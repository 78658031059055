import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    item: {
      display: 'flex',
      width: '100%',
      justifyContent: 'left',
      borderRadius: theme.spacing(1),
      padding: theme.spacing(1),
      background: '#FAFAFA',
      '&.Mui-disabled': {
        color: theme.palette.text.primary
      }
    },
    content: {
      display: 'grid',
      width: '100%',
      gridTemplateColumns: 'min-content auto min-content min-content',
      alignItems: 'center',
      textAlign: 'left'
    },
    icon: {
      borderRadius: '6px',
      padding: '6px',
      width: '36px',
      height: '40px',
      color: theme.palette.primary.dark,
      background: theme.palette.background.default
    },
    textWrap: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2)
    },
    text: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2)
    },
    action: {
      padding: '6px'
    }
  })
)
