import axios from '../axios'
import { ApiController } from './types'

const base = '/feedback'

const apiController: ApiController = {
  getFeedback: async () =>
    await axios
      .get(base)
      .then(response => response.data),

  sendFeedback: async (request) =>
    await axios
      .post(base, request)
      .then(response => response.data)
}

export default apiController
