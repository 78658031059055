import { authTypes } from '../auth/types'
import {
  userTypes,
  UserType,
  UserActions,
  UserValue,
  UserValues,
} from './types'

const initialState: UserType = {
  id: '',
  email: [],

  firstName: '',
  middleName: '',
  lastName: '',
  phone: null,
  companyName: '',
  tosAccepted: false,

  address: {
    city: '',
    state: '',
    line_1: '',
    line_2: '',
    zipCode: '',
  },
  picture: '',

  tradeList: [],
  licenseList: [],
  insuranceList: [],
  territoryList: [],
  wcExemptTerritories: [],

  registrationComplete: false,
  workOrderCount: 0,
  workOrderCountCompleted: 0,
  workOrderCountScheduled: 0,
  completedIncentives: 0,

  bgCheckAccepted: false,
  lmsEnabled: false,
  verificationStage: '',
  isAdmin: false,
  networkIds: [],
}

const reducer = (state = initialState, action: UserActions): UserType => {
  const { type, payload } = action

  switch (type) {
    // case authTypes.LOGIN_REQUEST: {
    //   return initialState
    // }
    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }

    case userTypes.SET_VALUE: {
      const { attr, value } = payload as UserValue
      return {
        ...state,
        [attr]: value,
      }
    }
    case userTypes.SET_VALUES: {
      const { attrs } = payload as UserValues
      return {
        ...state,
        ...(attrs as UserType),
      }
    }
    default:
      return state
  }
}

export default reducer
