import { FC } from 'react'
import { Box, Typography } from '../../..'
import useStyles from './styles'
import { TitledBoxProps } from './types'

const TitledBox: FC<TitledBoxProps> = ({ children, title, small = false }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Typography variant={small ? 'subtitle2' : 'subtitle1'} className={classes.title}>
        {title}
      </Typography>
      {children}
    </Box>
  )
}

export default TitledBox
