import { FC, useState } from 'react'
import { FormControl, InputAdornment, IconButton, FormHelperText, TextField } from '../../..'
import { icons } from '../../../../../assets'
import { PasswordProps } from './types'
import theme from '../../../MaterialUI/theme'

const Password: FC<PasswordProps> = ({ value, onChange, error, helperText, autoComplete='' }) => {
  const [showPassword, setShowPassword] = useState(false)

  const handleClickShowPassword = (): void => {
    setShowPassword(!showPassword)
  }

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault()
  }

  return (
    <FormControl
      size='small'
      margin='normal'
      fullWidth
      error={error}
      id={autoComplete}
      name={autoComplete}
      autoComplete={autoComplete}
    >
      <TextField
        variant='outlined'
        label='Password'
        name='password'
        size='small'
        value={value}
        error={error}
        onChange={onChange}
        type={showPassword ? 'text' : 'password'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                <icons.Visibility
                  style={{
                    color: showPassword
                      ? 'var(--teal-700)'
                      : theme.palette.grey[400]
                  }}
                />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  )
}

export default Password
