
import { FC, useEffect, useState } from 'react'
import Camera, { FACING_MODES, IMAGE_TYPES } from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'
import { Dialog, IconButton } from '../../../'
import { isEmpty } from '../../../../../helpers'
import { icons } from '../../../../../assets'
import useStyles from './styles'
import { CameraProps } from './types'

const CustomCam: FC<CameraProps> = ({ open, setOpen, onTakePhoto, defaultFacingUser = false }) => {
  const classes = useStyles()

  const [facingUser, setFacingUser] = useState(defaultFacingUser)
  const [error, setError] = useState<Error | null>(null)
  const [disabled, setDisabled] = useState<boolean>(true)

  useEffect(() => {
    if (error) {
      setOpen(false)
      setTimeout(function () {
        alert(error)
      }, 500)
    }
  }, [error, setOpen])

  const handleTakePhoto = (dataUri: string) => {
    if (onTakePhoto) {
      onTakePhoto(dataUri)
    }
  }

  const handleFlipCamera = () => {
    setFacingUser(!facingUser)
  }

  const handleClose = () => {
    setDisabled(true)
    setOpen(false)
  }

  const handleCameraError = (error: Error) => {
    setError(error)
  }

  const handleOpenedCam = () => {
    setTimeout(() => {
      setDisabled(false)
    }, 300)
  }

  if (isEmpty(error)) {
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        classes={{ paper: classes.dialog }}
      >
        {!disabled && (
          <>
            <IconButton
              disabled={disabled}
              className={classes.close}
              onClick={handleClose}
            >
              <icons.Close
                fontSize='large'
              />
            </IconButton>
            <IconButton
              disabled={disabled}
              className={classes.flip}
              onClick={handleFlipCamera}
            >
              <icons.FlipCameraIos
                fontSize='large'
              />
            </IconButton>
          </>
        )}
        <Camera
          onCameraStart={() => handleOpenedCam()}
          idealFacingMode={facingUser ? FACING_MODES.USER : FACING_MODES.ENVIRONMENT}
          onTakePhotoAnimationDone={handleTakePhoto}
          imageType={IMAGE_TYPES.PNG}
          onCameraError={(error) => { handleCameraError(error) }}
        />
      </Dialog>
    )
  }
  return <div />
}

export default CustomCam
