import { Theme, createStyles, makeStyles } from '../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      height: '100%',
      maxWidth: theme.breakpoints.values.lg,
      position: 'relative',
      gridTemplateRows: 'min-content auto'
    }
  })
)
