import { useState, useEffect, FC } from 'react'
import {
  Grid,
  Badge,
  Avatar,
  Button,
  Camera,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
} from '../../..'
import useStyles from './styles'
import { icons } from '../../../../../assets'
import { ProfileCardOutlinedProps } from './types'
import { dataURItoFile, isEmpty } from '../../../../../helpers'

const ProfileCardOutlined: FC<ProfileCardOutlinedProps> = ({
  primary,
  secondary,
  picture,
  preview = null,
  onUpload,
  action,
  badge,
  small = false,
}) => {
  const classes = useStyles()

  const [openCamera, setOpenCamera] = useState<boolean>(false)
  const [picturePreview, setPicturePreview] = useState<string | null>(preview)

  useEffect(() => {
    if (picture) {
      const tmppath = URL.createObjectURL(picture)
      setPicturePreview(tmppath)
    }
  }, [picture])

  const handleUpload = (event: any): void => {
    if (onUpload) {
      onUpload(event.target.files[0])
    }
  }

  const handleCamera = (): void => {
    setOpenCamera(true)
  }

  const handleTakePhoto = (photoUri: string): void => {
    if (onUpload) {
      onUpload(dataURItoFile(photoUri, 'picture'))
    }
    setOpenCamera(false)
  }

  const getPicture = (): JSX.Element => {
    if (picturePreview !== null) {
      return (
        <Avatar
          alt=""
          src={picturePreview}
          className={small ? classes.pictureSmall : classes.picture}
        />
      )
    }
    return (
      <Avatar alt="" className={small ? classes.iconSmall : classes.icon}>
        <icons.PersonOutlineTwoTone
          fontSize="large"
          className={classes.personIcon}
        />
      </Avatar>
    )
  }

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <List style={{ padding: 0 }}>
          <ListItem style={{ padding: 0 }}>
            <ListItemAvatar>
              {!isEmpty(badge) ? (
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  badgeContent={
                    <Avatar alt="badge" src={badge} className={classes.badge} />
                  }
                >
                  {getPicture()}
                </Badge>
              ) : (
                getPicture()
              )}
            </ListItemAvatar>
            <ListItemText
              style={{ paddingLeft: '16px' }}
              primary={primary}
              secondary={secondary}
            />
            {action && (
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={action}>
                  <icons.NavigateNextTwoTone />
                </IconButton>
              </ListItemSecondaryAction>
            )}
          </ListItem>
        </List>
      </Grid>
      {onUpload !== undefined && (
        <Grid container item spacing={1}>
          <input
            accept="image/*"
            style={{ display: 'none' }}
            id="contained-button-file"
            type="file"
            onChange={handleUpload}
          />
          <Camera
            defaultFacingUser
            open={openCamera}
            setOpen={setOpenCamera}
            onTakePhoto={handleTakePhoto}
          />
          <Grid item xs={6}>
            <label htmlFor="contained-button-file">
              <Button
                variant="text"
                color="primary"
                fullWidth
                className={classes.button}
                component="span"
                endIcon={<icons.CloudUploadTwoTone />}
              >
                UPLOAD
              </Button>
            </label>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              className={classes.button}
              component="span"
              onClick={handleCamera}
              endIcon={<icons.CameraAltTwoTone />}
            >
              TAKE
            </Button>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default ProfileCardOutlined
