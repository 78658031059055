import { UserType } from '../user/types'
import { vendorsTypes, VendorsActions } from './types'

const actions = {
  fetchVendors: (callback?: (succ: boolean) => void): VendorsActions => ({
    type: vendorsTypes.FETCH_VENDORS,
    payload: null,
    callback,
  }),
  setVendors: (payload: UserType[] | []): VendorsActions => ({
    type: vendorsTypes.SET_VENDORS,
    payload,
  }),
  deleteVendor: (
    payload: { id: string; email: string; networkId: string },
    callback?: (succ: boolean) => void
  ): VendorsActions => ({
    type: vendorsTypes.DELETE_VENDOR,
    payload,
    callback,
  }),
}

export default actions
