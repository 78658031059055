import { FC } from 'react'
import { Dialog, Button, DialogContent, Typography, Grid, Link } from '../../..'
import useStyles from './styles'
import { DialogIncentiveProps } from './types'

const DialogIncentive: FC<DialogIncentiveProps> = ({ open, setOpen }) => {
  const classes = useStyles()

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Dialog onClose={handleClose} open={open} classes={{ paper: classes.root }}>
      <DialogContent>
        <Typography variant='h6'>
          How you are gonna be paid
        </Typography>
        <ul className={classes.list}>
          <li>
            <Typography variant='body1'>
              You must hit this Incentive to be paid.
            </Typography>
          </li>
          <li>
            <Typography variant='body1'>
              Incentive is <b>included in the total price.</b>
            </Typography>
          </li>
          <li>
            <Typography variant='body1'>
              You’ll receive:
            </Typography>
            <Typography variant='body1'>
              <i>Bid - Cost of material = Total Amount</i>
            </Typography>
          </li>
        </ul>
        <Typography variant='body1' className={classes.thanks}>
          Thanks for being an incredible Pro!
        </Typography>
      </DialogContent>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant='contained'
              color='primary'
              component='span'
              onClick={handleClose}
              size='large'
              fullWidth
            >
              ok, got it
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='body2' className={classes.center}>
              If any doubt, please contact <Link href='https://bosscathome.com/contact/' target='_blank' rel='noreferrer'>BOSSCAT</Link>
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogContent />
    </Dialog>
  )
}

export default DialogIncentive
