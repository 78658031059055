/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { select, call, put, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import { Auth, Punchlist } from '../../api'
import {
  CreateUserRequest,
  UpdateUserRequest,
} from '../../api/punchlist/users/types'
import { EMAIL_TYPE, errorTextTryingTo, isEmpty } from '../../helpers'
import { userActions } from '../actions'
import { getUserId } from '../selectors'

import {
  userTypes,
  FetchCurrentUserAction,
  CreateUserAction,
  UpdateUserAction,
  UpdateUserListAction,
  UserType,
  GetLmsAccessAction,
} from './types'
import { getUser, getUserNetworkIds } from './selectors'
import { getUserReferrals } from '../userReferrals/selectors'

const parseUserAttrs = (user: Partial<UserType>): Partial<UserType> => {
  return {
    id: user.id || '',
    email: user.email || [],
    firstName: user.firstName || '',
    middleName: user.middleName || '',
    lastName: user.lastName || '',
    phone: user.phone || null,
    companyName: user.companyName || '',
    tosAccepted: user.tosAccepted || false,
    address: user.address || {
      city: '',
      state: '',
      line_1: '',
      line_2: '',
      zipCode: '',
    },
    picture: user.picture || '',
    tradeList: user.tradeList || [],
    licenseList: user.licenseList || [],
    insuranceList: user.insuranceList || [],
    territoryList: user.territoryList || [],
    wcExemptTerritories: user.wcExemptTerritories || [],
    registrationComplete: user.registrationComplete || false,
    workOrderCount: user.workOrderCount || 0,
    workOrderCountCompleted: user.workOrderCountCompleted || 0,
    workOrderCountScheduled: user.workOrderCountScheduled || 0,
    completedIncentives: user.completedIncentives || 0,
    bgCheckAccepted: user.bgCheckAccepted || false,
    lmsEnabled: user.lmsEnabled || false,
    verificationStage: user.verificationStage || '',
    isAdmin: user.isAdmin || false,
    networkIds: user.networkIds || [],
  }
}

export function* fetchCurrentUser({
  callback,
}: FetchCurrentUserAction): SagaIterator {
  let success = false
  try {
    const data = yield call(Punchlist.users.getCurrentUser)

    if (!isEmpty(data) && !isEmpty(data.id)) {
      Auth.set(data.id, 'punchlist-user-id', true)
      yield put(userActions.setUserValues({ attrs: parseUserAttrs(data) }))
      success = true
    }
    yield call(callback, success)
  } catch (error) {
    yield call(callback, false)
  }
}

export function* createUser({ callback }: CreateUserAction): SagaIterator {
  let success = false
  const userProperties = yield select(getUserReferrals)
  const userNetworkIds = yield select(getUserNetworkIds)
  try {
    const userInfo = Auth.getUserInfo()
    if (userInfo && userInfo.email) {
      const request = {
        email: [
          {
            emailType: EMAIL_TYPE.PRIMARY,
            email: userInfo.email,
          },
        ],
        type: 'Vendor',
      } as CreateUserRequest

      if (!isEmpty(userProperties)) {
        request.userProperties = userProperties
      }
      if (!isEmpty(userNetworkIds)) {
        request.networkIds = userNetworkIds
      }

      const data = yield call(Punchlist.users.createUser, request)

      if (!isEmpty(data) && !isEmpty(data.id)) {
        Auth.set(data.id, 'punchlist-user-id', true)
        yield put(userActions.setUserValues({ attrs: parseUserAttrs(data) }))
        success = true
      }
    }
    yield call(callback, success)
  } catch (error) {
    yield call(callback, false)
  }
}

export function* updateUser({
  payload,
  callback,
}: UpdateUserAction): SagaIterator {
  let success = false

  try {
    const userId = yield select(getUserId)
    const request: UpdateUserRequest = []

    for (const attr in payload) {
      request.push({
        op: 'add',
        path: '/' + attr,
        value: payload[attr as keyof UserType],
      })
    }

    const data = yield call(Punchlist.users.updateUser, userId, request)

    if (!isEmpty(data)) {
      yield put(userActions.setUserValues({ attrs: parseUserAttrs(data) }))
      success = true
    }
    yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update user info'))
    yield call(callback, false)
  }
}

export function* updateUserList({
  payload,
  callback,
}: UpdateUserListAction): SagaIterator {
  let success = false
  let removeData = null
  let addData = null

  try {
    const userId = yield select(getUserId)

    if (payload.opp === 'removeAll' || payload.opp === 'replace') {
      const requestRemove: UpdateUserRequest = []
      requestRemove.push({ op: 'remove', path: '/' + payload.attr })
      removeData = yield call(Punchlist.users.updateUser, userId, requestRemove)
    }

    if (payload.opp === 'add' || (payload.opp === 'replace' && removeData)) {
      const requestAdd: UpdateUserRequest = []
      for (const index in payload.list) {
        requestAdd.push({
          op: 'add',
          path: '/' + payload.attr + '/-',
          value: payload.list[parseInt(index)],
        })
      }
      addData = yield call(Punchlist.users.updateUser, userId, requestAdd)
    }

    if (payload.opp === 'remove' && payload.position !== undefined) {
      const requestRemove: UpdateUserRequest = []
      requestRemove.push({
        op: 'remove',
        path: '/' + payload.attr + '/' + payload.position,
      })
      removeData = yield call(Punchlist.users.updateUser, userId, requestRemove)
    }

    if (payload.opp === 'removeAll' && removeData) {
      yield put(
        userActions.setUserValue({
          attr: payload.attr,
          value: [],
        })
      )
      success = true
    }

    if (payload.opp === 'remove' && removeData) {
      yield put(
        userActions.setUserValues({ attrs: parseUserAttrs(removeData) })
      )
      success = true
    }

    if (payload.opp === 'replace' && addData) {
      yield put(
        userActions.setUserValue({
          attr: payload.attr,
          value: payload.list,
        })
      )
      success = true
    }

    if (payload.opp === 'add' && addData) {
      const user = yield select(getUser)
      yield put(
        userActions.setUserValue({
          attr: payload.attr,
          value: [...user[payload.attr], ...(payload.list ?? [])],
        })
      )
      success = true
    }

    yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update user info'))
    yield call(callback, false)
  }
}

export function* getLmsAccess({ callback }: GetLmsAccessAction): SagaIterator {
  try {
    const lmsAccess = yield call(Punchlist.users.getCurrentUserLms)
    if (!isEmpty(lmsAccess) && !isEmpty(lmsAccess.accessToken)) {
      yield call(callback, lmsAccess)
    } else {
      yield call(callback, null)
    }
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get lms access'))
    yield call(callback, null)
  }
}

export function* fetchVendorByEmail({ payload, callback }: any): SagaIterator {
  try {
    yield call(Punchlist.users.getVendorsByEmail, payload)
    if (callback) yield call(callback, true)
  } catch (error) {
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(userTypes.FETCH_CURRENT_USER, fetchCurrentUser)
  yield takeLatest(userTypes.CREATE_USER, createUser)
  yield takeLatest(userTypes.UPDATE_USER, updateUser)
  yield takeLatest(userTypes.UPDATE_USER_LIST, updateUserList)
  yield takeLatest(userTypes.GET_LSM_ACCESS, getLmsAccess)
  yield takeLatest(userTypes.FETCH_VENDOR_BY_EMAIL, fetchVendorByEmail)
}
