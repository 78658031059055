import {
  territoriesTypes,
  Territory,
  TerritoriesActions,
  TerritoriesPayload
} from './types'

const actions = {
  fetchTerritories: (payload?: TerritoriesPayload, callback?: (succ: boolean) => void): TerritoriesActions => ({
    type: territoriesTypes.FETCH_TERRITORIES,
    payload,
    callback
  }),
  setTerritories: (payload: Territory[]): TerritoriesActions => ({
    type: territoriesTypes.SET_TERRITORIES,
    payload
  })
}

export default actions
