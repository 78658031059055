/** TYPES **/
export const territoriesTypes = {
  FETCH_TERRITORIES: 'FETCH_TERRITORIES',
  SET_TERRITORIES: 'SET_TERRITORIES'
}

/** ACTIONS **/
export interface FetchTerritoriesAction {
  payload?: TerritoriesPayload,
  callback?: (succ: boolean) => void,
  type: typeof territoriesTypes.FETCH_TERRITORIES,
}

export interface SetTerritoriesAction {
  type: typeof territoriesTypes.SET_TERRITORIES
  payload: Territory[]
}

export type TerritoriesActions =
  | FetchTerritoriesAction
  | SetTerritoriesAction

/** REDUCER **/
export interface Territory {
  id: string
  zipCodes: string[]
  title: string
  visible: boolean
  serviceable: boolean
}
export interface TerritoriesPayload {
  serviceable: boolean
}

export interface TerritoriesType extends Array<Territory> { }
