import axios from '../axios'
import { ApiController } from './types'

const base = '/users'
const baseToCreate = '/vendors'

const apiController: ApiController = {
  getUsers: async () =>
    await axios
      .get(base)
      .then(response => response.data),

  getCurrentUser: async () =>
    await axios
      .get(`${base}/me`)
      .then(response => response.data),

  getCurrentUserLms: async () =>
    await axios
      .get(`${base}/me/lms`)
      .then(response => response.data),

  createUser: async (request) =>
    await axios
      .post(baseToCreate, request)
      .then(response => response.data),

  getUserById: async (id: string) =>
    await axios
      .get(`${base}/${id}`)
      .then(response => response.data),

  updateUser: async (id: string, request) =>
    await axios
      .patch(`${base}/${id}`, request)
      .then(response => response.data),
      
  getVendorsByEmail: async (email: string) =>
    await axios.head(`/users?email=${email}&userType=Vendor`).then((response) => response.data),
}

export default apiController
