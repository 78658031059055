import {
  EMAIL_TYPE,
  LICENSE_TYPE,
  INSURANCE_TYPE,
  REVIEW_STATUS,
} from '../../helpers'
import { TradeType } from '../tradeTypes/types'

/** TYPES **/
export const userTypes = {
  FETCH_CURRENT_USER: 'FETCH_CURRENT_USER',
  CREATE_USER: 'CREATE_USER',
  UPDATE_USER: 'UPDATE_USER',
  UPDATE_USER_LIST: 'UPDATE_USER_LIST',
  SET_VALUE: 'SET_USER_VALUE',
  SET_VALUES: 'SET_USER_VALUES',
  GET_LSM_ACCESS: 'GET_LSM_ACCESS',
  FETCH_VENDOR_BY_EMAIL: 'FETCH_VENDOR_BY_EMAIL',
}

/** ACTIONS **/
export interface FetchCurrentUserAction {
  type: typeof userTypes.FETCH_CURRENT_USER
  payload: null
  callback: (succ: boolean) => void
}

export interface CreateUserAction {
  type: typeof userTypes.CREATE_USER
  payload: null
  callback: (succ: boolean) => void
}

export interface UpdateUserAction {
  type: typeof userTypes.UPDATE_USER
  payload: Partial<UserType>
  callback: (succ: boolean) => void
}

export interface UpdateUserListPayload {
  attr: keyof UserType
  opp?: 'add' | 'removeAll' | 'replace' | 'remove'
  list?: Trade[] | License[] | Insurance[] | UserTerritory[] | string[]
  position?: number
}

export interface UpdateUserListAction {
  type: typeof userTypes.UPDATE_USER_LIST
  payload: UpdateUserListPayload
  callback: (succ: boolean) => void
}

export interface UserValue {
  attr: keyof UserType
  value: any
}

export interface UserSetValueAction {
  type: typeof userTypes.SET_VALUE
  payload: UserValue
}

export interface UserValues {
  attrs: Partial<UserType>
}

export interface UserSetValuesAction {
  type: typeof userTypes.SET_VALUES
  payload: UserValues
}

export interface GetLmsAccessAction {
  type: typeof userTypes.GET_LSM_ACCESS
  payload: null
  callback: (doceboAccess: LmsAccess | null) => void
}

export interface FetchUserByEmailAction {
  type: typeof userTypes.FETCH_VENDOR_BY_EMAIL
  payload: string
  callback?: (succ: boolean, vendors?: UserType[]) => void
}

export type UserActions =
  | FetchCurrentUserAction
  | CreateUserAction
  | UpdateUserAction
  | UpdateUserListAction
  | UserSetValueAction
  | UserSetValuesAction
  | GetLmsAccessAction
  | FetchUserByEmailAction

/** REDUCER **/
export interface LmsAccess {
  type: string
  reEnterCC: number
  accessToken: string
  expiresIn: number
  tokenType: string
}

export interface Email {
  emailType: EMAIL_TYPE
  email: string
}

export interface Address {
  city: string
  state: string
  line_1: string
  line_2: string
  zipCode: string
}

export interface Trade {
  tradeType: TradeType
  primaryTrade: boolean
}

export interface License {
  licenseType: LICENSE_TYPE
  reviewStatus: REVIEW_STATUS
  fileName: string
  id: string
}

export interface Insurance {
  insuranceType: INSURANCE_TYPE
  reviewStatus: REVIEW_STATUS
  fileName: string
  id: string
}

export interface UserTerritory {
  id: string
  title: string
  status: REVIEW_STATUS
}

export interface UserType {
  id: string
  email: Email[]

  firstName: string
  middleName: string
  lastName: string
  phone: number | null
  companyName: string
  tosAccepted: boolean

  address: Address
  picture: string

  tradeList: Trade[]
  licenseList: License[]
  insuranceList: Insurance[]
  territoryList: UserTerritory[]
  wcExemptTerritories: string[]

  registrationComplete: boolean
  workOrderCount: number
  workOrderCountCompleted: number
  workOrderCountScheduled: number
  completedIncentives: number

  bgCheckAccepted: boolean
  lmsEnabled: boolean
  verificationStage: string
  isAdmin: boolean
  networkIds: string[]
}
