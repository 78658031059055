import { FC, useState, useEffect } from 'react'
import Hammer from 'hammerjs'
import { Grid, GridSize, Button, Box } from '../../..'
import useStyles from './styles'
import { NavBarProps } from './types'
import { history, isEmpty } from '../../../../../helpers'
import { useMediaQuery, useTheme } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import { configActions } from '../../../../../ducks/actions'

const NavBar: FC<NavBarProps> = ({ options, replaceAll = false }) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const replace = history.useReplace()
  const replaceLast = history.useReplaceLast()
  const currentPath = history.getCurrentPath()
  const currentScreen = history.getCurrentScreen()

  const theme = useTheme()
  const smallScreen = useMediaQuery(theme.breakpoints.down('sm'))

  const xs = (12 / options.length) as GridSize
  const [selected, setSelected] = useState(currentScreen)

  const isSelected = (path: string, subPaths?: string[]) => {
    return selected === path || (subPaths && subPaths.includes(selected))
  }

  const handleClick = (index: number, path: string): void => {
    if (!isSelected(options[index].path, options[index].subPaths)) {
      setSelected(options[index].path)
      if (!isEmpty(path)) {
        if (replaceAll) replace('/'.concat(path))
        else replaceLast(path)
      }
    }
  }

  const handleSwipe = (direction: string) => {
    const pageIndex = options.findIndex((page) => page.path === currentScreen)
    if (direction === 'swiperight' && pageIndex > 0) {
      replaceLast(options[pageIndex - 1].path)
      return true
    }
    if (direction === 'swipeleft' && pageIndex < options.length - 1) {
      replaceLast(options[pageIndex + 1].path)
      return true
    }
    return false
  }

  useEffect(() => {
    dispatch(
      configActions.setConfigValue({ type: 'lastPath', value: currentPath })
    )
  }, [])

  useEffect(() => {
    const navContent = document.getElementById('navBar')
    if (navContent) {
      const hammer = new Hammer.Manager(navContent)
      const Swipe = new Hammer.Swipe()
      hammer.add(Swipe)
      hammer.on('swipeleft', (event) => {
        const moved = handleSwipe(event.type)
        return moved && hammer.destroy()
      })
      hammer.on('swiperight', (event) => {
        const moved = handleSwipe(event.type)
        return moved && hammer.destroy()
      })
    }
  }, [])

  return (
    <Box id="navBar" className={classes.root}>
      <Box className={classes.buttons}>
        <Grid container spacing={2}>
          {options.map((option, index) => {
            return (
              <Grid item key={index} xs={xs}>
                <Button
                  disabled={option.disabled}
                  size={smallScreen ? 'small' : 'large'}
                  disableElevation
                  className={
                    isSelected(option.path, option.subPaths)
                      ? classes.selected
                      : classes.option
                  }
                  onClick={() => handleClick(index, option.path)}
                >
                  <Grid container>
                    <Grid item xs={12}>
                      <option.icon
                        fontSize={smallScreen ? 'small' : 'medium'}
                        className={classes.icon}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      {option.text}
                    </Grid>
                  </Grid>
                </Button>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </Box>
  )
}

export default NavBar
