/* eslint-disable @typescript-eslint/no-unused-vars */
import storeInfo from '../store'
import { Invitation } from './types'

export const getInvitations = (statuses?: string[]) => (): Invitation[] => {
  const { invitations } = storeInfo.store.getState()
  if (!invitations || !invitations.length) return []
  if (!statuses) return invitations
  return invitations.filter((invitation) =>
    statuses.includes(invitation.status)
  )
}

export const getCurrentNetwork = (network?: string) => (): Invitation | null => {
  if (!network) return null
  const { invitations } = storeInfo.store.getState()
  const acceptedInvitationNetwork = invitations?.filter(invitation => invitation.status === "ACCEPTED").find(invitation => invitation?.vendorId === network)
  return acceptedInvitationNetwork || null
}


export const hasPendingInvitation = (): boolean => {
  const { invitations } = storeInfo.store.getState()
  if (!invitations || !invitations.length) return false
  return true
}
