import { Theme, createStyles, makeStyles } from '../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      position: 'relative',
      gridTemplateRows: 'min-content min-content auto'
    },
    header: {
      display: 'grid',
      alignItems: 'center',
      width: '100%',
      margin: '0 auto',
      padding: theme.spacing(2),
      maxWidth: theme.breakpoints.values.lg,
      gridTemplateColumns: '45px auto 45px'
    },
    headerContent: {
      backgroundColor: theme.palette.primary.main
    },
    button: {
      padding: 0,
      height: '42px',
      backgroundColor: 'transparent',
      '&:hover, &.Mui-focusVisible': {
        background: 'none'
      }
    },
    icon: {
      color: theme.palette.primary.contrastText
    },
    title: {
      textAlign: 'center',
      fontWeight: 700,
      width: '100%',
      color: theme.palette.primary.contrastText
    },
    user: {
      backgroundColor: theme.palette.primary.main,
      padding: theme.spacing(2)
    },
    contentBackground: {
      backgroundColor: theme.palette.primary.main
    },
    contentContainer: {
      minHeight: '250px',
      borderRadius: '36px 36px 0 0',
      backgroundColor: theme.palette.primary.contrastText
    },
    content: {
      maxWidth: theme.breakpoints.values.lg,
      height: '100%',
      margin: 'auto',
      padding: '24px 16px 24px 16px',
      left: 0,
      right: 0
    }
  })
)
