import { ORDER_STATUS_REDUCED } from '../../helpers'
import { authTypes } from '../auth/types'
import { configTypes, ConfigType, ConfigActionTypes, ConfigValue } from './types'

const initialState: ConfigType = {
  orderTab: ORDER_STATUS_REDUCED.ACTIVE,
  orderSearch: '',
  orderPendingScrollTop: 0,
  orderActiveScrollTop: 0,
  orderCompletedScrollTop: 0,
  orderItemsScrollTop: 0,
  lastPath: '',
  replyingBid: false,
  error: { show: false, type: '' },
}

const reducer = (state = initialState, action: ConfigActionTypes): ConfigType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case configTypes.SET_CONFIG_VALUE: {
      return {
        ...state,
        [(payload as ConfigValue).type]: (payload as ConfigValue).value
      }
    }

    case configTypes.SET_ERROR: {
      return {
        ...state,
        error: {
          show: (payload as unknown as ErrorValue).show,
          type: (payload as unknown as ErrorValue).type
        }
      }
    }

    default:
      return state
  }
}

export default reducer
