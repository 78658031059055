import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      padding: '16px 0',
      gap: theme.spacing(2)
    },
    icon: {
      width: theme.spacing(10),
      height: theme.spacing(10)
    },
    text: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      maxWidth: '250px',
      color: theme.palette.primary.dark
    },
    bottom: {
      display: 'grid',
      padding: '16px 8px 0'
    }
  })
)
