import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    warning: {
      maxWidth: theme.breakpoints.values.sm,
      padding: theme.spacing(3)
    },
    iconBox: {
      display: 'grid',
      justifyContent: 'center'
    },
    item: {
      width: '100%'
    },
    center: {
      textAlign: 'center'
    }
  })
)
