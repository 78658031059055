import { FC } from 'react'
import { Typography, Grid } from '../../..'
import useStyles from './styles'
import { NewsMessageProps } from './types'

const NewsMessage: FC<NewsMessageProps> = ({ icon, title, message }) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <Grid item xs={1} xl={1} className={classes.icon}>
        {icon}
      </Grid>
      <Grid item xs={11} xl={1} className={classes.text}>
        <Typography variant="h6">{title}</Typography>
      </Grid>
      <Grid item className={classes.text}>
        <Typography variant="body2">{message}</Typography>
      </Grid>
    </Grid>
  )
}

export default NewsMessage
