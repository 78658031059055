import { useState, useEffect, useRef, FC } from 'react'
import { Typography, Camera, Paper, Popover, MenuItem, MenuList, ClickAwayListener } from '../../..'
import { DocumentsMenuProps } from '../../../../pages/Auth/Register/types'
import { applicationMimeTypes, imageMimeTypes, dataURItoFile } from '../../../../../helpers'

const allowedFilesExtensions = [...imageMimeTypes, ...applicationMimeTypes].join(', ')

const DocumentsMenu: FC<DocumentsMenuProps> = ({ open, multiple, index, anchorEl, setOpen, onChange }) => {
  const prevOpen = useRef(open)

  const [openCamera, setOpenCamera] = useState<boolean>(false)

  useEffect(() => {
    if (prevOpen.current && !open && anchorEl !== null) {
      anchorEl.focus()
    }
    prevOpen.current = open
  }, [open, anchorEl])

  const handleClose = (event: React.MouseEvent<EventTarget>): void => {
    if (anchorEl && anchorEl.contains(event.target as HTMLElement)) { return }
    setOpen(false)
  }

  const handleListKeyDown = (event: React.KeyboardEvent): void => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
  }

  const handleChange = (event: any): void => {
    onChange(event.target.files)
  }

  const handleCamera = (): void => {
    setOpenCamera(true)
  }

  const handleTakePhoto = (photoUri: string): void => {
    onChange([dataURItoFile(photoUri, 'document')])
    setOpenCamera(false)
  }

  return (
    <Popover
      open={open} anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{
        style: {
          width: index !== undefined
            ? document.getElementById(`replace-${index}`)?.offsetWidth
            : 'auto'
        }
      }}
    >
      <Paper>
        <ClickAwayListener onClickAway={handleClose}>
          <MenuList
            autoFocusItem={open}
            id='menu-list-grow'
            onKeyDown={handleListKeyDown}
          >
            <input
              accept={allowedFilesExtensions}
              style={{ display: 'none' }}
              id='contained-button-file'
              type='file'
              multiple={multiple}
              onChange={handleChange}
            />
            <Camera
              open={openCamera}
              setOpen={setOpenCamera}
              onTakePhoto={handleTakePhoto}
            />
            <MenuItem onClick={handleCamera}>
              <Typography variant='body1'>Take Picture</Typography>
            </MenuItem>
            <label htmlFor='contained-button-file'>
              <MenuItem>
                <Typography variant='body1'>Upload</Typography>
              </MenuItem>
            </label>
          </MenuList>
        </ClickAwayListener>
      </Paper>
    </Popover>
  )
}

export default DocumentsMenu
