import { FC } from 'react'
import { Container, Box } from '../../../UI'
import useStyles from './styles'
import { TemplateProps } from './types'
import { logo } from '../../../../assets'

const Template: FC<TemplateProps> = ({ children, center = false }) => {
  const classes = useStyles()

  return (
    <Container className={classes.root}>
      <Box className={center ? classes.topLogo : ''}>
        <img src={logo} alt='' className={classes.logo} />
      </Box>
      <Box className={center ? classes.contentCenter : classes.content}>
        <Box className={center ? classes.bottomContent : ''}>
          {children}
        </Box>
      </Box>
    </Container>
  )
}

export default Template
