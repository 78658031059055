/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import { Punchlist } from '../../api'
import { tradesActions } from '../actions'

import {
  tradeTypes,
  FetchTradeTypesAction
} from './types'

export function* fetchTradeTypes({ callback }: FetchTradeTypesAction): SagaIterator {
  let success = false
  try {
    const data = yield call(Punchlist.trades.getTradeTypes)

    if (data && data.length > 0) {
      yield put(tradesActions.setTradeTypes(data))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(tradeTypes.FETCH_TRADE_TYPES, fetchTradeTypes)
}
