import { FC } from 'react'
import { Dialog, Box, Grid, Typography, Button } from '../../..'
import { DialogWarningPorps } from './types'
import useStyles from './styles'

const DialogWarning: FC<DialogWarningPorps> = ({
  open, setOpen, icon, advice, comments, acceptButton, onAccept,
  onSkip, okButtonText = 'Ok', skipButtonText = 'Skip', center = false
}) => {
  const classes = useStyles()

  const handleAccept = (): void => {
    if (onAccept !== undefined) onAccept()
    setOpen(false)
  }

  const handleSkip = (): void => {
    if (onSkip !== undefined) onSkip()
    setOpen(false)
  }

  return (
    <Dialog open={open}>
      <Box className={classes.warning}>
        <Grid
          container
          alignContent='center'
          direction='column'
          spacing={2}
          className={center ? classes.center : ''}
        >
          <Grid item xs={12} className={classes.iconBox}>
            {icon}
          </Grid>
          <Grid container item xs={12} spacing={2}>
            {advice && (
              <Grid item className={classes.item}>
                <Typography variant='h6'> {advice} </Typography>
              </Grid>)}
            {comments && comments.map((comment, index) => {
              return (
                <Grid item key={index} className={classes.item}>
                  <Typography variant='body1'> {comment} </Typography>
                </Grid>
              )
            })}
          </Grid>
          <Grid container item xs={12} spacing={1}>
            <Grid item xs={12}>
              {acceptButton ?? (
                <Button
                  variant='contained'
                  color='primary'
                  component='span'
                  onClick={handleAccept}
                  size='large'
                  fullWidth
                >
                  {okButtonText}
                </Button>)}
            </Grid>
            <Grid item xs={12}>
              <Button
                variant='text'
                onClick={handleSkip}
                size='large'
                fullWidth
              >
                {skipButtonText}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}

export default DialogWarning
