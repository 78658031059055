import { combineReducers } from 'redux'

import configReducer from './config/reducer'
import invitationsReducer from './invitations/reducer'
import ordersReducer from './orders/reducer'
import territoriesReducer from './territories/reducer'
import tradesReducer from './tradeTypes/reducer'
import tosCheckReducer from './tosCheck/reducer'
import userReducer from './user/reducer'
import userReferralsReducer from './userReferrals/reducer'
import vendorsReducer from './vendors/reducer'

import { ConfigType } from './config/types'
import { InvitationsType } from './invitations/types'
import { OrdersType } from './orders/types'
import { TerritoriesType } from './territories/types'
import { TradeTypes } from './tradeTypes/types'
import { TosCheckType } from './tosCheck/types'
import { UserType } from './user/types'
import { UserReferralsType } from './userReferrals/types'
import { VendorsType } from './vendors/types'

export interface State {
  config: ConfigType
  invitations: InvitationsType
  orders: OrdersType
  territories: TerritoriesType
  trades: TradeTypes
  tosCheck: TosCheckType
  user: UserType
  userReferrals: UserReferralsType
  vendorsReferrals: VendorsType
}

const rootReducer = combineReducers({
  config: configReducer,
  invitations: invitationsReducer,
  orders: ordersReducer,
  territories: territoriesReducer,
  tradeTypes: tradesReducer,
  tosCheck: tosCheckReducer,
  user: userReducer,
  userReferrals: userReferralsReducer,
  vendors: vendorsReducer,
})

export default rootReducer
