import { FC, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { userActions, userReferralsActions } from '../../../../ducks/actions'
import { UserReferrals } from '../../../../ducks/userReferrals/types'
import { history, isEmpty } from '../../../../helpers'
import { TemplateProps } from './types'

const Template: FC<TemplateProps> = ({ children }) => {
  const dispatch = useDispatch()

  const query = history.useQuery()
  const source = query.get('utm_source')
  const medium = query.get('utm_medium')
  const campaign = query.get('utm_campaign')
  const content = query.get('utm_content')
  const networkId = query.get('networkId')

  useEffect(() => {
    const attrs = {} as Partial<UserReferrals>
    if (source) attrs.source = source
    if (medium) attrs.medium = medium
    if (campaign) attrs.campaign = campaign
    if (content) attrs.content = content
    if (!isEmpty(attrs)) dispatch(userReferralsActions.setUserReferralsValues({ attrs }))
    if (!isEmpty(networkId)) {
      dispatch(userActions.setUserValue({ attr: 'networkIds', value: [networkId] }))
    }
  }, [source, medium, campaign, content, dispatch])

  return <>{children}</>
}

export default Template
