import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      width: '96px',
      height: '96px',
      color: theme.palette.warning.main
    },
    deleteContained: {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.dark,
      '&:hover': {
        backgroundColor: theme.palette.error.main
      }
    }
  })
)
