import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center'
    },
    checkTodo: {
      color: theme.palette.text.disabled
    },
    checkWarning: {
      color: theme.palette.warning.main
    },
    checkDone: {
      color: theme.palette.success.main
    },
    checkError: {
      color: theme.palette.error.main
    }
  })
)
