import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { Button, Typography } from '@material-ui/core'

import { configActions } from '../../../../ducks/actions'
import { ErrorPageProps, StateProps } from './types'

import useStyles from './styles'

import { icons, unofficialMascot, logo } from '../../../../assets'
import { history } from '../../../../helpers'

const ErrorPage = ({ type }: ErrorPageProps) => {
  const dispatch = useDispatch()
  const classes = useStyles()
  const push = history.usePush()

  const [state, setState] = useState<StateProps>({
    title: '',
    subtitle: "Couldn't load this page",
    img: unofficialMascot,
    customClass: '',
    button: {
      icon: 'Cached',
      text: 'Reload page'
    }
  })
  const Icon = icons[state.button.icon]

  const resetError = () => {
    dispatch(configActions.setError({ show: false, type: '' }))
    push('home')
  }

  const reloadPage = () => {
    window.location.reload()
  }

  useEffect(() => {
    switch (type) {
      case '404':
        setState({
          ...state,
          title: 'Error 404',
          subtitle: "Couldn't find the roof you're looking for",
          button: {
            icon: 'ArrowBack',
            text: 'Return to Website'
          },
          customClass: classes.error404,
          buttonAction: resetError
        })
        break
      case 'no_internet':
        setState({
          ...state,
          subtitle: 'There is no internet connection.',
          button: {
            icon: 'Cached',
            text: 'Reload and Try again'
          },
          customClass: classes.error404,
          buttonAction: reloadPage
        })
        break
      default:
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <header className={classes.header}>
        <img src={logo} alt='Logo' width={200} />
      </header>
      <section className={`${classes.root} ${state.customClass || ''}`}>
        <div className={classes.container}>
          {state.title !== '' && <Typography className={classes.title}>{state.title}</Typography>}
          <img className={classes.img} src={state.img} alt='Error' />
          <Typography className={classes.subtitle}>Oops! <span className={classes.subtitleBold}>{state.subtitle}</span></Typography>
          {/*  <Button
            className={classes.button}
            startIcon={<Icon className={classes.buttonIcon} />}
            onClick={state.buttonAction && state.buttonAction}
          >
            {state.button.text}
          </Button> */}
        </div>
      </section>
    </>
  )
}

export default ErrorPage
