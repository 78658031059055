/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import { toast } from 'react-toastify'
import type { SagaIterator } from 'redux-saga'
import { call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import { Punchlist } from '../../api'
import { errorTextTryingTo } from '../../helpers'
import { invitationsActions } from '../actions'

import {
  invitationsTypes,
  FetchInvitationsAction,
  SendAnInvitationAction,
  ReplyAnInvitationAction,
  FetchSentInvitationsAction,
} from './types'

export function* fetchInvitations({
  payload,
  callback,
}: FetchInvitationsAction): SagaIterator {
  try {
    const data = yield call(Punchlist.invitations.getInvitations, payload || ['PENDING'])
    yield put(invitationsActions.setInvitations(data))
    if (callback) yield call(callback, true)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the invitations'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchSentInvitations({
  payload,
  callback,
}: FetchSentInvitationsAction): SagaIterator {
  try {
    const data = yield call(Punchlist.invitations.getSentInvitations, payload)
    yield put(invitationsActions.addInvitations(data))
    if (callback) yield call(callback, true)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the sent invitations'))
    if (callback) yield call(callback, false)
  }
}

export function* sendAnInvitation({
  payload,
  callback,
}: SendAnInvitationAction): SagaIterator {
  try {
    yield call(Punchlist.invitations.sendAnInvitation, {
      recipientEmail: payload.recipientEmail,
    })
    if (callback) yield call(callback, true)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('send the invitation'))
    if (callback) yield call(callback, false)
  }
}

export function* replyAnInvitation({
  payload,
  callback,
}: ReplyAnInvitationAction): SagaIterator {
  try {
    const { invitationId, request } = payload

    yield call(Punchlist.invitations.replyAnInvitation, invitationId, request)
    if (callback) yield call(callback, true)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('reply the invitation'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(invitationsTypes.FETCH_INVITATIONS, fetchInvitations)
  yield takeEvery(invitationsTypes.FETCH_SENT_INVITATIONS, fetchSentInvitations)
  yield takeLatest(invitationsTypes.SEND_AN_INVITATION, sendAnInvitation)
  yield takeLatest(invitationsTypes.REPLY_AN_INVITATION, replyAnInvitation)
}
