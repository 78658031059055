import { authTypes } from '../auth/types'
import {
  invitationsTypes,
  Invitation,
  InvitationsType,
  InvitationsActions,
} from './types'

const initialState: InvitationsType = []

const reducer = (
  state = initialState,
  action: InvitationsActions
): InvitationsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: {
      return initialState
    }
    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }

    case invitationsTypes.SET_INVITATIONS: {
      return payload as Invitation[]
    }

    case invitationsTypes.ADD_INVITATIONS: {
      return [...state, ...(payload as Invitation[])]
    }

    default:
      return state
  }
}

export default reducer
