/* eslint-disable @typescript-eslint/no-unused-vars */
import storeInfo from '../../ducks/store'
import {
  Order,
  OrderItem,
  OrderFile,
  OrderNote,
  OrderState,
  ImageFile,
} from './types'
import {
  ORDER_ITEM_STATUS,
  ORDER_ITEM_IMAGE_TYPE,
  ORDER_STATUS_REDUCED,
  concatSeparator,
  getNameFromAbb,
  ORDER_TYPE,
} from '../../helpers'
import { isEmpty } from 'lodash'

export const getOrders =
  ({
    location,
    statusTypeReduced,
    searchedText,
    vendorId,
  }: {
    location?: string
    statusTypeReduced?: ORDER_STATUS_REDUCED
    searchedText?: string
    vendorId?: string
  }) =>
    (): Order[] => {
      let { orders } = storeInfo.store.getState()
      if (!orders || !orders.length) return []
      if (location)
        orders = orders.filter((order) => order.address.city === location)
      if (statusTypeReduced)
        orders = orders.filter(
          (order) => order.statusTypeReduced === statusTypeReduced
        )
      if (!isEmpty(searchedText)) {
        orders = orders.filter((order) =>
          order.address.line_1
            .toLowerCase()
            .includes((searchedText as string).toLowerCase())
        )
      }
      if (vendorId) {
        orders = orders.filter((order) => order.assigneeId === vendorId)
      }
      return orders
    }

export const getOrder = (orderId: string) => (): Order | null => {
  const { orders } = storeInfo.store.getState()
  return orders.find((order) => order.orderId === orderId) || null
}

export const getOrderType = (orderId: string) => (): ORDER_TYPE | null => {
  const { orders } = storeInfo.store.getState()
  return orders.find((order) => order.orderId === orderId)?.type || null
}

export const getOrderState = (orderId: string) => (): OrderState => {
  const { orders } = storeInfo.store.getState()
  const order = orders.find((order) => order.orderId === orderId)
  if (order) {
    const { completedTimestamp, checkedInTimestamp } = order
    if (completedTimestamp) return 'complete'
    if (checkedInTimestamp && !completedTimestamp) return 'checkin'
  }
  return 'none'
}

export const getOrderFullAddress = (orderId: string) => (): string | null => {
  const { orders } = storeInfo.store.getState()
  const order = orders.find((order) => order.orderId === orderId)
  if (!order || !order.address) return null
  const {
    line_1: street1,
    line_2: street2,
    city,
    state,
    zipCode,
  } = order.address

  let fullAddress = concatSeparator(street1, street2, ' ')
  fullAddress = concatSeparator(fullAddress, city, ', ')
  fullAddress = concatSeparator(fullAddress, getNameFromAbb(state), ', ')
  fullAddress = concatSeparator(fullAddress, zipCode, ', ')

  return fullAddress || null
}

export const getOrderDiffCities = (): { city: string; state: string }[] => {
  const { orders } = storeInfo.store.getState()
  if (!orders || !orders.length) return []
  return [
    ...Array.from(
      new Set<{ city: string; state: string }>(
        orders
          .filter((order) => order.address?.city)
          .map((order) => {
            return {
              city: order.address?.city,
              state: order.address.state,
            }
          })
      )
    ),
  ]
}

export const getOrderItems =
  (orderId: string, status?: ORDER_ITEM_STATUS[]) => (): OrderItem[] | [] => {
    const { orders } = storeInfo.store.getState()
    if (!orders || !orders.length) return []
    const order = orders.find((order) => order.orderId === orderId)
    if (!order || !order.items || !order.items.length) return []
    if (status && status.length > 0)
      return order.items.filter((item) => status.includes(item.status)) || []
    return order.items || []
  }

export const getOrderItem =
  (orderId: string, itemId: string) => (): OrderItem | null => {
    const { orders } = storeInfo.store.getState()
    if (!orders || !orders.length) return null
    const order = orders.find((order) => order.orderId === orderId)
    if (!order || !order.items || !order.items.length) return null
    const item = order.items.find((item) => item.itemId === itemId)
    return item || null
  }

export const getOrderItemPosition =
  (orderId: string, itemId: string) => (): string | null => {
    const { orders } = storeInfo.store.getState()
    if (!orders || !orders.length) return null
    const order = orders.find((order) => order.orderId === orderId)
    if (!order || !order.items || !order.items.length) return null
    const items = order.items // .filter(item => item.status !== ORDER_ITEM_STATUS.COMPLETE)
    const index = order.items.findIndex((item) => item.itemId === itemId) + 1
    return index > 0 ? index + '/' + items.length : null
  }

export const getOrderItemImages =
  (orderId: string, itemId: string, type?: ORDER_ITEM_IMAGE_TYPE) =>
    (): ImageFile[] | [] => {
      const { orders } = storeInfo.store.getState()
      if (!orders || !orders.length) return []
      const order = orders.find((order) => order.orderId === orderId)
      if (!order || !order.items || !order.items.length) return []
      const item = order.items.find((item) => item.itemId === itemId)
      if (!item || !item.imageFiles || !item.imageFiles.length) return []
      if (type)
        return item.imageFiles.filter((item) => item.fileType === type) || []
      return item.imageFiles || []
    }

export const allItemsFinished = (orderId: string) => (): boolean => {
  let result = true
  const finishedOptions = [
    ORDER_ITEM_STATUS.COMPLETE,
    ORDER_ITEM_STATUS.ACCEPTED,
  ]
  const { orders } = storeInfo.store.getState()
  const order = orders.find((order) => order.orderId === orderId)
  if (!order || !order.items || !order.items.length) return false
  order.items.forEach((item) => {
    if (!finishedOptions.includes(item.status)) result = false
  })
  return result
}

export const getOrderFiles = (orderId: string) => (): OrderFile[] | [] => {
  const { orders } = storeInfo.store.getState()
  if (!orders || !orders.length) return []
  const order = orders.find((order) => order.orderId === orderId)
  if (!order) return []
  return order.files || []
}

export const getOrderNotes = (orderId: string) => (): OrderNote[] | [] => {
  const { orders } = storeInfo.store.getState()
  if (!orders || !orders.length) return []
  const order = orders.find((order) => order.orderId === orderId)
  if (!order) return []
  return order.notes || []
}
