import axios from '../axios'
import { ApiController } from './types'

const base = '/trades'

const apiController: ApiController = {
  getTradeTypes: async () =>
    await axios
      .get(`${base}/types`)
      .then(response => response.data)
}

export default apiController
