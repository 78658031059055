import {
  userReferralsTypes,
  UserReferralsValue,
  UserReferralsValues,
  UserReferralsActions
} from './types'

const actions = {
  setUserReferralsValue: (payload: UserReferralsValue): UserReferralsActions => ({
    type: userReferralsTypes.SET_VALUE,
    payload
  }),
  setUserReferralsValues: (payload: UserReferralsValues): UserReferralsActions => ({
    type: userReferralsTypes.SET_VALUES,
    payload
  })
}

export default actions
