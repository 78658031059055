/* eslint-disable @typescript-eslint/no-unused-vars */
import { State } from '../reducers'
import storeInfo from '../../ducks/store'
import { UserTerritory, UserType, License, Insurance, Address } from './types'
import { TradeType } from '../tradeTypes/types'
import {
  concatSeparator,
  isEmpty,
  getNameFromAbb,
  INSURANCE_TYPE,
  REVIEW_STATUS,
  EMAIL_TYPE,
  LICENSE_TYPE,
  VERIFICATION_STAGE,
} from '../../helpers'
import { Invitation } from '../invitations/types'

export const getUser = (state: State): UserType => state.user

export const getUserWCExemptStatus = (): boolean => {
  const { user } = storeInfo.store.getState()
  const exceptTerrritories = user.wcExemptTerritories
  return exceptTerrritories && exceptTerrritories.length > 0
}

export const isFullUser = (): boolean => {
  const {
    user: {
      picture,
      tradeList,
      licenseList,
      insuranceList,
      bgCheckAccepted,
      verificationStage,
    },
  } = storeInfo.store.getState()

  if (verificationStage === VERIFICATION_STAGE.VERIFIED) return true

  const WCExemptStatus = getUserWCExemptStatus()

  // has at least one of each licenses nedded approved
  const formM9 =
    licenseList?.some((ins) => ins.licenseType === LICENSE_TYPE.FORM_W9) ||
    false
  const identification =
    licenseList?.some(
      (ins) => ins.licenseType === LICENSE_TYPE.IDENTIFICATION
    ) || false
  const tradeLicense =
    licenseList?.some(
      (ins) => ins.licenseType === LICENSE_TYPE.TRADE_LICENSE
    ) || false

  // if has liability insurance, update check state
  const liability =
    insuranceList?.some(
      (ins) => ins.insuranceType === INSURANCE_TYPE.CERTIFICATE_OF_INSURANCE
    ) || false
  const compensation =
    WCExemptStatus ||
    insuranceList?.some(
      (ins) => ins.insuranceType === INSURANCE_TYPE.WORKERS_COMPENSATION
    ) ||
    false

  return (
    // has avatar picture uploaded
    !isEmpty(picture) &&
    // has at least one trade
    tradeList &&
    tradeList.length > 0 &&
    // is background acknowledged accepted
    bgCheckAccepted &&
    // has at least one of each licenses nedded
    formM9 &&
    identification &&
    tradeLicense &&
    // has liability and compensation insurances
    liability &&
    compensation
  )
}

export const isLmsEnabled = (): boolean => {
  const {
    user: { lmsEnabled },
  } = storeInfo.store.getState()
  return lmsEnabled
}

export const isAdmin = (): boolean => {
  const {
    user: { isAdmin },
  } = storeInfo.store.getState()
  return isAdmin
}

export const hasSomeNetwork = (): boolean => {
  const {
    user: { networkIds },
  } = storeInfo.store.getState()
  return networkIds && networkIds.length > 0
}

export const getNetworks = (): string[] => {
  const {
    user: { networkIds },
  } = storeInfo.store.getState()
  return networkIds
}

export const getMatchingNetwork = (vendorNetworks: string[]) => (): string => {
  const {
    user: { networkIds },
  } = storeInfo.store.getState()
  const matchingNetwork = networkIds.filter((networkId) =>
    vendorNetworks.includes(networkId)
  )
  return matchingNetwork ? matchingNetwork[0] : ''
}

export const hasCompletedJobs = (): boolean => {
  const {
    user: { workOrderCountCompleted },
  } = storeInfo.store.getState()
  return workOrderCountCompleted > 0
}

export const validatingDocuments = (): boolean => {
  const {
    user: { insuranceList },
  } = storeInfo.store.getState()
  return (
    insuranceList &&
    insuranceList.length > 0 &&
    insuranceList.some(
      (ins) =>
        ins.reviewStatus === REVIEW_STATUS.DRAFT ||
        ins.reviewStatus === REVIEW_STATUS.SUBMITTED ||
        ins.reviewStatus === REVIEW_STATUS.REVIEWED
    )
  )
}

export const getUserId = (): string => {
  const { user } = storeInfo.store.getState()
  return user.id
}

export const getUserPrimaryEmail = (): string => {
  const { user } = storeInfo.store.getState()
  const primaryEmail =
    user.email.find((e) => e.emailType === EMAIL_TYPE.PRIMARY)?.email || ''
  return primaryEmail || 'No email registered'
}

export const getUserFullName = (): string => {
  const { user } = storeInfo.store.getState()
  const { firstName, lastName } = user
  const fullName = concatSeparator(firstName, lastName, ' ')
  return fullName || 'No name registered'
}

export const getUserAddress = (address?: Address) => (): string => {
  const { user } = storeInfo.store.getState()
  const { city, state } = address || user.address
  const userAddress = concatSeparator(city, getNameFromAbb(state), ', ')
  return userAddress || 'No address'
}

/* 
export const getTerritoryManagersFiltered = (territoryId: string) => (): TerritoryManagerType[] => {
  const { territory: { territoryManagers } } = storeInfo.store.getState()
  return territoryManagers.filter(manager => manager.roles
    .some(role => role.roleType === EMPLOYEE_ROLE_TYPE.TERRITORY_MANAGER && role.territoryId === territoryId)
  )
} */
export const getUserFullAddress = (): string => {
  const { user } = storeInfo.store.getState()
  const {
    line_1: street1,
    line_2: street2,
    city,
    state,
    zipCode,
  } = user.address

  let fullAddress = concatSeparator(street1, street2, ' ')
  fullAddress = concatSeparator(fullAddress, city, ', ')
  fullAddress = concatSeparator(fullAddress, getNameFromAbb(state), ', ')
  fullAddress = concatSeparator(fullAddress, zipCode, ', ')

  return fullAddress || 'No address registered'
}

export const getUserTerritories = (): UserTerritory[] => {
  const { user } = storeInfo.store.getState()
  const territories = user.territoryList
  return territories || []
}

export const getUserOtherTerritories = (): UserTerritory[] => {
  const { user } = storeInfo.store.getState()
  const territories = user.territoryList
  return territories?.filter(territory => territory?.id === "OTHER") || []
}

export const getUserWCExemptTerritories = (): string[] => {
  const { user } = storeInfo.store.getState()
  const exceptTerrritories = user.wcExemptTerritories
  return exceptTerrritories || []
}

export const getUserPrimaryTradeType = (): TradeType | null => {
  const { user } = storeInfo.store.getState()
  return user.tradeList?.find((trade) => trade.primaryTrade)?.tradeType || null
}

export const getUserOtherTradesType = (): TradeType[] => {
  const { user } = storeInfo.store.getState()
  return (
    user.tradeList
      ?.filter((trade) => !trade.primaryTrade)
      ?.map((trade) => {
        return trade.tradeType
      }) || []
  )
}

export const getUserLicenses = (): License[] => {
  const { user } = storeInfo.store.getState()
  return user.licenseList || []
}

export const getUserInsurances = (): Insurance[] => {
  const { user } = storeInfo.store.getState()
  return user.insuranceList || []
}

export const getUserNetworkIds = (): string[] => {
  const { user } = storeInfo.store.getState()
  return user.networkIds || []
}

export const getUserDocumentsProgress = (): number => {
  const { user } = storeInfo.store.getState()
  const { licenseList, insuranceList } = user
  let progress = 0
  if (licenseList.some((doc) => doc.licenseType === LICENSE_TYPE.FORM_W9))
    progress += 20
  if (
    licenseList.some((doc) => doc.licenseType === LICENSE_TYPE.IDENTIFICATION)
  )
    progress += 20
  if (licenseList.some((doc) => doc.licenseType === LICENSE_TYPE.TRADE_LICENSE))
    progress += 20
  if (
    insuranceList.some(
      (doc) => doc.insuranceType === INSURANCE_TYPE.CERTIFICATE_OF_INSURANCE
    )
  )
    progress += 20
  if (
    insuranceList.some(
      (doc) => doc.insuranceType === INSURANCE_TYPE.WORKERS_COMPENSATION
    )
  )
    progress += 20
  return progress
}
