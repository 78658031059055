import { FC } from 'react'
import { TemplateProps } from './types'
import { Button, StateMessage } from '../../../UI'
import { PAGE_STATE } from '../../../../helpers'
import { spinnerGif, brokenLinkGif, noItemsGif } from '../../../../assets'

const Template: FC<TemplateProps> = ({
  children,
  states = {
    loading: false,
    error: false,
    empty: false
  },
  loadingState = {
    icon: spinnerGif,
    title: 'Loading...',
    description: 'Thank you for waiting'
  },
  emptyState = {
    icon: noItemsGif,
    title: 'No available info here',
    description: ''
  },
  errorState = {
    icon: brokenLinkGif,
    title: 'Oh! Connection failed',
    description: 'Click below to try reloading'
  }
}) => {
  let state = PAGE_STATE.DATA
  if (states.loading) state = PAGE_STATE.LOADING
  else if (states.error) state = PAGE_STATE.ERROR
  else if (states.empty) state = PAGE_STATE.EMPTY

  switch (state) {
    case PAGE_STATE.LOADING: return (
      <StateMessage
        icon={loadingState.icon || spinnerGif}
        title={loadingState.title || 'Getting the info for you'}
        description={loadingState.description}
      />
    )

    case PAGE_STATE.EMPTY: return (
      <StateMessage
        icon={emptyState.icon || noItemsGif}
        title={emptyState.title || 'No available info here'}
        description={emptyState.description}
      />
    )

    case PAGE_STATE.ERROR: return (
      <StateMessage
        icon={errorState.icon || brokenLinkGif}
        title={errorState.title || 'Oh! Connection failed'}
        description={errorState.description || 'Click below to try reloading'}
        bottom={
          errorState.onReload && (
            <Button
              variant='contained'
              color='primary'
              onClick={() => { if (errorState.onReload) errorState.onReload() }}
            >Reload
            </Button>)
        }
      />
    )

    default: return <> {children} </>
  }
}

export default Template
