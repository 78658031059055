import { Theme, createStyles, makeStyles } from '../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      justifyContent: 'center',
      alignItems: 'center'
    },
    logo: {
      width: '90vw',
      maxWidth: '425px',
      paddingBottom: '25px'
    },
    loader: {
      width: '100%',
      margin: 'auto',
      borderRadius: '8px',
      maxWidth: '50%',
      [theme.breakpoints.up('md')]: {
        maxWidth: '24rem'
      }
    }
  })
)
