import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      width: '100%',
      padding: '0px 8px 16px',
      borderRadius: theme.spacing(1),
      boxShadow:
        '0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12)',
      cursor: 'pointer',
      height: '100%',
      gridAutoRows: 'minmax(auto, max-content)',
    },
    content: {
      overflow: 'hidden',
    },
    titleContent: {
      display: 'flex',
      gap: theme.spacing(1),
    },
    icon: {
      height: '24px',
      width: '24px',
      objectFit: 'contain',
      objectPosition: 'center',
    },
    title: {
      color: theme.palette.primary.dark,
      fontFamily: 'Next Regular',
      fontWeight: 700,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    backgroundIcon: {
      display: 'flex',
      height: '112px',
      justifyContent: 'flex-end',
      marginRight: '-8px',
      '& img': {
        width: '96px',
      },
    },
    text: {
      color: theme.palette.primary.dark,
      paddingTop: theme.spacing(0.5),
      letterSpacing: '0.2px',
    },
  })
)
