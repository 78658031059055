import { FC, useEffect, useState } from 'react'
import {
  Dialog,
  Box,
  Button,
  DialogContent,
  Typography,
  Grid,
  Link,
  TextField,
  CircularProgress,
} from '../../..'
import { isEmpty, validateEmail } from '../../../../../helpers'
import useStyles from './styles'
import { DialogInvitationProps } from './types'
import { awesomeSuccess } from '../../../../../assets'

const DialogInvitation: FC<DialogInvitationProps> = ({
  open,
  setOpen,
  onInvite,
}) => {
  const classes = useStyles()

  const [email, setEmail] = useState('')
  const [sent, setSent] = useState(false)
  const [sending, setSending] = useState(false)
  const [showWarning, setShowWarning] = useState(false)

  const validate = !isEmpty(email) && validateEmail(email)

  useEffect(() => {
    if (sent) {
      setTimeout(function () {
        setOpen(false)
      }, 3000)
    }
  }, [sent])

  const handleInvite = () => {
    if (validate && !sending) {
      setSending(true)
      onInvite(email, (success, refresh) => {
        setSending(false)
        setSent(success)
        refresh()
      })
    } else {
      setShowWarning(true)
    }
  }

  const handleClose = () => {
    if (!sending) {
      setOpen(false)
    }
  }

  const SentContent = () => (
    <DialogContent className={classes.center}>
      <img alt="" src={awesomeSuccess} className={classes.icon} />
      <Typography variant="body1">
        Invite to: <b>{email}</b> sent succesfully!
      </Typography>
    </DialogContent>
  )

  return (
    <Dialog open={open} classes={{ paper: classes.root }}>
      {sent ? (
        <SentContent />
      ) : (
        <>
          <DialogContent>
            <Typography variant="h6">Enter Pro’s e-mail to invite</Typography>
            <Box className={classes.input}>
              <TextField
                label="E-mail"
                type="email"
                variant="outlined"
                size="small"
                value={email}
                fullWidth
                disabled={sending || sent}
                error={showWarning && (isEmpty(email) || !validateEmail(email))}
                onChange={(event: React.ChangeEvent<any>) => {
                  setEmail(event.target.value)
                }}
              />
            </Box>
            <Typography variant="body1" className={classes.text}>
              Pro has to accept your invitation to be part of your organization.
            </Typography>
          </DialogContent>
          <DialogContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  onClick={handleInvite}
                  size="large"
                  fullWidth
                >
                  {sending ? (
                    <CircularProgress color="inherit" size={24} />
                  ) : (
                    'INVITE PRO'
                  )}
                </Button>
              </Grid>
              {!sending && (
                <Grid item xs={12}>
                  <Button
                    variant="outlined"
                    color="primary"
                    component="span"
                    onClick={handleClose}
                    size="large"
                    fullWidth
                  >
                    CANCEL
                  </Button>
                </Grid>
              )}
              <Grid item xs={12}>
                <Typography variant="body2" className={classes.center}>
                  If any doubt, please contact{' '}
                  <Link
                    href="https://bosscathome.com/contact/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    BOSSCAT
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
        </>
      )}
      <DialogContent />
    </Dialog>
  )
}

export default DialogInvitation
