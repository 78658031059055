/* eslint-disable @typescript-eslint/no-unused-vars */
import { getComparator, stableSort } from '../../helpers'
import storeInfo from '../store'
import { Territory } from './types'

export const getTerritories = (): Territory[] => {
  const { territories } = storeInfo.store.getState()
  if (!territories || !territories.length) return []
  return stableSort<Territory>(
    territories,
    getComparator('asc', 'title'))
}

export const getVisibleTerritories = (): Territory[] => {
  const { territories } = storeInfo.store.getState()
  if (!territories || !territories.length) return []
  return stableSort<Territory>(
    territories.filter(territory => territory.visible),
    getComparator('asc', 'title'))
}
