import axios from '../axios'
import { ApiController } from './types'

const base = '/invitations/vendor-network'

const apiController: ApiController = {
  getInvitations: async (statuses: string[]) =>
    await axios.get(`${base}?status=${statuses}`).then((response) => response.data),

  getSentInvitations: async (statuses: string) =>
    await axios
      .get(`${base}/sent?statuses=${statuses}`)
      .then((response) => response.data),

  sendAnInvitation: async (request) =>
    await axios.post(`${base}`, request).then((response) => response.data),

  replyAnInvitation: async (invitationId, request) =>
    await axios
      .post(`${base}/${invitationId}/reply`, request, {
        headers: {
          'Content-Type': 'text/plain',
        },
      })
      .then((response) => response.data),
}

export default apiController
