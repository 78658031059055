import { FC } from 'react'
import {
  Grid,
  Badge,
  Avatar,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Radio,
} from '../../..'
import useStyles from './styles'
import { ProfileCardOrganizationProps } from './types'
import { icons } from '../../../../../assets'

const ProfileCardOrganization: FC<ProfileCardOrganizationProps> = ({
  primary,
  secondary,
  email,
  preview = null,
  selected = false,
  action,
  small = false,
  status,
  removedOn,
  truncate = false,
  className = "",
  primaryTextMaxWidth
}) => {
  const classes = useStyles()
  const getPicture = (): JSX.Element => {
    if (preview !== null) {
      return (
        <Avatar
          alt=""
          src={preview}
          className={small ? classes.pictureSmall : classes.picture}
        />
      )
    }
    return (
      <Avatar alt="" className={small ? classes.iconSmall : classes.icon}>
        <icons.PersonOutlineTwoTone
          fontSize="large"
          className={classes.personIcon}
        />
      </Avatar>
    )
  }

  return (
    <Grid
      container
      spacing={1}
      onClick={action}
      style={action && { cursor: 'pointer' }}
    >
      <Grid
        item
        xs={12}
        className={
          `${['revoke', 'organization', 'assign'].includes(status || '')
            ? classes.revoke
            : ''}
            ${className}
            `
        }
      >
        <List style={{ padding: 0 }}>
          <ListItem style={{ padding: 0 }}>
            <ListItemAvatar>
              {['revoking', 'checked'].includes(status as string) ? (
                <Badge
                  overlap="circular"
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  badgeContent={
                    status === 'revoking' ? (
                      <icons.Schedule
                        fontSize="small"
                        className={classes.checkWarning}
                      />
                    ) : (
                      <icons.Check
                        fontSize="small"
                        className={classes.checkDoneContained}
                      />
                    )
                  }
                >
                  {getPicture()}
                </Badge>
              ) : (
                getPicture()
              )}
            </ListItemAvatar>

            <ListItemText
              className={`${classes.leftItemText} ${truncate && classes.leftItemTexTruncate}`}
              primary={primary || email}
              secondary={secondary}
              style={{ width: primaryTextMaxWidth }}
            />
            {status === 'active' && (
              <icons.CheckCircle
                fontSize="small"
                className={classes.checkDone}
              />
            )}
            {status === 'pending' && (
              <icons.Schedule
                fontSize="small"
                className={classes.checkWarning}
              />
            )}
            {status === 'removed' && (
              <ListItemText
                className={`${classes.rightItemText} ${truncate && classes.rightItemTextTruncate}`}
                primary="Removed on:"
                secondary={removedOn}
              />
            )}
            {status === 'organization' && (
              <icons.Business className={classes.organization} />
            )}
            {status === 'assign' && (
              <Radio color="primary" checked={selected} />
            )}
          </ListItem>
        </List>
      </Grid>
    </Grid>
  )
}

export default ProfileCardOrganization
