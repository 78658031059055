import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      flexFlow: 'column',
      gap: '6px',
      height: '75%'
    },
    title: {
      color: theme.palette.primary.dark
    }
  })
)
