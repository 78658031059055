import { FC } from 'react'
import { Box, NavBar } from '../../../UI'
import { icons } from '../../../../assets'
import useStyles from './styles'
import { TemplateProps } from './types'

const Template: FC<TemplateProps> = ({ children, order = null }) => {
  const classes = useStyles()

  const disableMap = order && (!order.address || (order.address && (!order.address.latitude || !order.address.longitude)))

  const main = [
    {
      text: 'Items',
      icon: icons.CalendarViewDay,
      path: 'items'
    },
    {
      text: 'Files',
      icon: icons.Folder,
      path: 'files'
    },
    {
      text: 'Notes',
      icon: icons.Create,
      path: 'notes'
    },
    {
      text: 'Map',
      icon: icons.Place,
      path: 'map',
      disabled: disableMap
    }
  ]

  return (
    <Box className={classes.root}>
      {children}
      <NavBar options={main} />
    </Box>
  )
}

export default Template
