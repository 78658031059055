/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import { Punchlist } from '../../api'
import { territoriesActions } from '../actions'

import {
  territoriesTypes,
  FetchTerritoriesAction
} from './types'

export function* fetchTerritories({ payload, callback }: FetchTerritoriesAction): SagaIterator {
  let success = false
  try {
    const data = yield call(Punchlist.territories.getTerritories, payload?.serviceable)

    if (data && data.length > 0) {
      yield put(territoriesActions.setTerritories(data))
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(territoriesTypes.FETCH_TERRITORIES, fetchTerritories)
}
