import axios from '../axios'
import { ApiController } from './types'

const base = '/vendors'

const apiController: ApiController = {
  getVendors: async () =>
    await axios.get(base).then((response) => response.data),

  deleteVendor: async (idOrEmail: string, networkId: string) =>
    await axios
      .delete(`${base}/${idOrEmail}/networks/${networkId}`)
      .then((response) => response.data),
}

export default apiController
