import { FC, useState } from 'react'
import env from '@beam-australia/react-env'
import {
  Dialog,
  Button,
  DialogContent,
  Typography,
  Grid,
  CircularProgress,
  ProfileCardOrganization,
  InputBase,
} from '../../..'
import useStyles from './styles'
import { DialogAssignVendorProps } from './types'
import { icons } from '../../../../../assets'
import {
  concatSeparator,
  getNameFromAbb,
  isEmpty,
} from '../../../../../helpers'

const FILES_URL = env('FILES_URL') ?? ''

const DialogAssignVendor: FC<DialogAssignVendorProps> = ({
  open,
  setOpen,
  onAssign,
  address,
  vendors,
}) => {
  const classes = useStyles()

  const [assigning, setSending] = useState(false)
  const [selected, setSelected] = useState<string | null>(null)
  const [searchText, setSearchText] = useState<string>('')

  const { city, line_1, zipCode } = address

  const showSearch = vendors.length > 4

  const handleAssign = () => {
    if (selected && !assigning) {
      setSending(true)
      onAssign(selected, (success) => {
        setSending(false)
        if (success) setOpen(false)
      })
    }
  }

  const handleClose = () => {
    if (!assigning) {
      setOpen(false)
    }
  }

  return (
    <Dialog open={open} classes={{ paper: classes.root }}>
      <DialogContent>
        <Typography variant="body1" color="primary">
          {line_1}
        </Typography>
        <Typography variant="caption">{`${city}, ${zipCode}`}</Typography>
      </DialogContent>
      {showSearch && (
        <div className={classes.search}>
          <div className={classes.searchIcon}>
            <icons.Search />
          </div>
          <InputBase
            value={searchText}
            onChange={(event) => setSearchText(event.target.value)}
            placeholder="Find Pro.."
            classes={{
              root: classes.inputRoot,
              input: classes.input,
            }}
          />
        </div>
      )}
      <DialogContent>
        <Grid
          container
          spacing={2}
          style={{ maxHeight: showSearch ? '265px' : '300px' }}
        >
          {vendors.map((vendor, index) => {
            const {
              id,
              firstName,
              lastName,
              address: vendorAddress,
              picture,
              email
            } = vendor
            const { city: vendorCity, state: vendorState } = vendorAddress || {}
            const fullName = concatSeparator(firstName, lastName, ' ')
            const address = concatSeparator(
              vendorCity,
              getNameFromAbb(vendorState),
              ', '
            )
            const vendorEmail = email.find(email => email.emailType === "PRIMARY")

            if (
              !isEmpty(searchText) &&
              !fullName.toLowerCase().includes(searchText.toLowerCase())
            ) {
              return null
            }

            return (
              <Grid key={index} item xs={12}>
                <ProfileCardOrganization
                  primary={fullName}
                  secondary={address}
                  preview={!isEmpty(picture) ? FILES_URL.concat(picture) : null}
                  status="assign"
                  small
                  selected={selected === id}
                  email={vendorEmail?.email}
                  action={() => {
                    setSelected(id)
                  }}
                />
              </Grid>
            )
          })}
        </Grid>
      </DialogContent>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              component="span"
              onClick={handleAssign}
              size="large"
              fullWidth
              disabled={!selected}
              className={classes.assignButton}
              endIcon={!assigning && <icons.CheckCircle />}
            >
              {assigning ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                'ASSIGN JOB'
              )}
            </Button>
          </Grid>
          {!assigning && (
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                component="span"
                onClick={handleClose}
                size="large"
                fullWidth
              >
                CANCEL
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogContent>
      <DialogContent />
    </Dialog>
  )
}

export default DialogAssignVendor
