/* eslint-disable @typescript-eslint/no-unused-vars */
import { EMAIL_TYPE } from '../../helpers'
import storeInfo from '../store'
import { UserType } from '../user/types'

export const getVendors = (): UserType[] => {
  const { vendors } = storeInfo.store.getState()
  if (!vendors || !vendors.length) return []
  return vendors
}
export const getVendor = (id: string) => (): UserType | null => {
  const { vendors } = storeInfo.store.getState()
  if (!vendors || !vendors.length) return null
  return vendors.find((vendor) => vendor.id === id) || null
}

export const getVendorPrimaryEmail = (id: string) => (): string => {
  const vendor = getVendor(id)()
  if (!vendor) return ''
  const primaryEmail =
    vendor.email.find((e) => e.emailType === EMAIL_TYPE.PRIMARY)?.email || ''
  return primaryEmail || ''
}
