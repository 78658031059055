import { FC } from 'react'
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { CircularProgressbarProps } from './types'
import { Box } from '../../..'

const CustomCircularProgressbar: FC<CircularProgressbarProps> = ({ progress, total }) => {
  const percentage = progress && total ? (progress / total * 100) : 0
  return (
    <Box style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
      <Box style={{ width: '120px' }}>
        <CircularProgressbarWithChildren
          value={percentage}
          strokeWidth={8}
          styles={buildStyles({
            pathColor: 'rgba(39, 186, 45, 1)',
            textSize: '34px',
            rotation: 0.5
          })}
        >
          <div style={{ fontSize: 34, marginTop: -5 }}>
            <strong>{progress || 0}</strong>
          </div>
        </CircularProgressbarWithChildren>
      </Box>
    </Box>
  )
}

export default CustomCircularProgressbar
