import { authTypes } from '../auth/types'
import {
  territoriesTypes,
  Territory,
  TerritoriesType,
  TerritoriesActions
} from './types'

const initialState: TerritoriesType = []

const reducer = (state = initialState, action: TerritoriesActions): TerritoriesType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case territoriesTypes.SET_TERRITORIES: {
      return payload as Territory[]
    }

    default:
      return state
  }
}

export default reducer
