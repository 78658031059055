import { FC, useEffect, useState } from 'react'
import env from '@beam-australia/react-env'
import {
  Box,
  Dialog,
  Button,
  DialogContent,
  Typography,
  Grid,
  CircularProgress,
  ProfileCardOrganization,
} from '../../..'
import useStyles from './styles'
import { DialogInvitationAcceptProps } from './types'
import { awesomeSuccess, organizationOrderSample } from '../../../../../assets'
import { isEmpty } from '../../../../../helpers'

const FILES_URL = env('FILES_URL') ?? ''

const DialogInvitationAccept: FC<DialogInvitationAcceptProps> = ({
  open,
  setOpen,
  userFullName,
  userAddress,
  picture,
  invitation = { id: '', networkId: '' },
  onReply,
  join = false
}) => {
  const classes = useStyles()

  const { id, networkId } = invitation

  const [accepted, setAccepted] = useState(false)
  const [rejected, setRejected] = useState(false)
  const [rejectWarning, setRejectWarning] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (rejected) {
      setTimeout(function () {
        setOpen(false)
      }, 3000)
    }
  }, [rejected])

  const handleAccept = () => {
    if (!loading) {
      setLoading(true)
      onReply(id, 'ACCEPTED', (success) => {
        setLoading(false)
        setAccepted(success)
      })
    }
  }

  const handleReject = () => {
    if (!loading) {
      setLoading(true)
      onReply(id, 'REJECTED', (success) => {
        setLoading(false)
        setRejected(success)
        setRejectWarning(!success)
      })
    }
  }

  const handleRejectWarning = () => {
    setRejectWarning(true)
  }

  const handleClose = () => {
    if (!loading) {
      setOpen(false)
    }
  }

  const AcceptedContent = () => (
    <>
      <DialogContent>
        <Box className={classes.iconBox}>
          <img alt="" src={awesomeSuccess} className={classes.icon} />
          <Typography variant="h6" className={classes.awesome}>
            Awesome!
          </Typography>
        </Box>
        <Typography variant="body1">
          Admin Assigned Jobs/Site Visits will have this icon in the Job card.
        </Typography>
      </DialogContent>
      <DialogContent>
        <img alt="" style={{ width: '100%' }} src={organizationOrderSample} />
      </DialogContent>
      <DialogContent>
        <Button
          variant="contained"
          color="primary"
          component="span"
          onClick={handleClose}
          size="large"
          fullWidth
        >
          GOT IT!
        </Button>
      </DialogContent>
    </>
  )

  const RejectedContent = () => (
    <>
      <DialogContent>
        <Typography variant="h6" className={classes.title}>
          Done!
        </Typography>
        <Typography variant="body1">
          {`You have rejected ${userFullName}’s invitation.`}
        </Typography>
        <DialogContent></DialogContent>
        <ProfileCardOrganization
          primary={userFullName}
          secondary={userAddress}
          preview={
            !isEmpty(picture) ? FILES_URL.concat(picture as string) : null
          }
          status="organization"
          small
        />
      </DialogContent>
    </>
  )

  const RejectWarningContent = () => (
    <>
      <DialogContent>
        <Typography variant="h6" className={classes.title}>
          {`Are you sure to rejects ${networkId}’s invite for his Organization?`}
        </Typography>
      </DialogContent>
      <DialogContent>
        <ProfileCardOrganization
          primary={userFullName}
          secondary={userAddress}
          preview={
            !isEmpty(picture) ? FILES_URL.concat(picture as string) : null
          }
          status="revoke"
          small
        />
      </DialogContent>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              component="span"
              onClick={handleReject}
              className={classes.rejectWarningButton}
              size="large"
              fullWidth
            >
              {loading ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                'YES, REJECT INVITE'
              )}
            </Button>
          </Grid>
          {!loading && (
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                component="span"
                onClick={handleClose}
                size="large"
                fullWidth
              >
                NO, CANCEL
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </>
  )

  const Content = () => (
    <>
      <DialogContent>
        <Typography variant="h6" className={classes.title}>
          {(join ? 'Join ' : '') + `${userFullName}’s Organization`}
        </Typography>
      </DialogContent>
      <DialogContent>
        <ProfileCardOrganization
          primary={userFullName}
          secondary={userAddress}
          preview={
            !isEmpty(picture) ? FILES_URL.concat(picture as string) : null
          }
          status="organization"
          small
        />
      </DialogContent>
      <DialogContent>
        <Typography variant="body1">
          By joining this Organization you’ll be assigned to Jobs.
        </Typography>
      </DialogContent>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              component="span"
              onClick={handleAccept}
              size="large"
              fullWidth
            >
              {loading ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                'ACCEPT INVITE'
              )}
            </Button>
          </Grid>
          {!loading && (
            <>
              <Grid item xs={12}>
                <Button
                  variant="contained"
                  component="span"
                  className={classes.rejectButton}
                  onClick={handleRejectWarning}
                  size="large"
                  fullWidth
                >
                  REJECT INVITE
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="text"
                  component="span"
                  className={classes.closeButton}
                  onClick={handleClose}
                  size="large"
                  fullWidth
                >
                  SEE LATER
                </Button>
              </Grid>
            </>
          )}
        </Grid>
      </DialogContent>
    </>
  )

  return (
    <Dialog open={open} classes={{ paper: classes.root }}>
      {accepted && <AcceptedContent />}
      {rejected && <RejectedContent />}
      {rejectWarning && <RejectWarningContent />}
      {!accepted && !rejected && !rejectWarning && <Content />}
      <DialogContent />
    </Dialog>
  )
}

export default DialogInvitationAccept
