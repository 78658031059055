
import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      padding: theme.spacing(2),
      paddingBottom: 0
    },
    content: {
      height: '100%',
      display: 'grid',
      gridTemplateRows: 'min-content auto min-content'
    },
    title: {
      display: 'flex',
      gap: theme.spacing(3),
      alignItems: 'center'
    },
    textField: {
      height: '100%',
      '& div': {
        alignItems: 'baseline',
        height: '100%'
      },
      '& fieldset': {
        borderRadius: theme.spacing(3) / 2
      },
      '& textarea:first-child': {
        height: '100% !important'
      }
    },
    text: {
      fontSize: '16px'
    },
    noCursor: {
      fontSize: '16px',
      color: 'transparent',
      textShadow: '0 0 0 ' + theme.palette.text.primary
    },
    warning: {
      display: 'grid',
      justifyContent: 'center'
    }
  })
)
