/** TYPES **/
export const tosCheckTypes = {
  SET_TOS_CHECKED: 'SET_TOS_CHECKED',
  FETCH_TOS_ACCEPTED: 'FETCH_TOS_ACCEPTED'
}

/** ACTIONS **/
export interface SetTosCheckAction {
  type: typeof tosCheckTypes.SET_TOS_CHECKED
  payload: null
}

export interface FetchTosAcceptedAction {
  type: typeof tosCheckTypes.FETCH_TOS_ACCEPTED
  payload: null
}

export type TosCheckActions =
  | SetTosCheckAction
  | FetchTosAcceptedAction

/** REDUCER **/
export type TosCheckType = boolean
