import { FC } from 'react'
import { isEmpty } from '../../../../../helpers'
import { Typography, Link } from '../../..'
import useStyles from './styles'
import { BottomLinkProps } from './types'

const BottomLink: FC<BottomLinkProps> = ({ question, linkText, onClick, href, target }) => {
  const classes = useStyles()

  return (
    <div className={classes.center}>
      <Typography variant='body2'>
        {question}
        {' '}
        <Link
          href={href || ''}
          target={target || ''}
          onClick={(event) => {
            event.preventDefault()
            if (onClick) onClick()
            else if (isEmpty(href)) event.preventDefault()
          }}
        >
          {linkText}
        </Link>
      </Typography>
    </div>
  )
}

export default BottomLink
