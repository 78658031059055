
import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      background: 'transparent',
      boxShadow: 'none',
      borderRadius: '15px',
      maxWidth: '90%',
      maxHeight: '90%',
      position: 'relative'
    },
    close: {
      position: 'absolute',
      zIndex: 1,
      color: theme.palette.primary.contrastText,
      top: theme.spacing(1) / 2,
      right: theme.spacing(1) / 2
    },
    flip: {
      position: 'absolute',
      zIndex: 1,
      color: theme.palette.primary.contrastText,
      bottom: theme.spacing(2),
      left: theme.spacing(2)
    }
  })
)
