import { FC, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import env from '@beam-australia/react-env'
import { Box, IconButton, Typography, ProfileCardCentered } from '../../../UI'
import useStyles from './styles'
import { TemplateProps } from './types'
import { icons, badgeOn, badgeOff } from '../../../../assets'
import { history, isEmpty } from '../../../../helpers'
import { getUser, getUserFullName, isFullUser } from '../../../../ducks/selectors'
import { filesActions, userActions } from '../../../../ducks/actions'
import { ScrollWrapper } from '../..'

const FILES_URL = env('FILES_URL') ?? ''

const Template: FC<TemplateProps> = ({
  children, title = null, backButton = false, settingsButton = false, showUpload = false
}) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const goBack = history.useGoBack()
  const push = history.usePushForward()

  const user = useSelector(getUser)
  const userFullName = useSelector(getUserFullName)
  const fullUser = useSelector(isFullUser)

  const [uploading, setUploading] = useState(false)
  const [picture, setPicture] = useState(user.picture)

  const handleUpload = (image: File | null): void => {
    if (image) {
      setUploading(true)
      setPicture('')
      dispatch(filesActions.uploadFile(image,
        (uploadSucc, fileName) => {
          if (uploadSucc && !isEmpty(fileName)) {
            dispatch(userActions.updateUser({
              picture: fileName
            }, (success) => {
              setPicture(success ? fileName as string : user.picture)
              setUploading(false)
            }))
          } else {
            setPicture(user.picture)
            setUploading(false)
          }
        }
      ))
    }
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.headerContent}>
        <Box className={classes.header}>
          {backButton
            ? (
              <IconButton
                disableRipple
                color='inherit'
                className={classes.button}
                onClick={() => goBack()}
              >
                <icons.ChevronLeft className={classes.icon} fontSize='large' />
              </IconButton>)
            : <div />}
          <Typography variant='h6' className={classes.title}>
            {title}
          </Typography>
          {settingsButton
            ? (
              <IconButton
                disableRipple
                color='inherit'
                className={classes.button}
                onClick={() => push('settings')}
              >
                <icons.Settings className={classes.icon} fontSize='large' />
              </IconButton>)
            : <div />}
        </Box>
      </Box>
      <Box className={classes.user}>
        <ProfileCardCentered
          primary={userFullName}
          secondary='BosscatPro'
          preview={!isEmpty(picture) ? FILES_URL.concat(picture) : null}
          picture={null}
          loading={uploading}
          badge={fullUser ? badgeOn : badgeOff}
          onUpload={showUpload ? handleUpload : undefined}
        />
      </Box>
      <Box className={classes.contentBackground}>
        <ScrollWrapper relativeClass={classes.contentContainer} absoluteClass={classes.content}>
          {children}
        </ScrollWrapper>
      </Box>
    </Box>
  )
}

export default Template
