import { FC } from 'react'
import { Box, Typography } from '../../..'
import { PullToRefresh } from 'react-js-pull-to-refresh'
import { PullDownContentProps, PullToRefreshProps } from './types'

const PullDownContent: FC<PullDownContentProps> = ({ text }) => {
  return (
    <Box
      style={{
        display: 'flex',
        justifyContent: 'center',
        height: '65px'
      }}
    >
      <Typography
        color='primary'
        style={{ paddingTop: '5px' }}
      >
        {text}
      </Typography>
    </Box>
  )
}

const CustomPullToRefresh: FC<PullToRefreshProps> = ({ children, onRefresh }) => {
  return (
    <PullToRefresh
      pullDownContent={<PullDownContent text='Pull down to refresh' />}
      releaseContent={<PullDownContent text='Release to refresh' />}
      refreshContent={<PullDownContent text='' />}
      pullDownThreshold={65}
      onRefresh={async () => { onRefresh() }}
      triggerHeight='auto'
      backgroundColor='white'
      startInvisible
    >
      {children}
    </PullToRefresh>
  )
}

export default CustomPullToRefresh
