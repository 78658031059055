import { authTypes } from '../auth/types'
import { UserType } from '../user/types'
import { vendorsTypes, VendorsType, VendorsActions } from './types'

const initialState: VendorsType = []

const reducer = (state = initialState, action: VendorsActions): VendorsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: {
      return initialState
    }
    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }

    case vendorsTypes.SET_VENDORS: {
      return payload as UserType[]
    }

    default:
      return state
  }
}

export default reducer
