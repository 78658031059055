import { FC, lazy, Suspense } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

import Loader from '../components/pages/Loader'
import { authActions } from '../ducks/actions'
import { isAuthorized, isRegisterCompleted } from '../ducks/selectors'
import { history } from '../helpers'
import { RouteType } from './types'
import { NewTermsWrapper } from '../components/templates'


const PrivateRoute: FC<RouteType> = ({ path, page, ...rest }) => {
  const dispatch = useDispatch()
  const replace = history.useReplace()
  const authorized = useSelector(isAuthorized)
  const registerCompleted = useSelector(isRegisterCompleted)
  const PageComponent = lazy(() => import(`../components/pages/${page}`))
  const registerPath = path === '/register'

  if (!authorized) {
    dispatch(authActions.logoutRequest({
      callback: (success) => {
        if (success) replace('/')
      }
    }))
  }

  return (
    <Route
      exact
      {...rest}
      path={path}
      component={() => {
        if (!authorized) {
          return <Redirect to='/' />
        }
        if (registerPath && registerCompleted) {
          return <Redirect to='/home' />
        }
        if (!registerPath && !registerCompleted) {
          return <Redirect to='/register' />
        }
        return (
          <Suspense fallback={<Loader />}>
            <NewTermsWrapper>
              <PageComponent />
            </NewTermsWrapper>
          </Suspense>
        )
      }}
    />
  )
}

export default PrivateRoute
