import { authTypes } from '../auth/types'
import {
  tosCheckTypes,
  TosCheckType,
  TosCheckActions
} from './types'

const initialState: boolean = false

const reducer = (state = initialState, action: TosCheckActions): TosCheckType => {
  const { type } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case tosCheckTypes.SET_TOS_CHECKED: {
      return true
    }

    default:
      return state
  }
}

export default reducer
