
import { CircularProgress, useTheme } from '@material-ui/core'
import { FC } from 'react'
import { Dialog, Box, Grid, Button, Typography } from '../../..'
import { TERMS_PDF } from '../../../../../helpers'
import useStyles from './styles'
import { DialogTermsPorps } from './types'

const DialogTerms: FC<DialogTermsPorps> = ({ open, loading, onAccept, onDecline }) => {
  const classes = useStyles()
  const theme = useTheme()

  const handleAccept = () => {
    onAccept()
  }

  const handleDecline = () => {
    onDecline()
  }

  return (
    <Dialog
      open={open}
      fullWidth
      PaperProps={{
        style: {
          maxWidth: theme.breakpoints.values.md
        }
      }}
    >
      <Box className={classes.root}>
        <Grid
          container
          direction='column'
          spacing={2}
          className={classes.content}
        >
          <Grid item xs={12} className={classes.title}>
            <Typography variant='h6'>
              New Terms & Conditions
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <iframe
              src={TERMS_PDF.concat('#toolbar=0&navpanes=0')}
              style={{ width: '100%', height: '100%' }}
              title='new terms and conditions'
            />
          </Grid>
          <Grid container item spacing={1}>
            <Grid item xs={6}>
              <Button
                variant='text'
                onClick={handleDecline}
                fullWidth
              >
                Decline
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                variant='contained'
                color='primary'
                component='span'
                onClick={handleAccept}
                fullWidth
              >
                {loading
                  ? <CircularProgress color='inherit' size={24} />
                  : 'Accept'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}

export default DialogTerms
