
import { FC } from 'react'
import { Button, Box, Typography, IconButton } from '../../..'
import useStyles from './styles'
import { ItemBoxProps, ContentProps } from './types'

const Content: FC<ContentProps> = ({ children, icon, actions, textWrap = false }) => {
  const classes = useStyles()

  return (
    <div className={classes.content}>
      <div className={classes.icon}>
        {icon}
      </div>
      <Typography
        variant='body2'
        className={textWrap || actions
          ? classes.textWrap
          : classes.text}
      >
        {children.text}
      </Typography>
      {actions && actions.map((action, index) => {
        return (
          <IconButton
            key={index}
            className={classes.action}
            onClick={action.handleClick}
            size='small'
          >
            {action.icon}
          </IconButton>
        )
      })}
    </div>
  )
}

const ItemBox: FC<ItemBoxProps> = ({ children, icon, onClick, actions, textWrap = false }) => {
  const classes = useStyles()

  if (onClick !== undefined) {
    return (
      <Button
        className={classes.item}
        style={{ textTransform: 'none' }}
        disableElevation
        onClick={onClick}
      >
        <Content icon={icon} textWrap={textWrap}>
          {children}
        </Content>
      </Button>
    )
  } else {
    return (
      <Box className={classes.item}>
        <Content icon={icon} actions={actions} textWrap>
          {children}
        </Content>
      </Box>
    )
  }
}

export default ItemBox
