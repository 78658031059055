import {
  isEmpty as isEmptyLodash,
  capitalize as capitalizeLodash
} from 'lodash'
import moment from 'moment'

export * from './constants'
export * from './files'
export * as history from './history'
export * from './sort'
export * from './states'
export * from './trades'

export const TERMS_URL = 'https://www.bosscathome.com/usage-policy'
export const TERMS_PDF = 'https://punchlistcms.wpengine.com/wp-content/uploads/2022/11/pro_terms.pdf'
export const RESOURCES_URL = 'https://my.bosscathome.com/pro-app'

/** MASKS *******************************/
export const PHONE_MASK_INPUT = [/[1-9]/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
export const PHONE_MASK_REGEXP = /^([0-9]+(-[0-9]+)+)$/
/****************************************/

/** EMAILS ******************************/
export const validateEmail = (email: string): boolean => {
  // eslint-disable-next-line no-useless-escape
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}
/****************************************/

/** PASSWORDS ***************************/
export const containsLowercase = (str: string): boolean => /[a-z]/.test(str)
export const containsUppercase = (str: string): boolean => /[A-Z]/.test(str)
export const containsNumber = (str: string): boolean => /\d/.test(str)
export const containsLetter = (str: string): boolean => /[A-Za-z]/.test(str)
export const containsNumberLetter = (str: string): boolean => containsNumber(str) && containsLetter(str)

export const validatePassword = (psw: string): boolean => (
  psw.length >= 6 &&
  containsUppercase(psw) &&
  containsLowercase(psw) &&
  containsNumberLetter(psw)
)
/****************************************/

/** OBJECTS *****************************/
export const isEmpty = (object: any): boolean => {
  if (typeof object === 'string') { return isEmptyLodash(object.trim()) }
  return isEmptyLodash(object)
}
/****************************************/

/** STRINGS *****************************/
export const capitalize = (string?: string | undefined): string => {
  return capitalizeLodash(string)
}

export const formatPhone = (phone: number | null): string => {
  if (phone !== null) {
    const phoneCopy = phone.toString()
    if (phoneCopy.length === 10) {
      return phoneCopy.substring(0, 3).concat('-').concat(phoneCopy.substring(3, 6)).concat('-').concat(phoneCopy.substring(6))
    }
  }
  return ''
}

export const formatTimestamp = (timestamp: number | null, format: string): string => {
  if (timestamp !== null) {
    return moment.unix(timestamp).format(format)
  }
  return ''
}

export const concatSeparator = (fstStr: string, sndStr: string, separator: string): string => {
  return fstStr?.concat(fstStr && !isEmpty(fstStr) && sndStr && !isEmpty(sndStr) ? separator : '', sndStr || '')
}

export const errorTextTryingTo = (text: string): string => {
  if (!isEmpty(text)) return 'An error occurred trying to '.concat(text).concat(". We'll fix it soon!")
  return "An unexpected error occurred. We'll fix it soon!"
}

export const parseUrl = (url: string, rest: string): string => {
  if (!isEmpty(url)) return url.endsWith('/') ? url.concat(rest) : url.concat('/', rest)
  return ''
}
/****************************************/

/** NUMBERS *****************************/

export const round = (value: number, exp: number) => {
  if (value === null || value === undefined) return '0.'.concat('0'.repeat(exp))

  const roundedPrice = (
    Math.round((value + Number.EPSILON) * 100) / 100
  ).toFixed(2)
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
  })
  return formatter.format(Number(roundedPrice))
}
