import { FC, useState } from 'react'
import {
  Dialog, TextField, Button, DialogContent, Typography,
  FormControl, RadioGroup, FormControlLabel, Radio
} from '../../..'
import useStyles from './styles'
import { FeedbackModalProps } from './types'
import { isEmpty, ORDER_REJECT_TYPE } from '../../../../../helpers'
import { Grid } from '@material-ui/core'

const options = [
  { key: ORDER_REJECT_TYPE.SCHEDULE, value: 'Scheduling conflict' },
  { key: ORDER_REJECT_TYPE.PRICE, value: 'Bid price is too low' },
  { key: ORDER_REJECT_TYPE.DISINTEREST, value: 'Not interested' },
  { key: ORDER_REJECT_TYPE.OTHER, value: 'Other' }
]

const DialogRejectFeedback: FC<FeedbackModalProps> = ({ open, setOpen, onSubmit }) => {
  const classes = useStyles()

  const [submit, setSubmit] = useState(false)
  const [optionSelected, setOptionSelected] = useState<ORDER_REJECT_TYPE | null>(null)
  const [comment, setComment] = useState('')

  const otherSelected = optionSelected === ORDER_REJECT_TYPE.OTHER

  const validate: boolean =
    optionSelected !== null && (!otherSelected || (otherSelected && !isEmpty(comment)))

  const handleChange = (event: any) => {
    setOptionSelected(event.target.value)
  }

  const handleClose = () => {
    setOptionSelected(null)
    setComment('')
    setOpen(false)
  }

  const handleOnSubmit = () => {
    setSubmit(true)
    if (validate) {
      onSubmit(optionSelected as ORDER_REJECT_TYPE, comment)
      handleClose()
    }
  }

  return (
    <Dialog onClose={handleClose} open={open} classes={{ paper: classes.root }}>
      <DialogContent>
        <Typography variant='h6'>
          Please let us know why you are declining this Job
        </Typography>
        <Typography variant='subtitle2'>
          That way we can better tailor jobs sent to you!
        </Typography>
      </DialogContent>
      <DialogContent>
        <FormControl component='fieldset' className={classes.formControl}>
          <RadioGroup value={optionSelected} onChange={handleChange}>
            {options.map((option) =>
              <FormControlLabel key={option.key} value={option.key} control={<Radio color='primary' />} label={option.value} />
            )}
          </RadioGroup>
        </FormControl>
        {otherSelected && (
          <TextField
            variant='outlined'
            size='small'
            margin='normal'
            value={comment}
            className={classes.input}
            disabled={!otherSelected}
            placeholder='Let us know why...'
            error={submit && isEmpty(comment)}
            onChange={(event) => setComment(event.target.value)}
            fullWidth
          />
        )}
      </DialogContent>
      <DialogContent>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Button
              variant='contained'
              color='primary'
              component='span'
              onClick={handleOnSubmit}
              size='large'
              fullWidth
            >
              SUBMIT FEEDBACK
            </Button>
          </Grid>
          {/*  <Grid item xs={12}>
            <Button
              variant='text'
              onClick={handleClose}
              size='large'
              fullWidth
            >
              SKIP
            </Button>
          </Grid> */}
        </Grid>
      </DialogContent>
      <DialogContent />
    </Dialog>
  )
}

export default DialogRejectFeedback
