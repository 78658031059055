import { FC } from 'react'
import { useSelector } from 'react-redux'
import env from '@beam-australia/react-env'
import {
  Container,
  Box,
  IconButton,
  Avatar,
  Typography,
  useTheme,
} from '../../../UI'
import useStyles from './styles'
import { TemplateProps } from './types'
import { icons, logo } from '../../../../assets'
import { history, isEmpty } from '../../../../helpers'
import { getUser } from '../../../../ducks/selectors'

const FILES_URL = env('FILES_URL') ?? ''

const Template: FC<TemplateProps> = ({
  children,
  title = null,
  centerTitle = true,
  withSkip = false,
  backButton = true,
  showLogo = true,
  maxWidth,
  additional,
  fullHeight = false,
  backPage = null
}) => {
  const classes = useStyles()
  const goBack = history.useGoBack()
  const replace = history.useReplace()
  const theme = useTheme()

  const { picture } = useSelector(getUser)

  const goBackPage = () => {
    if (backPage) {
      replace(backPage)
    } else {
      goBack()
    }
  }

  return (
    <Container
      className={classes.root}
      style={{
        maxWidth: maxWidth
          ? theme.breakpoints.values[maxWidth]
          : theme.breakpoints.values.xl,
        height: fullHeight ? '100vh' : 'auto',
      }}
    >
      <Box
        className={
          title
            ? classes.headerTitle
            : backButton
              ? classes.headerBack
              : classes.header
        }
        style={{
          maxWidth: maxWidth
            ? theme.breakpoints.values[maxWidth]
            : theme.breakpoints.values.lg,
        }}
      >
        {backButton && (
          <IconButton
            disableRipple
            className={classes.backButton}
            onClick={() => goBackPage()}
          >
            <icons.ChevronLeft color="primary" fontSize="large" />
          </IconButton>
        )}
        {title ? (
          <>
            <Typography
              color="primary"
              variant="h6"
              className={centerTitle ? classes.title : ''}
            >
              {title}
            </Typography>
            {withSkip && (
              <Typography
                color="primary"
                variant="h6"
                className={classes.skip}
                onClick={() => goBackPage()}
              >
                Skip for Now
              </Typography>
            )}
          </>
        ) : (
          <>
            {showLogo ? (
              backButton ? (
                <img src={logo} alt="" className={classes.logoCentered} />
              ) : (
                <div>
                  {' '}
                  <img src={logo} alt="" className={classes.logo} />{' '}
                </div>
              )
            ) : (
              <div />
            )}
            {additional ||
              (!isEmpty(picture) && (
                <Avatar
                  alt=""
                  src={FILES_URL.concat(picture)}
                  className={classes.avatar}
                />
              ))}
          </>
        )}
      </Box>
      <Box
        className={classes.content}
        style={{
          maxWidth: maxWidth
            ? theme.breakpoints.values[maxWidth]
            : theme.breakpoints.values.md,
        }}
      >
        {children}
      </Box>
    </Container>
  )
}

export default Template
