/* eslint-disable camelcase */
import { useState, useEffect, FC } from 'react'
import { useSelector } from 'react-redux'
import { Grid, Typography, Box, Button, CircularProgress } from '../../../../UI'
import useStyles from './styles'
import { concatSeparator, formatTimestamp, history, isEmpty, ORDER_ITEM_STATUS, ORDER_TYPE, round } from '../../../../../helpers'
import { getOrder, getOrderItems, getOrderState, allItemsFinished, isAdmin } from '../../../../../ducks/selectors'
import { icons } from '../../../../../assets'
import { generatePdf } from '../../../../pages/Order/Items/ItemsPdf'

const OrderProfile: FC = () => {
  const classes = useStyles()

  const { order: id } = history.getParamValues()
  const order = useSelector(getOrder(id))

  const orderState = useSelector(getOrderState(id))
  const allFinished = useSelector(allItemsFinished(id))

  const items = useSelector(getOrderItems(id))
  const itemsInProgress = useSelector(getOrderItems(id, [ORDER_ITEM_STATUS.APPROVED, ORDER_ITEM_STATUS.PENDING, ORDER_ITEM_STATUS.REJECTED]))
  const itemsFinished = useSelector(getOrderItems(id, [ORDER_ITEM_STATUS.COMPLETE, ORDER_ITEM_STATUS.ACCEPTED]))
  const itemsCompleted = useSelector(getOrderItems(id, [ORDER_ITEM_STATUS.COMPLETE]))
  const itemsAccepted = useSelector(getOrderItems(id, [ORDER_ITEM_STATUS.ACCEPTED]))
  const isOrgAdmin = useSelector(isAdmin)


  const [totalCount, setTotalCount] = useState(0)
  const [finishedCount, setFinishedCount] = useState(0)

  const [downloadingPdf, setDownloadingPdf] = useState(false)

  useEffect(() => {
    if (items) setTotalCount(items.length)
    if (itemsFinished) setFinishedCount(itemsFinished.length)
  }, [items, itemsFinished])

  if (!order) return <div>No order</div>
  else {
    const {
      bidPrice, orderName, orderPublicId, type,
      scheduledStartTimestamp, scheduledEndTimestamp,
      checkedInTimestamp, completedTimestamp,
      address: { city, zipCode, line_1, line_2 },
      assignedByName
    } = order

    const showBidPrice = (isOrgAdmin || isEmpty(assignedByName)) && bidPrice !== null

    let address = city
    address = concatSeparator(address, zipCode, ', ')
    address = concatSeparator(address, line_2, ', ')

    return (
      <Grid container style={{ display: 'grid' }}>
        <Grid container item style={{ alignItems: 'baseline' }}>
          <Grid container item xs={6} style={{ display: 'grid' }}>
            <Grid item>
              <Typography variant='subtitle1'>
                {line_1}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant='caption'>
                {address}
              </Typography>
            </Grid>
            {(type === ORDER_TYPE.ORDER && orderPublicId) && (
              <Grid item>
                <Typography variant='caption'>
                  <b>Order Code:</b> {orderPublicId}
                </Typography>
              </Grid>)}
          </Grid>
          <Grid
            container item xs={6} style={{
              textAlign: 'end'
            }}
          >
            {showBidPrice && <Grid item xs={12}>
              <Typography variant='h6' color='primary'>
                $ {round(bidPrice,2)}
              </Typography>
            </Grid>}
            <Grid item xs={12}>
              <Typography variant='caption'>
                {`${orderName || ''}`}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant='caption'>
                <b>Items:</b> {`${finishedCount}/${totalCount}`}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item className={classes.infoBox}>
          <Grid container item className={classes.info}>
            <Grid container item xs={7} sm={6} className={classes.infoContent}>
              <Grid item xs={12}>
                <Typography variant='caption'><b>Job Start Date: </b></Typography>
                <Typography variant='caption'>
                  {formatTimestamp(scheduledStartTimestamp, 'MM/DD/YYYY HH:mm')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='caption'><b>Job End Date: </b></Typography>
                <Typography variant='caption'>
                  {formatTimestamp(scheduledEndTimestamp, 'MM/DD/YYYY HH:mm')}
                </Typography>
              </Grid>
              {(orderState === 'checkin' || orderState === 'complete') && (
                <Grid item xs={12}>
                  <Typography variant='caption'><b>Checkin Date: </b></Typography>
                  <Typography variant='caption'>
                    {formatTimestamp(checkedInTimestamp, 'MM/DD/YYYY HH:mm')}
                  </Typography>
                </Grid>)}
              {orderState === 'complete' && (
                <Grid item xs={12}>
                  <Typography variant='caption'><b>Completed Date: </b></Typography>
                  <Typography variant='caption'>
                    {formatTimestamp(completedTimestamp, 'MM/DD/YYYY HH:mm')}
                  </Typography>
                </Grid>)}
            </Grid>
            <Grid container item xs={5} sm={6} className={classes.statusContainer}>
              {totalCount > 0 && (
                <Button
                  variant='outlined'
                  color='primary'
                  className={classes.downloadPdf}
                  startIcon={downloadingPdf
                    ? <CircularProgress color='inherit' size={20} />
                    : <icons.GetApp />}
                  onClick={() => {
                    if (!downloadingPdf) {
                      setDownloadingPdf(true)
                      // ! CHANGE THIS ALSO TO SHOW ACCEPTED AND COMPLETED
                      generatePdf({
                        orderState,
                        order,
                        finishedCount,
                        totalCount,
                        itemsInProgress,
                        itemsCompleted,
                        itemsAccepted,
                        allFinished,
                        showBidPrice
                      }).then(() => setDownloadingPdf(false))
                    }
                  }}
                >
                  PDF
                </Button>
              )}
              {(orderState === 'complete' || allFinished) && (
                <Box className={`${classes.status} ${classes.backgroundComplete}`}>
                  <Typography variant='caption' className={classes.statusComplete}><b>COMPLETED</b></Typography>
                  <icons.CheckCircle className={`${classes.statusIcon} ${classes.statusComplete}`} />
                </Box>
              )}
              {orderState === 'checkin' && !allFinished && (
                <Box className={`${classes.status} ${classes.backgroundProgress}`}>
                  <Typography variant='caption' className={classes.statusProgress}><b>IN PROGRESS</b></Typography>
                  <icons.CheckCircle className={`${classes.statusIcon} ${classes.statusProgress}`} />
                </Box>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default OrderProfile
