import { Theme, createStyles, makeStyles } from '../../../UI'

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh',
            display: 'flex',
        },
        header: {
            margin: '40px 30px 0',
        },
        container: {
            maxWidth: '35%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            textAlign: 'center'
        },
        error404: {

        },
        title: {
            fontSize: '40px',
            fontWeight: 600
        },
        subtitle: {
            fontSize: '20px',
            fontWeight: 300,
        },
        subtitleBold: {
            fontWeight: 700,
        },
        img: {
            display: 'flex',
            width: '164px',
            margin: '50px 0px',
            borderRadius: '8px'
        },
        button: {
            margin: '2rem 0',
            fontSize: '16px',
            fontWeight: 400,
            width: '220px',
            padding: '30px 0',
            textTransform: 'none',
            color: 'white',
            backgroundColor: 'var(--navbar-background-color-selected)',
            borderRadius: 32,
            '&:hover': {
                backgroundColor: 'var(--navbar-background-color)',
            }
        },
        buttonIcon: {
            width: '16px',
            height: '16px',
        },
    })
)