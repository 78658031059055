import { authTypes } from '../auth/types'
import {
  userReferralsTypes,
  UserReferralsType,
  UserReferralsActions,
  UserReferralsValue,
  UserReferralsValues
} from './types'

const initialState: UserReferralsType = {}

const reducer = (state = initialState, action: UserReferralsActions): UserReferralsType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case userReferralsTypes.SET_VALUE: {
      const { attr, value } = payload as UserReferralsValue
      return {
        ...state,
        [attr]: value
      }
    }
    case userReferralsTypes.SET_VALUES: {
      const { attrs } = payload as UserReferralsValues
      return {
        ...state,
        ...attrs as UserReferralsType
      }
    }
    default:
      return state
  }
}

export default reducer
