import { Theme, createStyles, makeStyles } from '../../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    statusContainer: {
      display: 'grid',
      gridAutoFlow: 'row',
      gap: theme.spacing(1),
      justifyContent: 'end',
      [theme.breakpoints.up('md')]: {
        gridAutoFlow: 'column',
        gap: theme.spacing(3)
      }
    },
    infoBox: {
      width: '100vw',
      display: 'flex',
      justifyContent: 'center',
      margin: '8px -16px',
      background: '#FAFAFA',
      [theme.breakpoints.up('sm')]: {
        margin: '8px -24px'
      },
      [theme.breakpoints.up('lg')]: {
        margin: '8px calc(-1 * (100vw - 820px) / 2)'
      },
      [theme.breakpoints.up('xl')]: {
        position: 'relative',
        left: 'calc((100vw - 1024px) / 2)',
        maxWidth: theme.breakpoints.values.xl,
        borderRadius: '8px 8px 0 0'
      }
    },
    info: {
      width: '100%',
      padding: '8px 24px',
      maxWidth: theme.breakpoints.values.lg
    },
    infoContent: {
      alignContent: 'center'
    },
    status: {
      display: 'flex',
      gap: theme.spacing(1) / 2,
      height: '40px',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '8px',
      margin: '2px 0',
      padding: '0 8px'
    },
    backgroundProgress: {
      border: '1px solid ' + theme.palette.warning.dark,
      backgroundColor: 'rgba(253, 179, 0, 0.08)'
    },
    backgroundComplete: {
      border: '1px solid ' + theme.palette.success.dark,
      backgroundColor: 'rgba(39, 186, 45, 0.08)'
    },
    statusProgress: { color: theme.palette.warning.dark },
    statusComplete: { color: theme.palette.success.dark },
    statusIcon: {
      width: theme.spacing(2),
      height: theme.spacing(2)
    },
    downloadPdf: {
      minWidth: '100px',
      height: '36px',
      [theme.breakpoints.up('md')]: {
        height: '40px',
        marginTop: '2px'
      }
    }
  })
)
