/** TYPES **/
export const userReferralsTypes = {
  SET_VALUE: 'SET_USER_REFERRALS_VALUE',
  SET_VALUES: 'SET_USER_REFERRALS_VALUES'
}

/** ACTIONS **/
export interface UserReferralsValue {
  attr: keyof UserReferralsType
  value: any
}

export interface UserSetValueAction {
  type: typeof userReferralsTypes.SET_VALUE
  payload: UserReferralsValue
}

export interface UserReferralsValues {
  attrs: Partial<UserReferrals>
}

export interface UserSetValuesAction {
  type: typeof userReferralsTypes.SET_VALUES
  payload: UserReferralsValues
}

export type UserReferralsActions =
  | UserSetValueAction
  | UserSetValuesAction

/** REDUCER **/
export interface UserReferrals {
  source?: string
  campaign?: string
  content?: string
  medium?: string
}

export type UserReferralsType = UserReferrals | {}
