import { authTypes } from '../auth/types'
import {
  tradeTypes,
  TradeType,
  TradeTypes,
  TradeTypesActions
} from './types'

const initialState: TradeTypes = []

const reducer = (state = initialState, action: TradeTypesActions): TradeTypes => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: { return initialState }
    case authTypes.LOGOUT_REQUEST: { return initialState }

    case tradeTypes.SET_TRADE_TYPES: {
      return payload as TradeType[]
    }

    default:
      return state
  }
}

export default reducer
