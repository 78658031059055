import { FC } from 'react'
import { Typography, Box } from '../../..'
import { icons } from '../../../../../assets'
import { CHECK_STATUS } from '../../../../../helpers'
import useStyles from './styles'
import { CheckLineProps } from './types'

const CheckLine: FC<CheckLineProps> = ({ children, status = CHECK_STATUS.TODO }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      {status === CHECK_STATUS.TODO &&
        <icons.CheckCircle
          fontSize='small'
          className={classes.checkTodo}
        />}
      {status === CHECK_STATUS.PENDING &&
        <icons.Schedule
          fontSize='small'
          className={classes.checkWarning}
        />}
      {status === CHECK_STATUS.DONE &&
        <icons.CheckCircle
          fontSize='small'
          className={classes.checkDone}
        />}
      {status === CHECK_STATUS.ERROR &&
        <icons.ErrorOutline
          fontSize='small'
          className={classes.checkError}
        />}
      <Typography variant='body2'>
        {children}
      </Typography>
    </Box>
  )
}

export default CheckLine
