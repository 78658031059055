import axios from '../axios'
import { ApiController } from './types'

const base = '/territories'

const apiController: ApiController = {
  getTerritories: async (serviceable) =>
    await axios
      .get(base, { params: { serviceable } })
      .then(response => response.data)
}

export default apiController
