import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    linearRoot: {
      background: 'rgba(39, 186, 45, 0.08)',
      height: 6,
      borderRadius: 4
    },
    linearColor: {
      backgroundColor: theme.palette.success.main
    },
    value: {
      minWidth: '35px',
      textAlign: 'end',
      color: theme.palette.success.dark
    }
  })
)
