import { FC } from 'react'
import { Typography, Grid } from '../../..'
import { icons } from '../../../../../assets'
import useStyles from './styles'
import { InfoMessageProps } from './types'

const InfoMessage: FC<InfoMessageProps> = ({ title, description, bottom, withSchedule = true }) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.validating}>
      <Grid
        item xs={1} style={
          withSchedule
            ? { textAlign: 'center' }
            : { display: 'flex', alignItems: 'center' }
        }
      >
        {withSchedule
          ? <icons.Schedule className={classes.icon} />
          : <icons.InfoOutlined className={classes.icon} />}
      </Grid>
      <Grid container item xs={11} spacing={1} className={classes.text}>
        <Grid item>
          <Typography variant='body1'>
            {title}
          </Typography>
        </Grid>
        {description && (
          <Grid item>
            <Typography variant='body2'>
              {description}
            </Typography>
          </Grid>
        )}
        {bottom && (
          <Grid item>
            <Typography variant='body2'>
              {bottom}
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default InfoMessage
