import { invitationsTypes, Invitation, InvitationsActions } from './types'

const actions = {
  fetchInvitations: (
    payload?: string[],
    callback?: (succ: boolean) => void
  ): InvitationsActions => ({
    type: invitationsTypes.FETCH_INVITATIONS,
    payload,
    callback,
  }),
  fetchSentInvitations: (
    payload: string,
    callback?: (succ: boolean) => void
  ): InvitationsActions => ({
    type: invitationsTypes.FETCH_SENT_INVITATIONS,
    payload,
    callback,
  }),
  setInvitations: (payload: Invitation | []): InvitationsActions => ({
    type: invitationsTypes.SET_INVITATIONS,
    payload,
  }),
  addInvitations: (payload: Invitation | []): InvitationsActions => ({
    type: invitationsTypes.ADD_INVITATIONS,
    payload,
  }),
  sendAnInvitation: (
    recipientEmail: string,
    callback?: (succ: boolean) => void
  ): InvitationsActions => ({
    type: invitationsTypes.SEND_AN_INVITATION,
    payload: {
      recipientEmail,
    },
    callback,
  }),
  replyAnInvitation: (
    invitationId: string,
    request: 'ACCEPTED' | 'REJECTED',
    callback?: (succ: boolean) => void
  ): InvitationsActions => ({
    type: invitationsTypes.REPLY_AN_INVITATION,
    payload: {
      invitationId,
      request,
    },
    callback,
  }),
}

export default actions
