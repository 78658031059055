import {
  userTypes,
  UserValue,
  UserValues,
  UserActions,
  UserType,
  UpdateUserListPayload,
  LmsAccess
} from './types'

const actions = {
  fetchCurrentUser: (
    callback: (succ: boolean) => void
  ): UserActions => ({
    type: userTypes.FETCH_CURRENT_USER,
    payload: null,
    callback
  }),
  createUser: (
    callback: (succ: boolean) => void
  ): UserActions => ({
    type: userTypes.CREATE_USER,
    payload: null,
    callback
  }),
  updateUser: (
    payload: Partial<UserType>,
    callback: (succ: boolean) => void
  ): UserActions => ({
    type: userTypes.UPDATE_USER,
    payload,
    callback
  }),
  updateUserList: (
    payload: UpdateUserListPayload,
    callback: (succ: boolean) => void
  ): UserActions => ({
    type: userTypes.UPDATE_USER_LIST,
    payload,
    callback
  }),
  setUserValue: (payload: UserValue): UserActions => ({
    type: userTypes.SET_VALUE,
    payload
  }),
  setUserValues: (payload: UserValues): UserActions => ({
    type: userTypes.SET_VALUES,
    payload
  }),
  getLmsAccessToken: (
    callback: (lmsAccess: LmsAccess | null) => void
  ): UserActions => ({
    type: userTypes.GET_LSM_ACCESS,
    payload: null,
    callback
  }),
  fetchVendorByEmail: (payload: string, callback?: (succ: boolean) => void): UserActions => ({
    type: userTypes.FETCH_VENDOR_BY_EMAIL,
    payload,
    callback
  })
}

export default actions
