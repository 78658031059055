import { useState, useEffect, FC } from 'react'
import {
  Grid,
  Badge,
  Avatar,
  Button,
  Camera,
  Typography,
  Box,
  CircularProgress,
} from '../../..'
import useStyles from './styles'
import { icons } from '../../../../../assets'
import { ProfileCardCenteredProps } from './types'
import { dataURItoFile, isEmpty } from '../../../../../helpers'

const ProfileCardCentered: FC<ProfileCardCenteredProps> = ({
  primary,
  secondary,
  picture,
  preview = null,
  onUpload,
  badge,
  loading = false,
}) => {
  const classes = useStyles()

  const [openCamera, setOpenCamera] = useState<boolean>(false)
  const [picturePreview, setPicturePreview] = useState<string | null>(preview)

  useEffect(() => {
    if (picture) {
      const tmppath = URL.createObjectURL(picture)
      setPicturePreview(tmppath)
    }
  }, [picture])

  useEffect(() => {
    if (preview !== picturePreview) {
      setPicturePreview(preview)
    }
  }, [preview, picturePreview])

  const handleUpload = (event: any): void => {
    if (onUpload) {
      onUpload(event.target.files[0])
    }
  }

  const handleCamera = (): void => {
    setOpenCamera(true)
  }

  const handleTakePhoto = (photoUri: string): void => {
    if (onUpload) {
      onUpload(dataURItoFile(photoUri, 'picture'))
    }
    setOpenCamera(false)
  }

  const getPicture = (): JSX.Element => {
    if (loading) {
      return (
        <Box className={classes.picture}>
          <CircularProgress color="inherit" className={classes.loading} />
        </Box>
      )
    }
    if (picturePreview !== null) {
      return <Avatar alt="" src={picturePreview} className={classes.picture} />
    }
    return (
      <Avatar alt="" className={classes.icon}>
        <icons.PersonOutlineTwoTone
          fontSize="large"
          className={classes.personIcon}
        />
      </Avatar>
    )
  }

  return (
    <Grid container spacing={1} style={{ textAlign: 'center' }}>
      <Grid item xs={12}>
        {!isEmpty(badge) ? (
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            badgeContent={
              <Avatar alt="badge" src={badge} className={classes.badge} />
            }
          >
            {getPicture()}
          </Badge>
        ) : (
          getPicture()
        )}
      </Grid>
      <Grid container item className={classes.bottom}>
        {onUpload ? (
          <>
            <input
              accept="image/*"
              style={{ display: 'none' }}
              id="contained-button-file"
              type="file"
              onChange={handleUpload}
            />
            <Camera
              defaultFacingUser
              open={openCamera}
              setOpen={setOpenCamera}
              onTakePhoto={handleTakePhoto}
            />
            <Grid container item spacing={1} className={classes.buttons}>
              <Grid item xs={6}>
                <label htmlFor="contained-button-file">
                  <Button
                    variant="contained"
                    fullWidth
                    component="span"
                    className={classes.button}
                    endIcon={<icons.CloudUploadTwoTone />}
                  >
                    <b>UPLOAD</b>
                  </Button>
                </label>
              </Grid>
              <Grid item xs={6}>
                <Button
                  variant="contained"
                  fullWidth
                  component="span"
                  className={classes.button}
                  onClick={handleCamera}
                  endIcon={<icons.CameraAltTwoTone />}
                >
                  <b>TAKE</b>
                </Button>
              </Grid>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12}>
              <Typography variant="h6" className={classes.textColor}>
                {primary}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="caption" className={classes.textColor}>
                {secondary}
              </Typography>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  )
}

export default ProfileCardCentered
