import { Box, Typography, Checkbox } from '../../..'
import useStyles from './styles'
import { FC, useState } from 'react'
import { ExemptWCProps } from './types'
import { FormControlLabel } from '@material-ui/core'

const ExemptWC: FC<ExemptWCProps> = ({ defaultCheck, onChange }) => {
  const classes = useStyles()

  const [checked, setChecked] = useState(defaultCheck)

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked)
    if (onChange) onChange(event.target.checked)
  }

  return (
    <Box className={classes.root}>
      <Box>
        <FormControlLabel
          labelPlacement='start'
          className={classes.check}
          control={
            <Checkbox
              disableRipple
              className={classes.checkbox}
              checked={checked}
              onChange={handleChange}
              name='accepted'
              color='primary'
            />
}
          label={
            <Typography variant='body1'>
              If WC Exempt click here
            </Typography>
          }
        />
      </Box>
      <Typography variant='body2'>
        <i>We may reach out for additional WC documentation, if required. </i>
      </Typography>
    </Box>
  )
}

export default ExemptWC
