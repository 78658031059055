/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import { toast } from 'react-toastify'
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import { Punchlist } from '../../api'
import { errorTextTryingTo } from '../../helpers'
import { vendorsActions } from '../actions'

import { vendorsTypes, FetchVendorsAction } from './types'

export function* fetchVendors({ callback }: FetchVendorsAction): SagaIterator {
  try {
    const data = yield call(Punchlist.vendors.getVendors)
    yield put(vendorsActions.setVendors(data))
    if (callback) yield call(callback, true)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the organization pros'))
    if (callback) yield call(callback, false)
  }
}

export function* deleteVendor({ payload, callback }: any): SagaIterator {
  try {
    const { id, email, networkId } = payload
    yield call(Punchlist.vendors.deleteVendor, id || email, networkId)
    if (callback) yield call(callback, true)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('delete/revoke the pro'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(vendorsTypes.FETCH_VENDORS, fetchVendors)
  yield takeLatest(vendorsTypes.DELETE_VENDOR, deleteVendor)
}
