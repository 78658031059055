import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '445px',
      padding: theme.spacing(3)
    },
    container: {
      display: 'grid',
      gap: theme.spacing(1)
    },
    rating: {
      fontSize: '3.5rem'
    },
    item: {
      width: '100%'
    },
    center: {
      textAlign: 'center'
    },
    disabled: {
      color: 'rgba(0, 0, 0, 0.26)',
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.26)'
      }
    }
  })
)
