import { createStyles, makeStyles, Theme } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '445px',
      borderRadius: '16px',
      display: 'grid',
      gap: '16px',
      gridTemplateRows: 'min-content auto min-content min-content',
    },
    assignButton: {
      fontSize: '1rem',
      color: theme.palette.success.contrastText,
      backgroundColor: theme.palette.success.main,
      '&:hover': {
        backgroundColor: theme.palette.success.dark,
      },
    },
    search: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      opacity: 1,
      backgroundColor: theme.palette.action.hover,
      '&:hover': {
        opacity: '0.8',
        backgroundColor: theme.palette.action.hover,
      },
    },
    searchIcon: {
      padding: theme.spacing(0, 2),
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      color: theme.palette.text.secondary,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      width: '100%',
    },
    input: {
      width: '100%',
      padding: theme.spacing(1, 1, 1, 0),
      // vertical padding + font size from searchIcon
      paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
      transition: theme.transitions.create('width'),
    },
  })
)
