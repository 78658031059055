import {
  tosCheckTypes,
  TosCheckActions
} from './types'

const actions = {
  setTosChecked: (): TosCheckActions => ({
    type: tosCheckTypes.SET_TOS_CHECKED,
    payload: null
  }),
  fecthTosAccepted: (): TosCheckActions => ({
    type: tosCheckTypes.FETCH_TOS_ACCEPTED,
    payload: null
  })
}

export default actions
