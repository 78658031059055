import { Address } from '../user/types'

/** TYPES **/
export const invitationsTypes = {
  FETCH_INVITATIONS: 'FETCH_INVITATIONS',
  FETCH_SENT_INVITATIONS: 'FETCH_SENT_INVITATIONS',
  SET_INVITATIONS: 'SET_INVITATIONS',
  ADD_INVITATIONS: 'ADD_INVITATIONS',
  SEND_AN_INVITATION: 'SEND_AN_INVITATION',
  REPLY_AN_INVITATION: 'REPLY_AN_INVITATION',
}

/** ACTIONS **/
export interface FetchInvitationsAction {
  type: typeof invitationsTypes.FETCH_INVITATIONS
  payload?: string[]
  callback?: (succ: boolean) => void
}

export interface FetchSentInvitationsAction {
  type: typeof invitationsTypes.FETCH_SENT_INVITATIONS
  payload: string
  callback?: (succ: boolean) => void
}

export interface SetInvitationsAction {
  type: typeof invitationsTypes.SET_INVITATIONS
  payload: Invitation | []
}

export interface AddInvitationsAction {
  type: typeof invitationsTypes.ADD_INVITATIONS
  payload: Invitation[]
}

export interface SendAnInvitationAction {
  type: typeof invitationsTypes.SEND_AN_INVITATION
  payload: {
    recipientEmail: string
  }
  callback?: (succ: boolean) => void
}

export interface ReplyAnInvitationAction {
  type: typeof invitationsTypes.REPLY_AN_INVITATION
  payload: {
    invitationId: string
    request: 'ACCEPTED' | 'REJECTED'
  }
  callback?: (succ: boolean) => void
}

export type InvitationsActions =
  | FetchInvitationsAction
  | FetchSentInvitationsAction
  | SetInvitationsAction
  | AddInvitationsAction
  | SendAnInvitationAction
  | ReplyAnInvitationAction

/** REDUCER **/
export interface Invitation {
  id: string
  recipientEmail: string
  networkId: string
  expiresOn?: number
  status: string
  repliedOn?: number
  sentOn: number
  removedOn: number
  vendorName: string
  vendorAddress: Address
  vendorPicture: string
  vendorId: string
  activeJobCount?: number
  completedJobCount?: number
}

export interface InvitationsType extends Array<Invitation> { }
