
import { useTheme } from '@material-ui/core'
import { FC, useState } from 'react'
import { Dialog, Box, Grid, Button, Typography, TextField } from '../../..'
import { isEmpty } from '../../../../../helpers'
import useStyles from './styles'
import { DialogNotePorps } from './types'

const DialogNote: FC<DialogNotePorps> = ({ open, onSave, onClose, noteText, noteWarning }) => {
  const classes = useStyles()
  const theme = useTheme()

  const [text, setText] = useState(noteText || '')

  const handleSave = () => {
    if (onSave) onSave(text)
    setText('')
  }

  const handleClose = () => {
    setText('')
    onClose()
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullWidth
      PaperProps={{
        style: {
          maxWidth: theme.breakpoints.values.md
        }
      }}
    >
      <Box className={classes.root}>
        <Grid
          container
          direction='column'
          spacing={2}
          className={classes.content}
        >
          <Grid item xs={12} className={classes.title}>
            <Typography variant='h6'>
              Note:
            </Typography>
            {noteWarning && (
              <Box className={classes.warning}>
                <Typography color='error' variant='body1'>
                  {noteWarning}
                </Typography>
              </Box>
            )}
          </Grid>
          <Grid item xs={12}>
            <TextField
              multiline
              fullWidth
              value={text}
              variant='outlined'
              InputProps={{
                readOnly: !onSave,
                disabled: !onSave,
                classes: { input: onSave ? classes.text : classes.noCursor }
              }}
              placeholder={onSave ? 'Write your notes here...' : ''}
              className={classes.textField}
              onChange={(event) => setText(event.target.value)}
            />
          </Grid>
          <Grid container item spacing={1}>
            {onSave && (
              <Grid item xs={12}>
                <Button
                  variant='contained'
                  color='primary'
                  component='span'
                  disabled={isEmpty(text)}
                  onClick={handleSave}
                  fullWidth
                >
                  Save Note
                </Button>
              </Grid>
            )}
            <Grid item xs={12}>
              <Button
                variant='text'
                onClick={handleClose}
                fullWidth
              >
                Close
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}

export default DialogNote
