import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '8px 16px',
      borderRadius: theme.spacing(1),
      background: '#FAFAFA'
    },
    title: {
      color: theme.palette.primary.dark
    },
    icon: {
      width: theme.spacing(1.5)
    },
    text: {
      display: 'flex',
      gap: theme.spacing(1) / 2
    },
    span: {
      paddingTop: '2px'
    }
  })
)
