import { FC } from 'react'
import { Button } from '../../..'
import { DialogDeletePorps } from './types'
import useStyles from './styles'
import DialogWarning from '../DialogWarning'
import { icons, exclamationMark } from '../../../../../assets'

const DialogDelete: FC<DialogDeletePorps> = ({ open, setOpen, comments, onDelete, onSkip }) => {
  const classes = useStyles()

  const handleDelete = (): void => {
    if (onDelete !== undefined) onDelete()
    setOpen(false)
  }

  const handleSkip = (): void => {
    if (onSkip !== undefined) onSkip()
    setOpen(false)
  }

  return (
    <DialogWarning
      open={open}
      setOpen={setOpen}
      icon={<img alt='' src={exclamationMark} className={classes.icon} />}
      acceptButton={(
        <Button
          fullWidth
          className={classes.deleteContained}
          endIcon={<icons.DeleteOutline />}
          size='large'
          variant='contained'
          component='span'
          onClick={handleDelete}
        >
          Delete
        </Button>)}
      onSkip={handleSkip}
      skipButtonText='Close'
      advice='This action can’t be undone'
      comments={comments}
    />
  )
}

export default DialogDelete
