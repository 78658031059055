import { applyMiddleware, createStore, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import env from '@beam-australia/react-env'
import { persistStore, persistReducer } from 'redux-persist'
import storage from 'redux-persist/lib/storage'

import rootSaga from './sagas'
import rootReducer from './reducers'

const w: any = window as Window
const devtools = w.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__

const sagaMiddleware = createSagaMiddleware()
const middlewares = [sagaMiddleware]
const composeEnhancers =
  env('NODE_ENV') !== 'production' &&
  Boolean(devtools) &&
  typeof window === 'object'
    ? devtools({})
    : compose

const persistConfig = {
  key: 'punchlist-pro-app',
  storage,
  blacklist: ['tosCheck'],
  migrate: (state: any) => {
    if (!state) {
      state = {}
    }
    const newState = {
      ...state,
      config: state.config,
      tosCheck: false,
      invitations: state.invitations,
      orders: state.orders,
      territories: state.territories,
      tradeTypes: state.tradeTypes,
      user: { ...state.user },
      userReferrals: state.userReferrals,
      vendors: state.vendors,
    }
    return Promise.resolve(newState)
  },
}

const persistedReducer = persistReducer(persistConfig, rootReducer)
const store = createStore(
  persistedReducer, // root reducer with router state
  composeEnhancers(applyMiddleware(...middlewares))
)

const persistor = persistStore(store as any)

// Used to clean the storage that uses Redux Persist.
// persistor.purge()

sagaMiddleware.run(rootSaga)

const storeInfo = {
  store,
  persistor,
}

export default storeInfo
