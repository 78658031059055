import * as icons from '../assets'

export const getTradeIcon = (name: string): string => {
  switch (name) {
    case 'mason': return icons.mason
    case 'radon': return icons.radon
    case 'carpentry': return icons.carpentry
    case 'gutters': return icons.gutters
    case 'structuralengineer': return icons.structuralengineer
    case 'crawlspace': return icons.crawlspace
    case 'plumbing': return icons.plumbing
    case 'landscaping': return icons.landscaping
    case 'garagedoorrepair': return icons.garagedoorrepair
    case 'painting': return icons.painting
    case 'generalhandyman': return icons.generalhandyman
    case 'appliance': return icons.appliance
    case 'roof': return icons.roof
    case 'hvac': return icons.hvac
    case 'chimneysweep': return icons.chimney
    case 'welding': return icons.welding
    case 'glassrepairreplacement': return icons.glassrepairreplacement
    case 'electrical': return icons.electrical
    case 'tilefloors': return icons.tilefloors
    case 'flooring': return icons.flooring
    case 'foundation': return icons.foundation
    case 'siding': return icons.siding
    case 'framing': return icons.framing
    case 'pool': return icons.pool
    case 'cleaning': return icons.cleaning
    case 'treeremoval': return icons.treeremoval
    case 'moldremediation': return icons.moldremediation
    case 'woodfloors': return icons.woodfloor
    case 'septic': return icons.septic
    case 'concrete': return icons.concrete
    case 'wastedisposal': return icons.wastedisposal
    case 'fencing': return icons.fence
    case 'carpet': return icons.carpet
    case 'generalcontractor': return icons.generalcontractor
    default: return icons.defaulttrade
  }
}
