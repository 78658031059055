import { FC } from 'react'
import { isMobile } from 'react-device-detect'
import { Box } from '../../../UI'
import { TemplateProps } from './types'
import useStyles from './styles'

const Template: FC<TemplateProps> = ({
  id = '', children, showScrollbar = false,
  relativeStyle = {}, absoluteStyle = {},
  relativeClass = '', absoluteClass = ''
}) => {
  const classes = useStyles()

  return (
    <Box
      id={id}
      className={`${classes.relative} ${relativeClass} ${isMobile && !showScrollbar ? classes.hideScrollbar : ''}`}
      style={relativeStyle}
    >
      <Box className={`${classes.absolute} ${absoluteClass}`} style={absoluteStyle}>
        {children}
      </Box>
    </Box>
  )
}
export default Template
