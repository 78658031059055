import { createStyles, makeStyles, Theme } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '445px',
      borderRadius: '16px',
    },
    title: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    text: {
      color: theme.palette.warning.dark,
      textAlign: 'center',
      backgroundColor: 'rgba(253, 179, 0, 0.08)',
      padding: '12px 24px',
      margin: '12px -24px 0',
    },
    button: {
      backgroundColor: theme.palette.error.dark,
      color: theme.palette.primary.contrastText,
      '&:hover': {
        backgroundColor: theme.palette.error.main,
      },
    },
    center: {
      textAlign: 'center',
    },
    icon: {
      fontSize: theme.spacing(10),
      marginBottom: '16px',
    },
  })
)
