import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    picture: {
      display: 'flex',
      width: theme.spacing(12),
      height: theme.spacing(12),
      borderRadius: '20px',
      justifyContent: 'center',
      alignItems: 'center'
    },
    icon: {
      width: theme.spacing(10),
      height: theme.spacing(10),
      background: theme.palette.background.default,
      borderStyle: 'solid',
      borderWidth: '4px',
      borderColor: 'var(--primary-outlined-resting-border)'
    },
    loading: {
      color: theme.palette.primary.contrastText
    },
    iconSmall: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      background: theme.palette.background.default,
      borderStyle: 'solid',
      borderWidth: '4px',
      borderColor: 'var(--primary-outlined-resting-border)'
    },
    personIcon: {
      color: 'var(--primary-outlined-resting-border)',
      fontSize: theme.spacing(5)
    },
    badge: {
      width: '22px',
      height: '22px',
      top: '2px',
      left: '10px'
    },
    navigate: {
      display: 'grid',
      justifyContent: 'center',
      alignItems: 'center'
    },
    textColor: {
      color: theme.palette.primary.contrastText
    },
    buttons: {
      alignItems: 'center',
      maxWidth: theme.breakpoints.values.sm,
      margin: '0 5%'
    },
    button: {
      height: '42px',
      color: theme.palette.primary.main,
      backgroundColor: theme.palette.primary.contrastText
    },
    bottom: {
      minHeight: '62px',
      justifyContent: 'center'
    }
  })
)
