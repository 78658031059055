import { useState, useEffect, useCallback, FC } from 'react'
import { useDispatch } from 'react-redux'
import { history, isEmpty } from '../../../../helpers'
import LoaderPage from '../../../pages/Loader'
import { authActions, userActions } from '../../../../ducks/actions'
import { TemplateProps } from './types'

const Template: FC<TemplateProps> = ({ children, error, setError }) => {
  const dispatch = useDispatch()
  const replace = history.useReplace()

  const [hash, setHash] = useState<string | null>(null)
  const [hashParsed, setHashParsed] = useState(false)

  const [loading, setLoading] = useState(true)

  const hashFlags = useCallback((start: boolean): void => {
    setLoading(start)
    setHashParsed(true)
    setError(!start)
  }, [setError])

  useEffect(() => {
    if (!isEmpty(hash) && !hashParsed) {
      hashFlags(true)
      dispatch(authActions.parseHashRequest({
        hash: hash as string,
        callback: (hashSuccess) => {
          if (hashSuccess) {
            dispatch(userActions.fetchCurrentUser((fetchSuccess) => {
              if (fetchSuccess) replace('/')
              else {
                dispatch(userActions.createUser((createSuccess) => {
                  if (createSuccess) replace('/')
                  else hashFlags(false)
                }))
              }
            }))
          } else hashFlags(false)
        }
      }))
    }
  }, [hash, hashParsed, hashFlags, replace, dispatch])

  useEffect(() => {
    if (hash === null) setHash(window.location.hash)
    else setLoading(false)
  }, [hash])

  return (
    <LoaderPage loading={loading || (!isEmpty(hash) && !error)}>
      {children}
    </LoaderPage>
  )
}

export default Template
