import { FC, useState } from 'react'
import { Dialog, Box, Grid, Typography, Button, materialLab } from '../../..'
import { DialogRankingPorps } from './types'
import useStyles from './styles'

const DialogRanking: FC<DialogRankingPorps> = ({
  open, setOpen, title, advice, comments, onAccept, okButtonText = 'Ok', center = false
}) => {
  const classes = useStyles()

  const [rating, setRating] = useState(0)
  const [showWarning, setShowWarning] = useState(false)

  const handleAccept = (): void => {
    if (rating > 0) {
      if (onAccept !== undefined) onAccept(rating)
      handleClose()
    } else {
      setShowWarning(true)
    }
  }

  const handleClose = (): void => {
    setOpen(false)
    setShowWarning(false)
  }

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box className={classes.root}>
        <Grid
          container
          alignContent='center'
          direction='column'
          spacing={2}
          className={center ? classes.center : ''}
        >
          {title && (
            <Grid item className={classes.item}>
              <Typography variant='h6'> {title} </Typography>
            </Grid>)}
          <Grid item xs={12}>
            <materialLab.Rating
              name='rating'
              value={rating}
              className={classes.rating}
              onChange={(_e, newValue) => {
                if (newValue) {
                  setRating(newValue)
                  setShowWarning(false)
                }
              }}
            />
          </Grid>
          <Grid item xs={12} className={classes.container}>
            {advice && (
              <Grid item className={classes.item}>
                <Typography variant='body1'> {advice} </Typography>
              </Grid>)}
            {comments && comments.map((comment, index) => {
              return (
                <Grid item key={index} className={classes.item}>
                  <Typography variant='caption'> {comment} </Typography>
                </Grid>
              )
            })}
          </Grid>
          <Grid container item xs={12} className={classes.container}>
            {showWarning && (
              <Typography className={classes.item} color='error'>
                Please select the star rating and press submit
              </Typography>
            )}
            <Grid item xs={12}>
              <Button
                variant='contained'
                color='primary'
                size='large'
                className={!rating ? classes.disabled : ''}
                component='span'
                onClick={handleAccept}
                fullWidth
              >
                {okButtonText}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  )
}

export default DialogRanking
