import React from 'react'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { pdf, Page, Text, View, Document, Image } from '@react-pdf/renderer'
import { logoPng } from '../../../../../assets'
import { OrderState, Order, OrderItem } from '../../../../../ducks/orders/types'
import {
  concatSeparator,
  formatTimestamp,
  ORDER_TYPE,
  round,
} from '../../../../../helpers'
import styles from './style'

const OrderView = ({ orderData, totals, showBidPrice }) => {
  const {
    line_1: street1,
    bidPrice,
    orderName,
    orderPublicId,
    type,
    address,
  } = orderData
  return (
    <View style={styles.block}>
      <View style={styles.line}>
        <Text style={styles.title}>
          {street1}, {address}
        </Text>
        {showBidPrice && (
          <Text style={[styles.textRight, styles.price]}>$ {round(bidPrice, 2)}</Text>
        )}
      </View>
      <View style={styles.line}>
        <Text>
          {type === ORDER_TYPE.ORDER && orderPublicId && (
            <>
              <Text style={styles.subtitleBold}>Order Code: </Text>
              <Text style={styles.subtitle}>{orderPublicId} </Text>
            </>
          )}
          <Text style={styles.subtitle}>
            {orderName ? `(${orderName})` : ''}
          </Text>
        </Text>
        <Text style={styles.textRight}>
          <Text style={styles.subtitleBold}>Items: </Text>
          <Text style={styles.subtitle}>{totals}</Text>
        </Text>
      </View>
    </View>
  )
}

const OrderStateView = ({ orderState, allFinished }) => {
  return (
    <View style={styles.statusView}>
      {(orderState === 'complete' || allFinished) && (
        <View style={[styles.status, styles.completeStatus]}>
          <Text style={styles.completeText}>COMPLETED</Text>
        </View>
      )}
      {orderState === 'checkin' && !allFinished && (
        <View style={[styles.status, styles.checkinStatus]}>
          <Text style={styles.checkinText}>IN PROGRESS</Text>
        </View>
      )}
    </View>
  )
}

const DatesView = ({ orderData, orderState, allFinished }) => {
  const {
    scheduledStartTimestamp,
    scheduledEndTimestamp,
    checkedInTimestamp,
    completedTimestamp,
  } = orderData

  return (
    <View style={styles.block}>
      <View style={styles.bgGrey}>
        <View style={styles.content}>
          <OrderStateView orderState={orderState} allFinished={allFinished} />
          <Text style={styles.line}>
            <Text style={styles.subtitleBold}>Job Start Date: </Text>
            <Text style={styles.subtitle}>
              {formatTimestamp(scheduledStartTimestamp, 'MM/DD/YYYY HH:mm')}
            </Text>
          </Text>
          <Text style={styles.line}>
            <Text style={styles.subtitleBold}>Job End Date: </Text>
            <Text style={styles.subtitle}>
              {formatTimestamp(scheduledEndTimestamp, 'MM/DD/YYYY HH:mm')}
            </Text>
          </Text>
          {(orderState === 'checkin' || orderState === 'complete') && (
            <Text style={styles.line}>
              <Text style={styles.subtitleBold}>Checkin Date: </Text>
              <Text style={styles.subtitle}>
                {formatTimestamp(checkedInTimestamp, 'MM/DD/YYYY HH:mm')}
              </Text>
            </Text>
          )}
          {orderState === 'complete' && (
            <Text style={styles.line}>
              <Text style={styles.subtitleBold}>Completed Date: </Text>
              <Text style={styles.subtitle}>
                {formatTimestamp(completedTimestamp, 'MM/DD/YYYY HH:mm')}
              </Text>
            </Text>
          )}
        </View>
      </View>
    </View>
  )
}

const ItemView = ({ type, item }) => {
  const {
    inspectionReportReference,
    title,
    quantity,
    disclaimer,
    inspectionReportNote,
    requestedRepairNote,
    punchlistTechNote,
    fieldNote,
    siteVisitNotes,
    estimatedPrice,
    imageFiles,
  } = item

  return (
    <View style={styles.block}>
      <Text style={styles.itemTitle}>
        {`${inspectionReportReference ? inspectionReportReference : ''} - ${title} Qty: ${quantity}`}
      </Text>
      {!isEmpty(disclaimer) && (
        <Text style={styles.line}>
          <Text style={styles.itemSubtitle}>Disclamer: </Text>
          <Text style={styles.itemText}>{disclaimer}</Text>
        </Text>
      )}
      {!isEmpty(inspectionReportNote) && (
        <Text style={styles.line}>
          <Text style={styles.itemSubtitle}>Inspection/Report Notes: </Text>
          <Text style={styles.itemText}>{inspectionReportNote}</Text>
        </Text>
      )}
      {!isEmpty(requestedRepairNote) && (
        <Text style={styles.line}>
          <Text style={styles.itemSubtitle}>Requested Repair Notes: </Text>
          <Text style={styles.itemText}>{requestedRepairNote}</Text>
        </Text>
      )}
      {!isEmpty(punchlistTechNote) && (
        <Text style={styles.line}>
          <Text style={styles.itemSubtitle}>Bosscat Technician Notes: </Text>
          <Text style={styles.itemText}>{punchlistTechNote}</Text>
        </Text>
      )}
      {!isEmpty(fieldNote) && (
        <Text style={styles.line}>
          <Text style={styles.itemSubtitle}>My Field Notes: </Text>
          <Text style={styles.itemText}>{fieldNote}</Text>
        </Text>
      )}
      {!isEmpty(siteVisitNotes) && (
        <Text style={styles.line}>
          <Text style={styles.itemSubtitle}>Site Visit Notes: </Text>
          <Text style={styles.itemText}>{siteVisitNotes}</Text>
        </Text>
      )}
      {estimatedPrice > 0 && (
        <Text style={styles.line}>
          <Text style={styles.itemSubtitleEP}>Estimated Price: </Text>
          <Text style={styles.itemTextEP}> USD {estimatedPrice}</Text>
        </Text>
      )}
      {imageFiles.length > 0 && (
        <>
          <Text style={styles.line}>
            <Text style={styles.itemSubtitle}>Item Images: </Text>
          </Text>
          <View style={styles.itemImages}>
            {imageFiles.map((image, index) => {
              return (
                <Image
                  key={index}
                  style={styles.image}
                  src={image.fileUrl}
                  alt={'itemImage_' + index}
                />
              )
            })}
          </View>
        </>
      )}
    </View>
  )
}

const FooterView = () => {
  return (
    <>
      <Text
        fixed
        style={styles.printDate}
        render={() => formatTimestamp(moment().unix(), 'MM/DD/YYYY HH:mm')}
      />
      <Text
        fixed
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      />
    </>
  )
}

const ItemsPdf = ({
  orderState,
  orderData,
  totals,
  itemsInProgress,
  itemsCompleted,
  itemsAccepted,
  allFinished,
  showBidPrice,
}) => (
  <Document>
    <Page size="A4" style={styles.body}>
      <View style={styles.logoView}>
        <Image style={styles.logo} src={logoPng} alt="Bosscat" />
      </View>
      <OrderView
        orderData={orderData}
        totals={totals}
        showBidPrice={showBidPrice}
      />
      <DatesView
        orderData={orderData}
        orderState={orderState}
        allFinished={allFinished}
      />
      {itemsInProgress.map((item, index) => (
        <ItemView key={index} type={orderData.type} item={item} />
      ))}
      {itemsCompleted.length > 0 && (
        <>
          <View style={styles.block}>
            <View style={styles.bgGrey}>
              <View style={[styles.content, styles.completed]}>
                <Text style={styles.itemTitle}> COMPLETED ITEMS </Text>
              </View>
            </View>
          </View>
          {itemsCompleted.map((item, index) => (
            <ItemView key={index} item={item} />
          ))}
        </>
      )}
      {itemsAccepted.length > 0 && (
        <>
          <View style={styles.block}>
            <View style={styles.bgGrey}>
              <View style={[styles.content, styles.accepted]}>
                <Text style={styles.itemTitle}> ACCEPTED ITEMS </Text>
              </View>
            </View>
          </View>
          {itemsAccepted.map((item, index) => (
            <ItemView key={index} item={item} />
          ))}
        </>
      )}
      <FooterView />
    </Page>
  </Document>
)

const download = (blob, name) => {
  const url = window.URL.createObjectURL(new Blob([blob]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', name + '.pdf')
  if (document.body) document.body.appendChild(link)
  link.click()
}

export const generatePdf = async ({
  orderState,
  order,
  totalCount,
  finishedCount,
  itemsInProgress,
  itemsCompleted,
  itemsAccepted,
  allFinished,
  showBidPrice,
}: {
  orderState: OrderState,
  order: Order,
  totalCount: Number,
  finishedCount: Number,
  itemsInProgress: OrderItem[],
  itemsCompleted: OrderItem[],
  itemsAccepted: OrderItem[],
  allFinished: boolean,
  showBidPrice: boolean,
}) => {
  const {
    bidPrice,
    orderName,
    orderPublicId,
    type,
    scheduledStartTimestamp,
    scheduledEndTimestamp,
    checkedInTimestamp,
    completedTimestamp,
    address: { city, zipCode, line_1: street1, line_2: street2 },
  } = order

  let address = city
  address = concatSeparator(address, zipCode, ', ')
  address = concatSeparator(address, street2, ', ')
  download(
    await pdf(
      <ItemsPdf
        orderState={orderState}
        orderData={{
          line_1: street1,
          address,
          orderPublicId,
          bidPrice,
          orderName,
          scheduledStartTimestamp,
          scheduledEndTimestamp,
          checkedInTimestamp,
          completedTimestamp,
          type,
        }}
        totals={`${finishedCount}/${totalCount}`}
        itemsInProgress={itemsInProgress}
        itemsCompleted={itemsCompleted}
        itemsAccepted={itemsAccepted}
        allFinished={allFinished}
        showBidPrice={showBidPrice}
      />
    ).toBlob(),
    `Order_${new Date().getTime()}`
  )
}
