import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: theme.spacing(1),
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: theme.spacing(2),
      height: 'fit-content',
      alignItems: 'center',
    },
    icon: {
      paddingLeft: theme.spacing(1) / 3,
      paddingTop: theme.spacing(1) / 4,
    },
    text: {
      paddingLeft: theme.spacing(1),
      color: theme.palette.primary.dark,
    },
  })
)
