export const imageMimeTypes = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp']
export const videoMimeTypes = ['.webm', '.ogg', '.mp4', '.mov', '.quicktime']
export const applicationMimeTypes = ['.pdf']

export const getFileExtension = (file: File): string => {
  try {
    return file.type.split('/')[1]
  } catch (_error) {
    return ''
  }
}

export const dataURItoFile = (dataUri: string, name: string): File => {
  // convert base64 to raw binary data held in a string
  // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
  const byteString = atob(dataUri.split(',')[1])

  // separate out the mime component
  const mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0]

  // write the bytes of the string to an ArrayBuffer
  const ab = new ArrayBuffer(byteString.length)
  const ia = new Uint8Array(ab)
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i)
  }

  return new File([new Blob([ab], { type: mimeString })], name, { type: mimeString })
}

export const downloadURI = (uri: string, name: string): void => {
  const link = document.createElement('a')
  link.download = name
  link.href = uri
  link.target = '_blank'
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
