/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, select, takeLatest } from 'redux-saga/effects'
import { toast } from 'react-toastify'

import { Punchlist } from '../../api'
import { UpdateOrderItemRequest } from '../../api/punchlist/orders/types'
import {
  errorTextTryingTo,
  ORDER_STATUS_REDUCED,
  isEmpty,
  FEEDBACK_TYPE,
  ORDER_TYPE,
  ORDER_REPLY,
} from '../../helpers'
import { configActions, ordersActions } from '../actions'
import { getOrderItemImages, getOrderType } from '../selectors'
import {
  Feedback,
  ImageFile,
  SendOrderFeedbackAction,
  ordersTypes,
  Order,
  OrderItem,
  FetchOrdersAction,
  FetchOrderItemsAction,
  FetchOrderFilesAction,
  FetchOrderNotesAction,
  FetchOrderByIdAction,
  UpdateOrderStateAction,
  UpdateOrderItemAction,
  UpdateOrderItemImagesAction,
  ReplyToOrderAction,
  AssignOrderAction,
} from './types'
import { getUserId } from '../user/selectors'
import { getOrderItem } from './selectors'

export function* fetchOrders({
  payload,
  callback,
}: FetchOrdersAction): SagaIterator {
  let success = false
  let count = 0
  try {
    if (payload.reset) {
      yield put(ordersActions.setOrders([]))
    }

    const orders = yield call(
      Punchlist.orders.getOrders,
      payload.status,
      payload.assigneeId,
      payload.assignerId
    )

    if (orders && orders.length > 0) {
      orders.forEach((order: Order) => {
        order.statusTypeReduced = payload.status
          ? (payload.status as ORDER_STATUS_REDUCED)
          : ORDER_STATUS_REDUCED.ACTIVE
      })
    }

    yield put(ordersActions.addOrders(orders))
    success = true
    count = orders.length

    if (callback) yield call(callback, success, count)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the jobs'))
    if (callback) yield call(callback, false, 0)
  }
}

export function* fetchOrderById({
  payload,
  callback,
}: FetchOrderByIdAction): SagaIterator {
  let success = false
  const type = yield select(getOrderType(payload))

  try {
    if (!payload) {
      if (callback) yield call(callback, false)
    }

    const order = yield call(
      type === ORDER_TYPE.SITE_VISIT
        ? Punchlist.siteVisits.getSiteVisitById
        : Punchlist.orders.getOrderById,
      payload
    )
    yield put(
      ordersActions.setOrderValues({
        orderId: payload,
        values: order,
      })
    )
    success = true

    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the job'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchOrderItems({
  payload,
  callback,
}: FetchOrderItemsAction): SagaIterator {
  let success = false
  try {
    const type = yield select(getOrderType(payload))
    const data = yield call(
      type === ORDER_TYPE.SITE_VISIT
        ? Punchlist.siteVisits.getSiteVisitItems
        : Punchlist.orders.getOrderItems,
      payload
    )

    if (data && data.length > 0) {
      yield put(
        ordersActions.setOrderValue({
          orderId: payload,
          attr: 'items',
          value: data,
        })
      )
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the items'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchOrderFiles({
  payload,
  callback,
}: FetchOrderFilesAction): SagaIterator {
  let success = false
  try {
    const type = yield select(getOrderType(payload))
    const data = yield call(
      type === ORDER_TYPE.SITE_VISIT
        ? Punchlist.siteVisits.getSiteVisitFiles
        : Punchlist.orders.getOrderFiles,
      payload
    )

    if (data && data.length > 0) {
      yield put(
        ordersActions.setOrderValue({
          orderId: payload,
          attr: 'files',
          value: data,
        })
      )
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the files'))
    if (callback) yield call(callback, false)
  }
}

export function* fetchOrderNotes({
  payload,
  callback,
}: FetchOrderNotesAction): SagaIterator {
  let success = false
  try {
    const type = yield select(getOrderType(payload))
    const data = yield call(
      type === ORDER_TYPE.SITE_VISIT
        ? Punchlist.siteVisits.getSiteVisitNotes
        : Punchlist.orders.getOrderNotes,
      payload
    )

    if (data && data.length > 0) {
      yield put(
        ordersActions.setOrderValue({
          orderId: payload,
          attr: 'notes',
          value: data,
        })
      )
    }
    success = true
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('get the notes'))
    if (callback) yield call(callback, false)
  }
}

export function* updateOrderState({
  payload,
  callback,
}: UpdateOrderStateAction): SagaIterator {
  let success = false
  try {
    let data = null
    const { orderId, orderState } = payload
    const type = yield select(getOrderType(orderId))

    if (orderState === 'checkin') {
      data = yield call(
        type === ORDER_TYPE.SITE_VISIT
          ? Punchlist.siteVisits.setSiteVisitCheckIn
          : Punchlist.orders.setOrderCheckIn,
        orderId
      )
    }
    if (orderState === 'complete') {
      data = yield call(
        type === ORDER_TYPE.SITE_VISIT
          ? Punchlist.siteVisits.setSiteVisitComplete
          : Punchlist.orders.setOrderComplete,
        orderId
      )
    }

    if (orderState === 'submission') {
      data = yield call(Punchlist.orders.setOrderSubmission, orderId)
    }

    // yield put(ordersActions.setOrderValue({ orderId, attr: 'statusType', value: data.statusType }))
    if (data && data.checkedInTimestamp) {
      yield put(
        ordersActions.setOrderValue({
          orderId,
          attr: 'checkedInTimestamp',
          value: data.checkedInTimestamp,
        })
      )
      success = true
    }
    if (data && data.completedTimestamp) {
      yield put(
        ordersActions.setOrderValue({
          orderId,
          attr: 'completedTimestamp',
          value: data.completedTimestamp,
        })
      )
      yield put(
        ordersActions.setOrderValue({
          orderId,
          attr: 'statusTypeReduced',
          value: ORDER_STATUS_REDUCED.COMPLETED,
        })
      )
      success = true
    }

    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the job'))
    if (callback) yield call(callback, false)
  }
}

export function* updateOrderItem({
  payload,
  callback,
}: UpdateOrderItemAction): SagaIterator {
  let success = false

  try {
    const { orderId, itemId, partialItem } = payload
    const type = yield select(getOrderType(orderId))
    const request: UpdateOrderItemRequest = []

    let updateId = orderId
    if (type === ORDER_TYPE.SITE_VISIT) {
      const item = yield select(getOrderItem(orderId, itemId))
      updateId = item.siteVisitId || ''
    }

    for (const attr in partialItem) {
      request.push({
        op: 'add',
        path: '/' + attr,
        value: partialItem[attr as keyof OrderItem],
      })
    }

    const data = yield call(
      type === ORDER_TYPE.SITE_VISIT
        ? Punchlist.siteVisits.updateSiteVisitItem
        : Punchlist.orders.updateOrderItem,
      updateId,
      itemId,
      request
    )

    if (!isEmpty(data)) {
      yield put(
        ordersActions.setOrderItemValue({ orderId, itemId, attrs: data })
      )
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the item'))
    if (callback) yield call(callback, false)
  }
}

export function* updateOrderItemImages({
  payload,
  callback,
}: UpdateOrderItemImagesAction): SagaIterator {
  let success = false

  try {
    const { orderId, itemId, list, opp } = payload
    const type = yield select(getOrderType(orderId))
    const request: UpdateOrderItemRequest = []

    let updateId = orderId
    if (type === ORDER_TYPE.SITE_VISIT) {
      const item = yield select(getOrderItem(orderId, itemId))
      updateId = item.siteVisitId || ''
    }

    if (opp === 'add') {
      for (const index in list) {
        request.push({
          op: 'add',
          path: '/imageFiles/-',
          value: list[parseInt(index)],
        })
      }
    }

    if (opp === 'remove') {
      for (const index in list) {
        const orderItemImages: ImageFile[] = yield select(
          getOrderItemImages(orderId, itemId)
        )
        const imageIndex = orderItemImages.findIndex(
          (image) => image.id === list[parseInt(index)]
        )
        if (imageIndex > -1)
          request.push({ op: 'remove', path: '/imageFiles/' + imageIndex })
      }
    }

    const data = yield call(
      type === ORDER_TYPE.SITE_VISIT
        ? Punchlist.siteVisits.updateSiteVisitItem
        : Punchlist.orders.updateOrderItem,
      updateId,
      itemId,
      request
    )

    if (!isEmpty(data)) {
      yield put(
        ordersActions.setOrderItemValue({ orderId, itemId, attrs: data })
      )
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (error) {
    yield call(toast.error, errorTextTryingTo('update the item'))
    if (callback) yield call(callback, false)
  }
}

export function* sendOrderFeedback({
  payload,
}: SendOrderFeedbackAction): SagaIterator {
  try {
    const { orderId, score } = payload
    const userId = yield select(getUserId)

    const request: Feedback = {
      userId,
      resourceId: orderId,
      resourceType: FEEDBACK_TYPE.ORDER,
      ratings: [{ score }],
    }

    yield call(Punchlist.feedback.sendFeedback, request)
  } catch (_error) {}
}

export function* replyOrder({
  payload,
  callback,
}: ReplyToOrderAction): SagaIterator {
  const { orderId, reply } = payload
  const statusText =
    reply.status === ORDER_REPLY.REJECTED ? 'decline' : 'accept'

  try {
    const type = yield select(getOrderType(orderId))

    yield put(
      configActions.setConfigValue({ type: 'replyingBid', value: true })
    )

    yield call(
      type === ORDER_TYPE.SITE_VISIT
        ? Punchlist.siteVisits.replySiteVisit
        : Punchlist.orders.replyOrder,
      orderId,
      reply
    )

    if (reply.status === ORDER_REPLY.REJECTED) {
      yield put(ordersActions.removeOrder(orderId))
      toast.info('Feedback submitted successfully')
    } else toast.success('Job Accepted successfully!')

    if (callback) yield call(callback, true)
  } catch (_error) {
    yield call(toast.error, errorTextTryingTo(`${statusText} the job`))
    if (callback) yield call(callback, false)
  }
}

export function* assignOrder({
  payload,
  callback,
}: AssignOrderAction): SagaIterator {
  let success = false
  const { orderId, vendorId } = payload

  try {
    const type = yield select(getOrderType(orderId))

    const data = yield call(
      type === ORDER_TYPE.SITE_VISIT
        ? Punchlist.siteVisits.assignSiteVisit
        : Punchlist.orders.assignOrder,
      orderId,
      vendorId
    )

    if (data && data.assigneeId) {
      yield put(
        ordersActions.setOrderValues({
          orderId,
          values: data,
        })
      )
      success = true
    }
    if (callback) yield call(callback, success)
  } catch (_error) {
    yield call(toast.error, errorTextTryingTo('assign the job'))
    if (callback) yield call(callback, false)
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(ordersTypes.FETCH_ORDERS, fetchOrders)
  yield takeLatest(ordersTypes.FETCH_ORDER, fetchOrderById)
  yield takeLatest(ordersTypes.FETCH_ORDER_ITEMS, fetchOrderItems)
  yield takeLatest(ordersTypes.FETCH_ORDER_FILES, fetchOrderFiles)
  yield takeLatest(ordersTypes.FETCH_ORDER_NOTES, fetchOrderNotes)
  yield takeLatest(ordersTypes.UPDATE_ORDER_STATE, updateOrderState)
  yield takeLatest(ordersTypes.UPDATE_ORDER_ITEM, updateOrderItem)
  yield takeLatest(ordersTypes.UPDATE_ORDER_ITEM_IMAGES, updateOrderItemImages)
  yield takeLatest(ordersTypes.SEND_ORDER_FEEDBACK, sendOrderFeedback)
  yield takeLatest(ordersTypes.REPLY_TO_ORDER, replyOrder)
  yield takeLatest(ordersTypes.ASSIGN_ORDER, assignOrder)
}
