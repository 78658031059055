import { createStyles, makeStyles, Theme } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '445px',
      borderRadius: '16px'
    },
    input: {
      paddingRight: '8px'
    },
    formControl: {
      '& .MuiButtonBase-root': {
        height: 'auto'
      }
    },
    list: {
      display: 'grid',
      paddingLeft: '24px',
      gap: '8px'
    },
    thanks: {
      color: theme.palette.primary.main,
      textAlign: 'center',
      background: '#FAFAFA',
      padding: '8px 24px',
      margin: '0 -24px'
    },
    center: {
      textAlign: 'center'
    }
  })
)
