/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */

import { SagaIterator } from 'redux-saga'
import { all, AllEffect } from 'redux-saga/effects'

import authSaga from './auth/saga'
import filesSaga from './files/saga'
import invitationsSaga from './invitations/saga'
import ordersSaga from './orders/saga'
import territoriesSaga from './territories/saga'
import tradesSaga from './tradeTypes/saga'
import tosCheckSaga from './tosCheck/saga'
import userSaga from './user/saga'
import vendorsSaga from './vendors/saga'

const rootSaga = function* rootSaga(): Generator<
  AllEffect<SagaIterator<any>>,
  void,
  unknown
> {
  yield all([
    authSaga(),
    filesSaga(),
    invitationsSaga(),
    ordersSaga(),
    territoriesSaga(),
    tradesSaga(),
    tosCheckSaga(),
    userSaga(),
    vendorsSaga(),
  ])
}

export default rootSaga
