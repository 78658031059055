import { FC } from 'react'
import { Box, NavBar } from '../../../UI'
import { icons } from '../../../../assets'
import useStyles from './styles'
import { TemplateProps } from './types'

const Template: FC<TemplateProps> = ({ children }) => {
  const classes = useStyles()

  const main = [
    {
      text: 'Home',
      icon: icons.Home,
      path: 'home'
    },
    {
      text: 'Bids',
      icon: icons.Alarm,
      path: 'bids'
    },
    {
      text: 'Jobs',
      icon: icons.Build,
      path: 'orders'
    },
    {
      text: 'Profile',
      icon: icons.Person,
      path: 'profile',
      subPaths: [
        'settings',
        'contact_info',
        'upload_docs',
        'locations'
      ]
    }
  ]

  return (
    <Box className={classes.root}>
      {children}
      <NavBar options={main} replaceAll />
    </Box>
  )
}

export default Template
