import { FC } from 'react'
import { Typography, Box } from '../../..'
import useStyles from './styles'
import { StateMessageProps } from './types'

const StateMessage: FC<StateMessageProps> = ({ icon, title, description, bottom }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      {icon && (
        typeof icon === 'string'
          ? <img alt='icon' src={icon} className={classes.icon} />
          : icon
      )}
      <Box className={classes.text}>
        {title && (
          <Typography>
            {title}
          </Typography>
        )}
        {description && (
          <Typography>
            {description}
          </Typography>
        )}
        {bottom && (
          <Box className={classes.bottom}>
            {bottom}
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default StateMessage
