import { toLower } from 'lodash'
import { ORDER_STATUS_REDUCED } from '../../../helpers'
import axios from '../axios'
import { ApiController } from './types'

const base = '/orders'

const apiController: ApiController = {
  getOrders: async (status?: ORDER_STATUS_REDUCED, assigneeId?: string, assignerId?: string) =>
    await axios
      .get(base, { params: { status: toLower(status), assigneeId, assignerId } })
      .then((response) => response.data),

  getOrderById: async (orderId: string) =>
    await axios.get(`${base}/${orderId}`).then((response) => response.data),

  getOrderItems: async (orderId: string) =>
    await axios
      .get(`${base}/${orderId}/items`)
      .then((response) => response.data),

  getOrderItemById: async (orderId: string, itemId: string) =>
    await axios
      .get(`${base}/${orderId}/items/${itemId}`)
      .then((response) => response.data),

  getOrderFiles: async (orderId: string) =>
    await axios
      .get(`${base}/${orderId}/files`)
      .then((response) => response.data),

  getOrderNotes: async (orderId: string) =>
    await axios
      .get(`${base}/${orderId}/notes`)
      .then((response) => response.data),

  setOrderCheckIn: async (orderId: string) =>
    await axios
      .post(`${base}/${orderId}/checkin`)
      .then((response) => response.data),

  setOrderComplete: async (orderId: string) =>
    await axios
      .post(`${base}/${orderId}/complete`)
      .then((response) => response.data),

  setOrderSubmission: async (orderId: string) =>
    await axios
      .post(`${base}/${orderId}/submission`)
      .then((response) => response.data),

  updateOrderItem: async (orderId: string, itemId: string, request) =>
    await axios
      .patch(`${base}/${orderId}/items/${itemId}`, request)
      .then((response) => response.data),

  replyOrder: async (id: string, request) =>
    await axios
      .post(`${base}/${id}/reply`, request)
      .then((response) => response.data),

  assignOrder: async (id: string, vendorId: string) =>
    await axios
      .post(`${base}/${id}/assign/${vendorId}`)
      .then((response) => response.data),
}

export default apiController
