import { FC } from 'react'
import { Box, Typography } from '../../..'
import useStyles from './styles'
import { BigButtonProps } from './types'

const BigButton: FC<BigButtonProps> = ({
  title,
  text,
  icon,
  backgroundColor,
  backgroundIcon,
  onClick,
}) => {
  const classes = useStyles()

  return (
    <Box
      className={classes.root}
      style={{ backgroundColor: backgroundColor }}
      onClick={onClick}
    >
      <Box className={classes.backgroundIcon}>
        <img src={backgroundIcon} alt="" />
      </Box>
      <Box className={classes.content}>
        <Box className={classes.titleContent}>
          <img src={icon} alt="" className={classes.icon} />
          <Typography variant="body1" className={classes.title}>
            {title}
          </Typography>
        </Box>
        <Typography variant="caption" className={classes.text}>
          {text}
        </Typography>
      </Box>
    </Box>
  )
}

export default BigButton
