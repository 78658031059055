/** TYPES **/
export const tradeTypes = {
  FETCH_TRADE_TYPES: 'FETCH_TRADE_TYPES',
  SET_TRADE_TYPES: 'SET_TRADE_TYPES'
}

/** ACTIONS **/
export interface FetchTradeTypesAction {
  type: typeof tradeTypes.FETCH_TRADE_TYPES
  payload: null
  callback?: (succ: boolean) => void
}

export interface SetTradeTypesAction {
  type: typeof tradeTypes.SET_TRADE_TYPES
  payload: TradeType[]
}

export type TradeTypesActions =
  | FetchTradeTypesAction
  | SetTradeTypesAction

/** REDUCER **/
export interface TradeType {
  id: string
  name: string
  displayName: string
}

export interface TradeTypes extends Array<TradeType> { }
