import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    card: {
      borderRadius: theme.spacing(2),
      position: 'relative'
    },
    full: {
      position: 'absolute',
      top: '8px',
      right: '8px',
      color: 'rgba(255, 255, 255, 0.8)',
      background: 'rgba(157, 157, 157, 0.5)',
      borderRadius: '8px',
      width: '24px',
      height: '24px',
      '&:hover': {
        background: 'rgba(157, 157, 157, 0.8)'
      },
      zIndex: 1
    },
    imageBox: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden',
      height: '135px',
      [theme.breakpoints.up('md')]: {
        height: '145px'
      },
      [theme.breakpoints.up('lg')]: {
        height: '165px'
      }
    },
    video: {
      width: '100%',
      height: '100%',
      maxHeight: '70vh',
      background: '#000'
    },
    preview: {
      width: '100%',
      height: '100%',
      objectFit: 'cover'
    },
    center: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    previewDefault: {
      width: '62px'
    },
    imageDialog: {
      maxHeight: '80vh'
    },
    actions: {
      display: 'grid',
      gap: theme.spacing(1),
      padding: theme.spacing(1)
    },
    documentCanva: {
      maxWidth: '80vw',
      width: 'auto',
      height: 'auto',
      overflow: 'hidden'
    }
  })
)
