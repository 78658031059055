import { createStyles, makeStyles, Theme } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      maxWidth: '445px',
      borderRadius: '16px'
    },
    input: {
      paddingRight: '8px'
    },
    formControl: {
      '& .MuiButtonBase-root': {
        height: 'auto'
      }
    }
  })
)
