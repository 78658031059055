import { FC } from 'react'
import { Box } from '../../../UI'
import OrderProfile from './OrderProfile'
import useStyles from './styles'
import { TemplateProps } from './types'

const Template: FC<TemplateProps> = ({ children }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <OrderProfile />
      {children}
    </Box>
  )
}

export default Template
