import axios from '../axios'
import { ApiController } from './types'

const base = '/site-visits'

const apiController: ApiController = {
  getSiteVisitById: async (siteVisitId: string) =>
    await axios.get(`${base}/${siteVisitId}`).then((response) => response.data),

  getSiteVisitItems: async (siteVisitId: string) =>
    await axios
      .get(`${base}/${siteVisitId}/items`)
      .then((response) => response.data),

  getSiteVisitItemById: async (siteVisitId: string, itemId: string) =>
    await axios
      .get(`${base}/${siteVisitId}/items/${itemId}`)
      .then((response) => response.data),

  getSiteVisitFiles: async (siteVisitId: string) =>
    await axios
      .get(`${base}/${siteVisitId}/files`)
      .then((response) => response.data),

  getSiteVisitNotes: async (siteVisitId: string) =>
    await axios
      .get(`${base}/${siteVisitId}/notes`)
      .then((response) => response.data),

  setSiteVisitCheckIn: async (siteVisitId: string) =>
    await axios
      .post(`${base}/${siteVisitId}/checkin`)
      .then((response) => response.data),

  setSiteVisitComplete: async (siteVisitId: string) =>
    await axios
      .post(`${base}/${siteVisitId}/complete`)
      .then((response) => response.data),

  updateSiteVisitItem: async (siteVisitId: string, itemId: string, request) =>
    await axios
      .patch(`${base}/${siteVisitId}/items/${itemId}`, request)
      .then((response) => response.data),

  replySiteVisit: async (id: string, request) =>
    await axios
      .post(`${base}/${id}/reply`, request)
      .then((response) => response.data),

  assignSiteVisit: async (id: string, vendorId: string) =>
    await axios
      .post(`${base}/${id}/assign/${vendorId}`)
      .then((response) => response.data),
}

export default apiController
