import { authTypes } from '../auth/types'
import {
  ordersTypes,
  OrdersType,
  OrdersActions,
  Order,
  OrderValue,
  OrderItemValues,
  OrderValues,
} from './types'

const initialState: OrdersType = []

const reducer = (state = initialState, action: OrdersActions): OrdersType => {
  const { type, payload } = action

  switch (type) {
    case authTypes.LOGIN_REQUEST: {
      return initialState
    }
    case authTypes.LOGOUT_REQUEST: {
      return initialState
    }

    case ordersTypes.SET_ORDERS: {
      return payload as Order[]
    }

    case ordersTypes.ADD_ORDERS: {
      return [
        ...state,
        ...(payload as Order[]).filter(
          (order: Order) =>
            state.find((actual) => actual.orderId === order.orderId) ===
            undefined
        ),
      ]
    }

    case ordersTypes.REMOVE_ORDER: {
      return [...state.filter((order) => order.orderId !== payload)]
    }

    case ordersTypes.SET_ORDER_VALUE: {
      const { orderId, attr, value } = payload as OrderValue

      const index = state.findIndex((order) => {
        return order.orderId === orderId
      })

      if (index !== -1) {
        const ordersCopy = [...state]
        ordersCopy[index] = {
          ...state[index],
          [attr]: value,
        }
        return ordersCopy
      }
      return state
    }

    case ordersTypes.SET_ORDER_VALUES: {
      const { orderId, values } = payload as OrderValues

      const index = state.findIndex((order) => {
        return order.orderId === orderId
      })

      if (index !== -1) {
        const ordersCopy = [...state]
        ordersCopy[index] = {
          ...state[index],
          ...values,
        }
        return ordersCopy
      }
      return state
    }

    case ordersTypes.SET_ORDER_ITEM_VALUES: {
      const { orderId, itemId, attrs } = payload as OrderItemValues

      const orderIndex = state.findIndex((order) => {
        return order.orderId === orderId
      })

      if (orderIndex !== -1) {
        const itemIndex = state[orderIndex].items.findIndex((item) => {
          return item.itemId === itemId
        })

        if (itemIndex !== -1) {
          const itemCopy = state[orderIndex].items[itemIndex]
          const itemsCopy = [...state[orderIndex].items]
          const ordersCopy = [...state]
          itemsCopy[itemIndex] = { ...itemCopy, ...attrs }
          ordersCopy[orderIndex].items = itemsCopy
          return ordersCopy
        }
      }
      return state
    }

    default:
      return state
  }
}

export default reducer
