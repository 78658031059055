import { FC } from 'react'
import { Typography, Grid } from '../../..'
import { icons } from '../../../../../assets'
import { formatTimestamp } from '../../../../../helpers'
import useStyles from './styles'
import { IncentiveDetailsProps } from './types'

const IncentiveDetails: FC<IncentiveDetailsProps> = ({ children }) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      <Grid item xs={12}>
        <Typography variant='subtitle1' className={classes.title}>
          Incentive Detail:
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='caption' className={classes.text}>
          <icons.Alarm className={classes.icon} />
          <span className={classes.span}>
            <b>Incentive Deadline: </b> {` ${formatTimestamp(children.deadline, 'MM/DD/YYYY')}`}
          </span>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='caption' className={classes.text}>
          <icons.AttachMoney className={classes.icon} />
          <span className={classes.span}>
            <b>Incentive Amount: </b> {` $${Math.floor(children.amount)}`}
          </span>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='caption' className={classes.text}>
          <icons.Create className={classes.icon} />
          <span className={classes.span}>
            <b>Incentive Notes: </b> {children.notes}
          </span>
        </Typography>
      </Grid>
    </Grid>
  )
}

export default IncentiveDetails
