import {
  ordersTypes,
  Order,
  OrderValue,
  UpdateStatePayload,
  UpdateOrderItemPayload,
  UpdateOrderItemImagesPayload,
  OrdersActions,
  OrderItemValues,
  FeedbackPayload,
  ReplyOrderPayload,
  FetchOrdersPayload,
  AssignOrderPayload,
  OrderValues,
} from './types'

const actions = {
  fetchOrders: (
    payload: FetchOrdersPayload,
    callback?: (succ: boolean, count: number) => void
  ): OrdersActions => ({
    type: ordersTypes.FETCH_ORDERS,
    payload,
    callback,
  }),
  fetchOrderById: (
    payload: string,
    callback?: (succ: boolean) => void
  ): OrdersActions => ({
    type: ordersTypes.FETCH_ORDER,
    payload,
    callback,
  }),
  fetchOrderItems: (
    payload: string,
    callback?: (succ: boolean) => void
  ): OrdersActions => ({
    type: ordersTypes.FETCH_ORDER_ITEMS,
    payload,
    callback,
  }),
  fetchOrderFiles: (
    payload: string,
    callback?: (succ: boolean) => void
  ): OrdersActions => ({
    type: ordersTypes.FETCH_ORDER_FILES,
    payload,
    callback,
  }),
  fetchOrderNotes: (
    payload: string,
    callback?: (succ: boolean) => void
  ): OrdersActions => ({
    type: ordersTypes.FETCH_ORDER_NOTES,
    payload,
    callback,
  }),
  setOrders: (payload: Order[]): OrdersActions => ({
    type: ordersTypes.SET_ORDERS,
    payload,
  }),
  addOrders: (payload: Order[]): OrdersActions => ({
    type: ordersTypes.ADD_ORDERS,
    payload,
  }),
  setActiveOrders: (payload: Order[]): OrdersActions => ({
    type: ordersTypes.SET_ACTIVE_ORDERS,
    payload,
  }),
  setCompletedOrders: (payload: Order[]): OrdersActions => ({
    type: ordersTypes.SET_COMPLETED_ORDERS,
    payload,
  }),
  removeOrder: (payload: string): OrdersActions => ({
    type: ordersTypes.REMOVE_ORDER,
    payload,
  }),
  setOrderValue: (payload: OrderValue): OrdersActions => ({
    type: ordersTypes.SET_ORDER_VALUE,
    payload,
  }),
  setOrderValues: (payload: OrderValues): OrdersActions => ({
    type: ordersTypes.SET_ORDER_VALUES,
    payload,
  }),
  updateOrderState: (
    payload: UpdateStatePayload,
    callback?: (succ: boolean) => void
  ): OrdersActions => ({
    type: ordersTypes.UPDATE_ORDER_STATE,
    payload,
    callback,
  }),
  updateOrderItem: (
    payload: UpdateOrderItemPayload,
    callback?: (succ: boolean) => void
  ): OrdersActions => ({
    type: ordersTypes.UPDATE_ORDER_ITEM,
    payload,
    callback,
  }),
  updateOrderItemImages: (
    payload: UpdateOrderItemImagesPayload,
    callback?: (succ: boolean) => void
  ): OrdersActions => ({
    type: ordersTypes.UPDATE_ORDER_ITEM_IMAGES,
    payload,
    callback,
  }),
  setOrderItemValue: (payload: OrderItemValues): OrdersActions => ({
    type: ordersTypes.SET_ORDER_ITEM_VALUES,
    payload,
  }),
  sendOrderFeedback: (payload: FeedbackPayload): OrdersActions => ({
    type: ordersTypes.SEND_ORDER_FEEDBACK,
    payload,
  }),
  replyOrder: (
    payload: ReplyOrderPayload,
    callback?: (succ: boolean) => void
  ): OrdersActions => ({
    type: ordersTypes.REPLY_TO_ORDER,
    payload,
    callback,
  }),
  assignOrder: (
    payload: AssignOrderPayload,
    callback?: (succ: boolean) => void
  ): OrdersActions => ({
    type: ordersTypes.ASSIGN_ORDER,
    payload,
    callback,
  }),
}

export default actions
