import Axios from 'axios'
import { Auth } from '..'
import { Token } from '../types'
import env from '@beam-australia/react-env'

const BASE_API_URL = env('PUNCHLIST_BASE_API_URL') ?? ''
const AUTH_PREFIX = env('AUTH_PREFIX') ?? 'pro_app'
const JWT_TOKEN = AUTH_PREFIX.concat(':jwtToken')

const axios = Axios.create({
  baseURL: BASE_API_URL
})

const parseJwt = (token: string): string => {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(atob(base64).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
  }).join(''))

  return JSON.parse(jsonPayload)
}

const tokenExpired = (token: string): boolean => {
  const tokenInfo = parseJwt(token) as unknown as Token
  return new Date().valueOf() / 1000 > tokenInfo.exp
}

axios.interceptors.request.use(async (req) => {
  const token = Auth.getToken(JWT_TOKEN)
  const userInfo = Auth.getUserInfo()
  const userId = Auth.get('punchlist-user-id')

  // These headers are for local dev. They are overridden in the API Gateway when verifying the JWT.
  if (userInfo && userInfo.sub) {
    req.headers['punchlist-auth0-id'] = `${userInfo.sub}`
  }

  if (userId) {
    req.headers['punchlist-user-id'] = `${userId}`
    req.headers['punchlist-user-type'] = 'Vendor'
  }

  if (token !== null && token !== undefined && !tokenExpired(token)) {
    req.headers.Authorization = `Bearer ${token}`
  } else {
    req.headers.Authorization = ''
  }
  return req
})

export default axios
export { tokenExpired }
