import { Theme, createStyles, makeStyles } from '../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      height: 'auto',
      position: 'relative',
      gridTemplateRows: 'min-content auto',
    },
    content: {
      width: '100%',
      margin: '0 auto',
    },
    headerTitle: {
      display: 'grid',
      alignItems: 'center',
      width: '100%',
      margin: '0 auto',
      padding: theme.spacing(2),
      maxWidth: theme.breakpoints.values.lg,
      gridTemplateColumns: '45px auto 45px',
    },
    headerBack: {
      display: 'grid',
      alignItems: 'center',
      width: '100%',
      margin: '0 auto',
      padding: theme.spacing(2),
      paddingLeft: 0,
      maxWidth: theme.breakpoints.values.lg,
      gridTemplateColumns: '45px auto 45px',
    },
    header: {
      display: 'grid',
      gridAutoFlow: 'column',
      padding: '20px 0 16px',
      alignItems: 'center',
    },
    backButton: {
      padding: 0,
      left: '-12px',
      height: '42px',
      backgroundColor: 'transparent',
      '&:hover, &.Mui-focusVisible': {
        background: 'none',
      },
    },
    title: {
      textAlign: 'center',
      width: '100%',
    },
    logo: {
      margin: 'auto',
      width: '60%',
    },
    logoCentered: {
      margin: 'auto',
      width: '75%',
      maxWidth: '380px',
      paddingRight: '8px',
    },
    avatar: {
      margin: '0 0 0 auto',
      borderRadius: '20px',
      width: theme.spacing(8),
      height: theme.spacing(8),
      [theme.breakpoints.down('sm')]: {
        width: theme.spacing(7),
        height: theme.spacing(7),
      },
      [theme.breakpoints.down('xs')]: {
        width: theme.spacing(6),
        height: theme.spacing(6),
      },
    },
    skip: {
      position: 'absolute',
      right: '54px',
      cursor: 'pointer',
    },
  })
)
