import { StyleSheet, Font } from '@react-pdf/renderer'
import Lato from '../../../../../assets/fonts/Lato-Regular.ttf'
import LatoLight from '../../../../../assets/fonts/Lato-Light.ttf'
import LatoBold from '../../../../../assets/fonts/Lato-Bold.ttf'
import Next from '../../../../../assets/fonts/NEXT_ART_Regular.otf'
import NextLight from '../../../../../assets/fonts/NEXT_ART_Light.otf'
import NextBold from '../../../../../assets/fonts/NEXT_ART_Bold.otf'

Font.register({ family: 'Lato', src: Lato, fontStyle: 'normal', fontWeight: 'normal' })
Font.register({ family: 'LatoLight', src: LatoLight, fontStyle: 'normal', fontWeight: 'normal' })
Font.register({ family: 'LatoBold', src: LatoBold, fontStyle: 'normal', fontWeight: 'normal' })
Font.register({ family: 'Next', src: Next, fontStyle: 'normal', fontWeight: 'normal' })
Font.register({ family: 'NextLight', src: NextLight, fontStyle: 'normal', fontWeight: 'normal' })
Font.register({ family: 'NextBold', src: NextBold, fontStyle: 'normal', fontWeight: 'normal' })

export default StyleSheet.create({
  body: {
    padding: '48 24'
  },
  logoView: {
    position: 'absolute',
    top: 12,
    right: 12
  },
  logo: {
    position: 'relative',
    width: '175px',
    height: '50px'
  },
  block: {
    paddingTop: '32px'
  },
  line: {
    display: 'flex',
    paddingTop: '4px',
    flexDirection: 'row'
  },
  textRight: {
    display: 'flex',
    width: '100%',
    textAlign: 'right'
  },
  title: {
    fontFamily: 'Next',
    fontSize: '18px'
  },
  price: {
    fontFamily: 'Next',
    fontSize: '20px',
    color: 'rgba(7, 65, 118, 1)'
  },
  subtitle: {
    fontFamily: 'LatoLight',
    fontSize: '12px'
  },
  subtitleBold: {
    fontFamily: 'LatoBold',
    fontSize: '12px'
  },
  bgGrey: {
    backgroundColor: '#FAFAFA',
    margin: '0 -24px'
  },
  content: {
    position: 'relative',
    padding: '8px 24px'
  },
  completed: {
    fontFamily: 'Next',
    fontSize: '18px',
    textAlign: 'center',
    backgroundColor: '#FAFAFA',
    color: 'rgba(31, 156, 36, 1)'
  },
  accepted: {
    fontFamily: 'Next',
    fontSize: '18px',
    textAlign: 'center',
    backgroundColor: '#FAFAFA',
    color: '#000'
  },
  itemTitle: {
    fontFamily: 'Next',
    fontSize: '16px'
  },
  itemSubtitle: {
    fontFamily: 'LatoBold',
    fontSize: '10px'
  },
  itemText: {
    fontFamily: 'LatoLight',
    fontSize: '10px'
  },
  itemSubtitleEP: {
    fontFamily: 'LatoBold',
    color: 'rgba(31, 156, 36, 1)',
    fontSize: '10px'
  },
  itemTextEP: {
    fontFamily: 'Lato',
    fontSize: '10px'
  },
  printDate: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 30,
    color: 'grey'
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    right: 30,
    color: 'grey'
  },
  statusView: {
    position: 'absolute',
    top: 12,
    right: 24
  },
  status: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '32px',
    borderRadius: '8px',
    padding: '0 8px'
  },
  completeStatus: {
    border: '1px solid rgba(31, 156, 36, 1)',
    backgroundColor: 'rgba(39, 186, 45, 0.08)'
  },
  completeText: {
    fontFamily: 'NextBold',
    fontSize: '12px',
    color: 'rgba(31, 156, 36, 1)'
  },
  checkinStatus: {
    border: '1px solid rgba(206, 146, 0, 1)',
    backgroundColor: 'rgba(253, 179, 0, 0.08)'
  },
  checkinText: {
    fontFamily: 'NextBold',
    fontSize: '12px',
    color: 'rgba(206, 146, 0, 1)'
  },
  itemImages: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  },
  image: {
    width: '165px',
    height: '145px',
    padding: '8px',
    flex: '0 0 33.333333%'
  }
})
