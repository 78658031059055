import { Theme, createStyles, makeStyles } from '../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      height: '100vh',
      maxWidth: theme.breakpoints.values.xl,
      margin: '0 auto',
      gridTemplateRows: 'auto min-content'
    }
  })
)
