import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    picture: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      borderRadius: '50px',
    },
    pictureSmall: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      marginLeft: theme.spacing(1),
      borderRadius: '50px',
    },
    personIcon: {
      color: 'var(--primary-outlined-resting-border)',
      fontSize: theme.spacing(5),
    },
    navigate: {
      display: 'grid',
      justifyContent: 'center',
      alignItems: 'center',
    },
    checkWarning: {
      margin: '0 16px',
      borderRadius: '50%',
      color: theme.palette.warning.dark,
      backgroundColor: 'var(--white-color)',
    },
    checkDone: {
      margin: '0 16px',
      color: theme.palette.success.main,
    },
    checkDoneContained: {
      margin: '0 16px',
      borderRadius: '50%',
      color: 'var(--white-color)',
      backgroundColor: theme.palette.success.main,
    },
    organization: {
      margin: '0 16px',
      color: theme.palette.primary.main,
    },
    leftItemText: {
      paddingLeft: '8px',
    },
    leftItemTexTruncate: {
      '@global': {
        '.MuiTypography-root': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis'
        }
      }
    },
    rightItemText: {
      textAlign: 'end',
      paddingRight: '8px',
      '& :nth-child(1)': {
        color: theme.palette.error.dark,
      },
    },
    rightItemTextTruncate: {
      '@global': {
        '.MuiTypography-root': {
          whiteSpace: 'nowrap',
        }
      }
    },
    revoke: {
      background: '#FAFAFA',
      borderRadius: '8px',
    },
    icon: {
      width: theme.spacing(8),
      height: theme.spacing(8),
      background: theme.palette.background.default,
      borderStyle: 'solid',
      borderWidth: '4px',
      borderColor: 'var(--primary-outlined-resting-border)',
      borderRadius: '50px',
    },
    iconSmall: {
      width: theme.spacing(6.5),
      height: theme.spacing(6.5),
      background: theme.palette.background.default,
      marginLeft: theme.spacing(1),
      borderStyle: 'solid',
      borderWidth: '4px',
      borderColor: 'var(--primary-outlined-resting-border)',
      borderRadius: '50px',
    },

  })
)
