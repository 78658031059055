import { FC, useEffect, useState } from 'react'
import {
  Dialog,
  Button,
  DialogContent,
  Typography,
  Grid,
  CircularProgress,
  ProfileCardOrganization,
} from '../../..'
import useStyles from './styles'
import { DialogInvitationRevokeProps } from './types'
import {
  concatSeparator,
  getNameFromAbb,
  isEmpty,
} from '../../../../../helpers'
import env from '@beam-australia/react-env'

const FILES_URL = env('FILES_URL') ?? ''

const DialogInvitationRevoke: FC<DialogInvitationRevokeProps> = ({
  open,
  setOpen,
  vendor,
  onRevoke,
}) => {
  const classes = useStyles()

  const {
    vendorId,
    recipientEmail,
    networkId,
    vendorName,
    vendorAddress,
    vendorPicture,
  } = vendor

  const { city, state } = vendorAddress || {}
  const address = concatSeparator(city, getNameFromAbb(state), ', ')

  const [revoked, setRevoked] = useState(false)
  const [revoking, setRevoking] = useState(false)

  useEffect(() => {
    if (revoked) {
      setTimeout(function () {
        setOpen(false)
      }, 3000)
    }
  }, [revoked])

  const handleRevoke = () => {
    if (!revoking) {
      setRevoking(true)
      onRevoke(vendorId, recipientEmail, networkId, (success) => {
        setRevoking(false)
        setRevoked(success)
      })
    }
  }

  const handleClose = () => {
    if (!revoking) {
      setOpen(false)
    }
  }

  const RevokedContent = () => (
    <>
      <DialogContent>
        <Typography variant="h6" className={classes.title}>
          Invitation Revoked
        </Typography>
        <Typography variant="body1">
          Daniel Swanson will no longer see your invitation.
        </Typography>
      </DialogContent>
      <DialogContent>
        <ProfileCardOrganization
          primary={(vendorName && vendorName.trim()) || recipientEmail}
          secondary={address}
          preview={
            !isEmpty(vendorPicture) ? FILES_URL.concat(vendorPicture) : null
          }
          status="revoke"
          small
        />
      </DialogContent>
    </>
  )

  const Content = () => (
    <>
      <DialogContent>
        <ProfileCardOrganization
          primary={(vendorName && vendorName.trim()) || recipientEmail}
          secondary={address}
          preview={
            !isEmpty(vendorPicture) ? FILES_URL.concat(vendorPicture) : null
          }
          status="revoking"
        />
        <Typography variant="body1" className={classes.text}>
          Pending to accept invitation
        </Typography>
      </DialogContent>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              component="span"
              className={classes.button}
              onClick={handleRevoke}
              size="large"
              fullWidth
            >
              {revoking ? (
                <CircularProgress color="inherit" size={24} />
              ) : (
                'REVOKE INVITE'
              )}
            </Button>
          </Grid>
          {!revoking && (
            <Grid item xs={12}>
              <Button
                variant="outlined"
                color="primary"
                component="span"
                onClick={handleClose}
                size="large"
                fullWidth
              >
                CLOSE
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </>
  )

  return (
    <Dialog open={open} classes={{ paper: classes.root }}>
      {revoked ? <RevokedContent /> : <Content />}
      <DialogContent />
    </Dialog>
  )
}

export default DialogInvitationRevoke
