import feedback from './feedback'
import files from './files'
import invitations from './invitations'
import orders from './orders'
import siteVisits from './siteVisits'
import territories from './territories'
import trades from './trades'
import users from './users'
import vendors from './vendors'

const punchlist = {
  feedback,
  files,
  invitations,
  orders,
  siteVisits,
  territories,
  trades,
  users,
  vendors,
}

export default punchlist
