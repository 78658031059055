import { Container, LinearProgress, Box } from '../../UI'
import useStyles from './styles'
import { logo } from '../../../assets'
import { LoaderProps } from './types'
import { FC } from 'react'

const Loader: FC<LoaderProps> = ({ children, loading = true }) => {
  const classes = useStyles()

  if (children && !loading) {
    return <> {children} </>
  }

  return (
    <Container className={classes.root}>
      <Box>
        <img src={logo} alt='' className={classes.logo} />
        <LinearProgress className={classes.loader} />
      </Box>
    </Container>
  )
}

export default Loader
