import { FC } from 'react'
import { BrowserRouter as Router, Switch, Redirect } from 'react-router-dom'
import PublicRoute from './PublicRoute'
import PrivateRoute from './PrivateRoute'
import ErrorBoundary from '../components/templates/ErrorBoundary'

const Routes: FC = () => (
  <Router>
    <ErrorBoundary>

      <Switch>
        {/* PUBLIC ROUTES */}
        <PublicRoute exact path="/" page="Auth/Login" />
        <PublicRoute exact path="/login-email" page="Auth/EmailLogin" />
        <PublicRoute exact path="/create" page="Auth/Create" />

        {/* PRIVATE ROUTES */}
        <PrivateRoute exact path="/register" page="Auth/Register" />
        <PrivateRoute exact path="/home" page="Home_V2" />
        <PrivateRoute exact path="/organization" page="Organization" />
        <PrivateRoute exact path="/organizations" page="Organizations" />
        <PrivateRoute
          exact
          path="/organization/:vendor"
          page="Organization/Vendor"
        />
        <PrivateRoute exact path="/bids" page="Bids" />
        <PrivateRoute exact path="/bids/:order/items" page="Bids/ItemsList" />
        <PrivateRoute exact path="/orders" page="Orders" />
        <PrivateRoute exact path="/orders/:order/items" page="Order/Items" />
        <PrivateRoute
          exact
          path="/orders/:order/items/:item"
          page="Order/Items/Item"
        />
        <PrivateRoute exact path="/orders/:order/files" page="Order/Files" />
        <PrivateRoute exact path="/orders/:order/notes" page="Order/Notes" />
        <PrivateRoute exact path="/orders/:order/map" page="Order/Map" />
        <PrivateRoute exact path="/profile" page="Profile/MyProfile" />
        <PrivateRoute
          exact
          path="/profile/licenses"
          page="Profile/LicensesAndDocs"
        />
        <PrivateRoute exact path="/profile/meeting" page="Profile/BookAMeeting" />
        {/* <PrivateRoute exact path='/profile/insurances' page='Profile/Insurances' /> */}
        <PrivateRoute exact path="/profile/settings" page="Profile/Settings" />
        <PrivateRoute
          exact
          path="/profile/settings/contact_info"
          page="Profile/ContactInfo"
        />
        <PrivateRoute
          exact
          path="/profile/settings/upload_docs"
          page="Profile/UploadDocs"
        />
        <PrivateRoute
          exact
          path="/profile/settings/locations"
          page="Profile/Locations"
        />
        {/* NOT FOUND */}
        <Redirect from="*" to="/" />
      </Switch>
    </ErrorBoundary>
  </Router>
)

export default Routes
