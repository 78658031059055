import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      zIndex: 2,
      justifyContent: 'center',
      padding: theme.spacing(3) / 2,
      color: theme.palette.primary.contrastText,
      backgroundColor: 'var(--navbar-background-color)',
      position:'sticky',
      bottom: '0.1px'
    },
    buttons: {
      margin: '0 auto',
      maxWidth: theme.breakpoints.values.lg
    },
    option: {
      width: '100%',
      color: theme.palette.primary.contrastText,
      backgroundColor: 'var(--navbar-background-color)',
      [theme.breakpoints.up('md')]: {
        height: '64px'
      }
    },
    selected: {
      width: '100%',
      color: theme.palette.primary.contrastText,
      backgroundColor: 'var(--navbar-background-color-selected)',
      '&:hover': {
        backgroundColor: 'var(--navbar-background-color-selected)'
      },
      [theme.breakpoints.up('md')]: {
        height: '64px'
      }
    },
    icon: {
      marginBottom: '-4px'
    },
    menuItem: {
      display: 'contents'
    },
    menuButton: {
      borderRadius: '12px'
    }
  })
)
