import { createStyles, makeStyles, Theme } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: '445px',
      borderRadius: '16px',
    },
    rejectButton: {
      color: theme.palette.error.dark,
      border: '1px solid ' + theme.palette.error.dark,
      backgroundColor: theme.palette.error.contrastText,
      boxShadow: 'none',
      '&:hover': {
        borderColor: theme.palette.error.dark,
        backgroundColor: theme.palette.error.contrastText,
        boxShadow: 'none',
      },
    },
    rejectWarningButton: {
      color: theme.palette.error.contrastText,
      backgroundColor: theme.palette.error.dark,
      '&:hover': {
        borderColor: theme.palette.error.contrastText,
        backgroundColor: theme.palette.error.dark,
      },
    },
    closeButton: {
      color: theme.palette.text.primary,
    },
    center: {
      textAlign: 'center',
    },
    iconBox: {
      display: 'flex',
      gap: '8px',
      alignItems: 'center',
    },
    title: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
    },
    awesome: {
      color: '#1B821F',
      fontWeight: 'bold',
    },
    icon: {
      width: '32px',
      height: '32px',
    },
  })
)
