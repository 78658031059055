import { UserType } from '../user/types'

/** TYPES **/
export const vendorsTypes = {
  FETCH_VENDORS: 'FETCH_VENDORS',
  SET_VENDORS: 'SET_VENDORS',
  DELETE_VENDOR: 'DELETE_VENDOR',
}

/** ACTIONS **/
export interface FetchVendorsAction {
  type: typeof vendorsTypes.FETCH_VENDORS
  payload: null
  callback?: (succ: boolean) => void
}

export interface SetVendorsAction {
  type: typeof vendorsTypes.SET_VENDORS
  payload: UserType[]
}

export interface DeleteVendorAction {
  type: typeof vendorsTypes.DELETE_VENDOR
  payload: {
    id: string
    email: string
    networkId: string
  }
  callback?: (succ: boolean) => void
}

export type VendorsActions =
  | FetchVendorsAction
  | SetVendorsAction
  | DeleteVendorAction

/** REDUCER **/

export interface VendorsType extends Array<UserType> {}
