import { FC, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  authActions,
  tosCheckActions,
  userActions,
} from '../../../../ducks/actions'
import {
  isRegisterCompleted,
  isTosAccepted,
  isTosChecked,
} from '../../../../ducks/selectors'
import { history } from '../../../../helpers'
import { DialogTerms } from '../../../UI'
import { TemplateProps } from './types'

const Template: FC<TemplateProps> = ({ children }) => {
  const dispatch = useDispatch()
  const replace = history.useReplace()

  const tosChecked = useSelector(isTosChecked)
  const tosAccepted = useSelector(isTosAccepted)
  const registerCompleted = useSelector(isRegisterCompleted)

  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!tosChecked) dispatch(tosCheckActions.fecthTosAccepted())
  }, [])

  useEffect(() => {
    if (registerCompleted && !tosAccepted && !open) setOpen(true)
  }, [tosAccepted])

  const handleAccept = (): void => {
    setLoading(true)
    dispatch(
      userActions.updateUser(
        {
          tosAccepted: true,
        },
        () => {
          setOpen(false)
          setLoading(false)
        }
      )
    )
  }

  const handleDecline = (): void => {
    dispatch(
      authActions.logoutRequest({
        callback: (success) => {
          if (success) replace('/')
        },
      })
    )
  }

  return (
    <>
      <DialogTerms
        open={open}
        loading={loading}
        onAccept={handleAccept}
        onDecline={handleDecline}
      />
      {children}
    </>
  )
}

export default Template
