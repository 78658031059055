import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    validating: {
      padding: theme.spacing(2),
      borderRadius: theme.spacing(1),
      color: theme.palette.primary.dark,
      background: 'var(--background-alert-color)'
    },
    icon: {
      color: theme.palette.info.main
    },
    text: {
      paddingLeft: theme.spacing(1)
    }
  })
)
