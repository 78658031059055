import { FC, lazy, Suspense } from 'react'
import { useSelector } from 'react-redux'
import { Route, Redirect } from 'react-router-dom'

import Loader from '../components/pages/Loader'
import { UserReferralsWrapper } from '../components/templates'
import { isAuthorized, isRegisterCompleted } from '../ducks/selectors'
import { RouteType } from './types'

const PublicRoute: FC<RouteType> = ({ path, page }) => {
  const authorized = useSelector(isAuthorized)
  const registerCompleted = useSelector(isRegisterCompleted)
  const PageComponent = lazy(() => import(`../components/pages/${page}`))

  return (
    <Route
      exact
      path={path}
      component={() => {
        if (authorized) {
          if (registerCompleted) {
            return <Redirect to='/home' />
          }
          return <Redirect to='/register' />
        }
        return (
          <Suspense fallback={<Loader />}>
            <UserReferralsWrapper>
              <PageComponent />
            </UserReferralsWrapper>
          </Suspense>
        )
      }}
    />
  )
}

export default PublicRoute
