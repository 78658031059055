import { Theme, createStyles, makeStyles } from '../../../UI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      height: '100vh',
      maxWidth: theme.breakpoints.values.md,
      position: 'relative',
      gridTemplateRows: 'min-content auto'
    },
    logo: {
      margin: 'auto',
      padding: theme.spacing(1),
      width: '100%'
    },
    topLogo: { paddingTop: '8vh' },
    bottomContent: { paddingBottom: '6vh' },
    content: { display: 'grid' },
    contentCenter: {
      display: 'grid',
      alignItems: 'center'
    }
  })
)
