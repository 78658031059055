/* eslint-disable generator-star-spacing */
/* eslint space-before-function-paren: "off" */
import type { SagaIterator } from 'redux-saga'
import { call, put, takeLatest } from 'redux-saga/effects'

import { Punchlist } from '../../api'
import { isEmpty } from '../../helpers'
import { tosCheckActions, userActions } from '../actions'

import {
  tosCheckTypes
} from './types'

export function* fetchTosAccepted(): SagaIterator {
  try {
    const data = yield call(Punchlist.users.getCurrentUser)

    if (!isEmpty(data) && !isEmpty(data.id)) {
      yield put(tosCheckActions.setTosChecked())
      yield put(userActions.setUserValue({ attr: 'tosAccepted', value: data?.tosAccepted || false }))
    }
  } catch (error) {
    // some error
  }
}

export default function* saga(): SagaIterator {
  yield takeLatest(tosCheckTypes.FETCH_TOS_ACCEPTED, fetchTosAccepted)
}
