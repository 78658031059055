import { ORDER_STATUS_REDUCED } from '../../helpers'

/** TYPES**/
export const configTypes = {
  SET_CONFIG_VALUE: 'SET_CONFIG_VALUE',
  SET_ERROR: 'SET_ERROR'
}

/** ACTIONS**/
export interface ConfigValue {
  type: string
  value: any
}

export interface ConfigSetValueAction {
  type: typeof configTypes.SET_CONFIG_VALUE
  payload: ConfigValue
}

export interface ErrorValue {
  show: boolean
  type: string
}

export interface SetErrorAction {
  type: typeof configTypes.SET_ERROR
  payload: ErrorValue
}

/** CONFIG**/
export interface ConfigType {
  orderTab: ORDER_STATUS_REDUCED
  orderSearch: string
  orderPendingScrollTop: number
  orderActiveScrollTop: number
  orderCompletedScrollTop: number
  orderItemsScrollTop: number
  lastPath: string
  replyingBid: boolean
  error: ErrorValue
}

export type ConfigActionTypes =
  | ConfigSetValueAction
  | SetErrorAction

