import { createTheme } from '.'

/* declare module '@material-ui/core/styles/createBreakpoints' {
  interface BreakpointOverrides {
    mobile: true
  }
} */

export default createTheme({
  props: {
    MuiWithWidth: {
      initialWidth: 'lg'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 445,
      md: 588,
      lg: 868,
      xl: 1024
    }
  },
  typography: {
    fontFamily: [
      'Lato',
      'Next Regular',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Next',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"'
    ].join(','),
    h1: { fontFamily: 'Next Regular', size: '96px' },
    h2: { fontFamily: 'Next Regular', size: '60px' },
    h3: { fontFamily: 'Next Regular', size: '48px' },
    h4: { fontFamily: 'Next Regular', size: '34px' },
    h5: { fontFamily: 'Next Regular', size: '24px' },
    h6: { fontFamily: 'Next Regular', size: '20px' },
    subtitle1: { fontFamily: 'Next Regular', size: '16px', fontWeight: 700 },
    subtitle2: { fontFamily: 'Next Regular', size: '14px' },
    body1: { size: '16px' },
    body2: { size: '14px' },
    button: { size: '14px' },
    caption: { size: '12px' }
  },
  palette: {
    background: {
      default: '#FBFFFE'
    },
    primary: {
      light: 'rgba(44, 118, 222, 1)',
      main: 'rgba(49, 133, 255, 1)',
      dark: 'rgba(30, 80, 151, 1)',
      contrastText: '#FBFFFE'
    },
    secondary: {
      // ! Workaround for Chip color, option > migrate to material v5 and create a new color
      main: 'rgba(31, 156, 36, 1)',
      /* light: 'rgba(255, 64, 129, 1)',
      main: 'rgba(245, 0, 87, 1)',
      dark: 'rgba(197, 17, 98, 1)', */
      contrastText: '#FBFFFE'
    },
    text: {
      primary: 'rgba(47, 49, 51, 0.9)',
      secondary: 'rgba(47, 49, 51, 0.6)',
      disabled: 'rgba(47, 49, 51, 0.32)'
    },
    info: {
      main: 'rgba(92, 157, 252,1)',
      dark: 'rgba(0, 123, 168, 1)',
      light: 'rgba(84, 200, 243, 1)',
      contrastText: '#FBFFFE'
    },
    success: {
      main: 'rgba(39, 186, 45, 1)',
      dark: 'rgba(78, 115, 93,1)',
      light: 'rgba(147, 218, 150, 1)',
      contrastText: '#FBFFFE'
    },
    error: {
      main: 'rgba(244, 67, 54, 1)',
      dark: 'rgba(204, 74, 43, 1);',
      light: 'rgba(248, 128, 120, 1)',
      contrastText: '#FBFFFE'
    },
    warning: {
      main: 'rgba(253, 179, 0, 1)',
      dark: 'rgba(206, 146, 0, 1)',
      light: 'rgba(251, 242, 218, 1)',
      contrastText: '#FBFFFE'
    },
    divider: 'rgba(0, 0, 0, 0.12)'
  },
  overrides: {
    MuiTypography: {
      h1: { '@media (min-width: 588px)': { fontSize: '108px' } },
      h2: { '@media (min-width: 588px)': { fontSize: '70px' } },
      h3: { '@media (min-width: 588px)': { fontSize: '56px' } },
      h4: { '@media (min-width: 588px)': { fontSize: '40px' } },
      h5: { '@media (min-width: 588px)': { fontSize: '28px' } },
      h6: { '@media (min-width: 588px)': { fontSize: '24px' } },
      subtitle1: { '@media (min-width: 588px)': { fontSize: '18px' } },
      subtitle2: { '@media (min-width: 588px)': { fontSize: '16px' } },
      body1: { '@media (min-width: 588px)': { fontSize: '18px' } },
      body2: { '@media (min-width: 588px)': { fontSize: '16px' } },
      button: { '@media (min-width: 588px)': { fontSize: '16px' } },
      caption: { '@media (min-width: 588px)': { fontSize: '14px' } }
    },
    MuiTextField: {
      root: {
        color: 'rgba(47, 49, 51, 0.6)'
      }
    },
    MuiInputLabel: {
      outlined: {
        '@media (min-width: 588px)': { // md
          '&$marginDense': {
            transform: 'translate(14px, 18px) scale(1)'
          },
          '&$shrink': {
            transform: 'translate(14px, -6px) scale(0.75)'
          }
        }
      }
    },
    MuiInputBase: {
      root: {
        '@media (min-width: 588px)': { // md
          height: '54px'
        }
      }
    },
    MuiButton: {
      label: {
        fontWeight: 700,
      }
    },
    MuiButtonBase: {
      root: {
        '@media (min-width: 588px)': { // md
          height: '54px'
        },
      },
    }
  }
})
