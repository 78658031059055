import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'grid',
      padding: `0 ${theme.spacing(2)}px ${theme.spacing(1)}px`,
      borderRadius: theme.spacing(1),
      background: 'var(--background-message-color)'
    },
    check: {
      margin: 0
    },
    checkbox: {
      '&:hover': {
        backgroundColor: 'transparent !important'
      }
    }
  })
)
