import { Theme, createStyles, makeStyles } from '../../../MaterialUI'

export default makeStyles((theme: Theme) =>
  createStyles({
    root: {
      padding: '4px',
    },
    content: {
      padding: '12px !important',
      background: 'linear-gradient(180deg, #3185ff 0%, #265AA5 100%)',
      borderRadius: '8px',
    },
    picture: {
      width: theme.spacing(9),
      height: theme.spacing(9),
      borderRadius: '50px',
    },
    pictureSmall: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      borderRadius: '50px',
    },
    icon: {
      width: theme.spacing(9),
      height: theme.spacing(9),
      background: theme.palette.background.default,
      borderStyle: 'solid',
      borderWidth: '4px',
      borderColor: 'var(--primary-outlined-resting-border)',
      borderRadius: '50px',
    },
    iconSmall: {
      width: theme.spacing(7),
      height: theme.spacing(7),
      background: theme.palette.background.default,
      borderStyle: 'solid',
      borderWidth: '4px',
      borderColor: 'var(--primary-outlined-resting-border)',
      borderRadius: '50px',
    },
    personIcon: {
      color: 'var(--primary-outlined-resting-border)',
      fontSize: theme.spacing(5),
    },
    badge: {
      width: '20px',
      height: '20px',
      top: '0px',
      left: '0px',
    },
    navigate: {
      display: 'grid',
      justifyContent: 'center',
      alignItems: 'center',
    },
    button: {
      height: '42px',
    },
  })
)
