import { FC } from 'react'
import { Box } from '../../../UI'
import { TemplateProps } from './types'

// TODO: temporary component, find a better solution for pages without navBar
const Template: FC<TemplateProps> = ({ children }) => {
  return (
    <Box style={{ display: 'flex', height: '100vh' }}>
      {children}
    </Box>
  )
}
export default Template
