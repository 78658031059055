import { FC } from 'react'
import { Typography, Box, LinearProgress } from '../../..'
import useStyles from './styles'
import { ProgressProps } from './types'

const Progress: FC<ProgressProps> = ({ value, total, ...rest }) => {
  const classes = useStyles()

  return (
    <Box display='flex' alignItems='initial'>
      <Box width='100%' mr={1}>
        <LinearProgress
          {...rest}
          style={{ marginTop: '8px' }}
          classes={{
            root: classes.linearRoot,
            bar: classes.linearColor
          }}
          variant='determinate'
          value={total !== undefined
            ? total > 0 ? (value * 100 / total) : 0
            : value}
        />
      </Box>
      <Box className={classes.value}>
        {total !== undefined
          ? (
            <Typography variant='body2'>
              {`${value}/${total}`}
            </Typography>)
          : (
            <Typography variant='body2'>
              {`${Math.round(value)}%`}
            </Typography>)}
      </Box>
    </Box>
  )
}

export default Progress
